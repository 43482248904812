import { Injectable, EventEmitter } from '@angular/core';
import { MonthDay } from '../../models/month-day';
import { Month } from '../../models/month';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs/Observable';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

@Injectable()
export class UtilsService {
  public linked: any = {};
  public utilsService: EventEmitter<any> = new EventEmitter<any>();

  public static getDate(fullYear: number, month: number, day: number): Date {
    try {
      if (isNullOrUndefined(fullYear) || isNullOrUndefined(month) || isNullOrUndefined(day)
        || (month < 1 || month > 12) || (day < 1 || day > 31)) { return null; }
      return new Date(fullYear, month - 1, day);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static getNumberOfDaysForMonth(month: number, year: number): number {
    try {
      return new Date(year, month, 0).getDate();
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public static getOrdinalNumber(n): string {
    try {
      const s: string[] = ['th', 'st', 'nd', 'rd'];
      const v: number = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static generateDateByStringYearAndMonthDate(stringDate: string): Date {
    const dateArray = stringDate.split('-');
    if (dateArray.length >= 2) {
      const year = Number(dateArray[0]);
      const month = Number(dateArray[1]);
      if ((Number.isSafeInteger(year) && Number.isSafeInteger(month)) && (month >= 1 && month <= 12)) {
        return UtilsService.generateDateByYearAndMonth(year, month);
      }
    }
    return null;
  }

  public static generateDateByYearAndMonth(year: number, month: number): Date {
    return new Date(year, month - 1);
  }

  public static generateDateByMiliseconds(milisecondsDate: number): Date {
    try {
      return new Date(milisecondsDate);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static millisecondsToHours(milliseconds: number): number {
    try {
      return milliseconds / 1000 / 60 / 60;
    } catch (error) {
      console.error(error);
      throw Number;
    }
  }
  public static millisecondsToDays( milliseconds: number ): number {
    try {
      return  milliseconds / 1000 / 60 / 60 / 24;
    } catch ( error ) {
      console.log( 'UtilService.millisecondsToHDays()' );
      console.error( error );
      throw Number;
    }
  }

  public static generateFormattedDateMMYYYY(date: Date): string {
    return UtilsService.generateFormattedDate(date, 'MMMM, YYYY');
  }

  public static generateFormattedDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  public static getArrayOfNumbers(numberOfElements: number, startfromZero: boolean): number[] {
    if (numberOfElements === 0) { return []; }
    const resultArray: number[] = [];
    const startAt: number = startfromZero ? 0 : 1;
    const endAt: number = startfromZero ? (numberOfElements - 1) : (numberOfElements);
    for (let i = startAt; i <= endAt; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  public static getArrayOfNumbersByRange(from: number, to: number): number[] {
    const resultArray: number[] = [];
    for (let i = from; i <= to; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  /***
   * Given a string in the 2018-01-17 10:55:05 format, it converts it into a ISO format (2018-01-17T10:55:05Z)
   * si it can be used to create a new Date object in JS
   * @param (string) notISOStringDate
   */
  public static createIsoDateTimeString(notISOStringDate: string) {
    try {
      const arrayDate: string[] = notISOStringDate.split(' ');
      console.log(arrayDate);
      if (arrayDate.length !== 2) { return null; }
      console.log(arrayDate[0] + 'T' + arrayDate[1] + 'Z');
      return arrayDate[0] + 'T' + arrayDate[1] + 'Z';
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static createDateFromNotISOStringDate(notISOStringDate: string): Date {
    try {
      const ISOStringDate: string = UtilsService.createIsoDateTimeString(notISOStringDate);
      if (isNullOrUndefined(ISOStringDate)) { return null; }
      return new Date(ISOStringDate);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static padRight(stringToPad: string, width: number, character = '0'): string {
    try {
      return stringToPad.length >= width ?
        stringToPad : new Array(width - stringToPad.length + 1).join(character) + stringToPad;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static generateMonthDays(month: number, year: number): MonthDay[] {
    try {
      const numberOfDaysForMonth: number = UtilsService.getNumberOfDaysForMonth(month, year);
      const resultMontDays: MonthDay[] = [];

      for (let i = 1; i <= numberOfDaysForMonth; i++) {
        const itemMonthDay: MonthDay = { value: i, name: UtilsService.padRight(i.toString(), 2) };
        resultMontDays.push(itemMonthDay);
      }

      return resultMontDays;
    } catch (error) {
      console.error(error);
    }
  }

  public static millisecondsCounter(initialDelay: number, millisecondsPeriod: number, until: number,
                                    ascendant: boolean): Observable<number> {
    try {
      const counterResult: Observable<number> = TimerObservable.create(initialDelay, millisecondsPeriod)
        .map((counter: number) => {
          if (ascendant === true) {
            return counter;
          } else {
            return until - counter - 1;
          }
        }).catch((error: any) => {
          console.log(error);
          return Observable.throw(error);
        });

      if (until === 0) {
        return counterResult;
      } else {
        return counterResult.take(Math.abs(until));
      }

    } catch (error) {
      console.log(error);
      Observable.throw(error);
    }
  }

  constructor() { }

}
