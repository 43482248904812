import { UtilsService } from './../../shared/services/utils.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cabinOrdinalName'
})
export class CabinOrdinalNamePipe implements PipeTransform {

  transform(value: number, args?: any): any {
    return UtilsService.getOrdinalNumber( value ) + ' Cabin';
  }

}

