import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { angularMath } from "angular-ts-math";
import { GalavailServiceEcommer, SeoServiceService } from "clientwsGalavail";
import { SearchRequestInterface } from "clientwsGalavail/src/app/modules/webservices/classes/search-request.interface";
import { RequestFindAvailabilityByCode } from "clientwsGalavail/src/app/modules/webservices/services/ws/galavail-ws/models/requestFindAvailabilityByCode";
import { RequestFindCabinsG } from "clientwsGalavail/src/app/modules/webservices/services/ws/galavail-ws/models/requestFindCabins";
import * as $ from "jquery";
import { NgWebflowService } from "ngweflow";
import { CruiseWrapper } from "src/app/classes/cruise-wrapper";
import { PriceRateDescriptor } from "src/app/enums/price-rate-descriptor.enum";
import { PurchaseRequestStatus } from "src/app/enums/purchase-request-status.enum";
import { CabinaItinerarioMenor } from "src/app/models/cabina/cabina-itinerario-menor";
import { Promo } from "src/app/models/cabina/promo";
import { Cruise } from "src/app/models/cruise";
import { PurchaseCabin } from "src/app/models/purchase-cabin";
import { PurchaseAirTicketDetail } from "src/app/models/purchase/purchase-air-ticket-detail";
import { UrlParamsService } from "src/app/services/url-params.service";
import { environment } from "src/environments/environment";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { isNullOrUndefined } from "util";
import { CabinService } from "../../services/cabin/cabin.service";
import { PurchaseRequestService } from "../../services/purchase-request/purchase-request.service";
import { CabinaItinerarioMenorWrapper } from "./../../../classes/cabina-itinerario-menor-wrapper";
import { PassengerType } from "./../../../enums/passenger-type.enum";
import { PromoCode } from "./../../../enums/promo-code.enum";
import { TipoPaqueteEcommerceEnum } from "./../../../enums/type-package-ecommerce.enum";
import { CamaCabinaList } from "./../../../models/cabina/cama-cabina-list";
import { PurchaseCabinPassenger } from "./../../../models/purchase-cabin-passenger";
import { CompanyParamsService } from "./../../../services/companyParams/company-params.service";
import { AirTicketService } from "./../../services/air-ticket/air-ticket.service";
import { CruiseService } from "./../../services/cruise/cruise.service";
import { PriceRateService } from "./../../services/price-rate/price-rate.service";
import { PromoService } from "./../../services/promo/promo.service";
import { CompanyCodeEnum } from "../../../enums/company-code.enum";

const swal: SweetAlert = _swal as any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          opacity: 1,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
          display: "none",
        })
      ),
      transition("open => closed", [animate("1.5s")]),
      transition("closed => open", [animate("1.5s")]),
    ]),
    trigger("close", [
      state(
        "open",
        style({
          opacity: 1,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
          display: "none",
        })
      ),
      transition("open => closed", [animate("1.5s")]),
    ]),
  ],
})
export class CheckoutComponent implements OnInit {
  public searchRequestInterface: SearchRequestInterface;
  public itineraries: any;
  public cruises: Cruise[];
  public cruise: Cruise;
  public cabinPhotoDirectory: string;
  public passengers = [];
  public children = [];
  public bedTypesSelected = [];
  public cabins: any = [];
  public cabinsSelected: CabinaItinerarioMenorWrapper[];
  public purchaseAirTicketDetail: PurchaseAirTicketDetail[][];
  public purchase: any;
  public includeGalapagosAirTickets: boolean;
  public urlImage = environment.app_resources.cabinpath;
  public includeAirFair = false;
  public tineraryIndex: number;
  public priceWithAirFare: number;
  public priceWithoutAirFare: number;
  public wasPriceWithAirFare: number;
  public wasPriceWithoutAirFare: number;
  public totalPrice: number;
  public isDefaultOptions: boolean;
  public itineraryIndex: number;
  public isShowOptionA: boolean;
  public isShowOptionB: boolean;
  public cruiseStartDate: string;
  public startEndDates: string;
  public isActive: boolean;
  public startEndDatesType: string;
  public promotion: string;
  public cabinsNumber: PurchaseCabin[];
  public tourSummaryBoolean: boolean;
  public activeEndSlide: string;
  public selectpackageOptionA: boolean;
  public selectpackageOptionB: boolean;
  public classNextPassenger: string;
  public isSearching: boolean;
  public requestAdults: number;
  public requestCabins: number;
  public totalPassengers: number;
  public totalAdults: number;
  public totalChildren: number;
  public totalPurchase: number;
  public requestFindAvailabilityByCode: RequestFindAvailabilityByCode;
  public purchaseRequestStatus: typeof PurchaseRequestStatus =
    PurchaseRequestStatus;
  public isRemainingTimeForPurchase: boolean;
  public cabinasItinerarioMenorWrapper: CabinaItinerarioMenorWrapper[];
  public purchaseCabinPassengers: PurchaseCabinPassenger[][] = [];
  public passengerFlag: number;
  public itinerariesImagesResource: string;
  public boatImagesResource: string;
  public lowestPriceCruise: { promo: Promo; price: number; dayFrom: number };
  public position: boolean;
  public cruiseItineraryDuration: string;
  public hasPromo: boolean;
  public company: any;
  public paramsInRoute: any;
  public isValidSummarFinal: boolean;
  public isValidCabin: boolean;
  PROMO_RAC = PromoCode.PROMO_RAC;
  isOpen = true;
  public index: number;
  public isOptionAIncludesFreeTransfers: boolean;
  public isOptionBIncludesFreeTransfers: boolean;
  public dateToday: Date = new Date();
  private requestCompany: any;
  paramsOfUrl: any;
  isOpenShareDialog: boolean;
  urlShare = "";

  constructor(
    public cruiseService: CruiseService,
    private cabinService: CabinService,
    public promoService: PromoService,
    public purchaseRequestService: PurchaseRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private seoServiceService: SeoServiceService,
    private airTicketService: AirTicketService,
    private ngWebflowService: NgWebflowService,
    public companyParamsService: CompanyParamsService,
    private urlParamsService: UrlParamsService,
    private priceRateService: PriceRateService
  ) {
    this.isSearching = true;
    this.isValidSummarFinal = false;
    this.index = 0;
    this.isOpenShareDialog = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
  ngOnInit() {
    this.setStylesdinamics();
    this.cabinsSelected = [];
    this.cabinasItinerarioMenorWrapper = [];
    this.fillInformation();
    this.passengerFlag = 0;
    this.totalPassengers = 0;
    this.totalAdults = 0;
    this.totalChildren = 0;
    this.totalPurchase = 0;
    this.includeGalapagosAirTickets = false;
    this.position = false;
  }

  public setScripts() {
    this.companyParamsService.getScripts().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }
  getChildrenAirFare() {
    return this.priceRateService.getPriceRateByName(
      PriceRateDescriptor.ChildrenAirFare
    ).valor;
  }
  getAdultAirFare() {
    return this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare)
      .valor;
  }
  private isIncludeAirFareInPrice(itinerary: number): boolean {
    try {
      const cruise: Cruise = { ...this.cruise };
      cruise.iterIndex = itinerary;

      const cruiseWrapper: CruiseWrapper = new CruiseWrapper(cruise);
      const tipoPaqueteEcom: any =
        cruiseWrapper.getTipoPaqueteEcommerceinNewFormat();
      if (isNullOrUndefined(tipoPaqueteEcom)) {
        return false;
      }

      if (tipoPaqueteEcom === TipoPaqueteEcommerceEnum.TYPE_A) {
        return true;
      } else if (
        tipoPaqueteEcom === TipoPaqueteEcommerceEnum.TYPE_B ||
        tipoPaqueteEcom === TipoPaqueteEcommerceEnum.TYPE_A_B ||
        tipoPaqueteEcom === TipoPaqueteEcommerceEnum.TYPE_DEFAULT
      ) {
        return false;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /***
   * It gets the lowest price for the the different itineraries of the boat
   * @returns {number}
   */
  private getLowestPriceCruise(): {
    promo: Promo;
    price: number;
    dayFrom: number;
  } {
    const result: { promo: Promo; price: number; dayFrom: number } = {
      promo: null,
      price: 0,
      dayFrom: 0,
    };
    let currentPrice: number;
    let lowestPrice = 0;

    for (let i = 0; i < this.cruise.availability.length; i++) {
      currentPrice = this.cruiseService.getCruisePriceinNewFormat(
        this.cruise,
        i,
        this.isIncludeAirFareInPrice(i)
      );
      const promotest: Promo = {
        codigo: this.cruise.availability[i].promotionCode,
        estado: 1,
        etiqueta: this.cruise.availability[i].promotion,
        valor: null,
        tipo: null,
        descripcion: null,
      };
      if (i === 0) {
        lowestPrice = currentPrice;
        result.promo = promotest;
        result.price = currentPrice;
        result.dayFrom = this.getDayfrom(
          this.cruise,
          this.cruise.availability[i]
        );
      } else if (currentPrice < lowestPrice) {
        lowestPrice = currentPrice;
        result.promo = promotest;
        result.price = currentPrice;
        result.dayFrom = this.getDayfrom(
          this.cruise,
          this.cruise.availability[i]
        );
      }
      return result;
    }
  }
  shareBoat() {
    this.urlShare = window.location.href;
    this.isOpenShareDialog = true;
  }
  private getDayfrom(cruise, availability): number {
    const codeitinerary = availability.itinerary;
    const itineraryob = cruise.itineraries.find(
      (itinerary) => itinerary.code === codeitinerary
    );

    return itineraryob.length;
  }
  getFloor(x) {
    return Math.round(x);
  }

  private createPurchaseAirTicketDetails(): PurchaseAirTicketDetail[][] {
    try {
      if (
        isNullOrUndefined(this.includeGalapagosAirTickets) ||
        this.purchaseCabinPassengers.length < 1
      ) {
        return [];
      }
      const mapPurchaseAirTicketDetails: PurchaseAirTicketDetail[][] = [];
      this.purchaseCabinPassengers.forEach(
        (purchaseCabinPassengersArray: PurchaseCabinPassenger[]) => {
          const purchaseAirTicketDetails: PurchaseAirTicketDetail[] = [];
          purchaseCabinPassengersArray.forEach(
            (purchaseCabinPassenger: PurchaseCabinPassenger) => {
              const airTicketPrice = this.airTicketService.getAirTicketPrice(
                purchaseCabinPassenger.type
              );
              const purchaseAirTicketDetail: PurchaseAirTicketDetail = {
                type: purchaseCabinPassenger.type,
                price: airTicketPrice,
              };
              purchaseAirTicketDetails.push(purchaseAirTicketDetail);
            }
          );
          mapPurchaseAirTicketDetails.push(purchaseAirTicketDetails);
        }
      );
      return mapPurchaseAirTicketDetails;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  isserch() {
    return this.isSearching;
  }

  private showOptions(): void {
    try {
      this.isDefaultOptions = this.purchaseRequestService.isPackageOption(
        TipoPaqueteEcommerceEnum.TYPE_DEFAULT
      );
      this.isShowOptionA = false;
      this.isShowOptionB = false;
    } catch (error) {
      console.error(error);
    }
  }

  setStylesdinamics() {
    (document.querySelector("#cabins") as HTMLElement).style.opacity = "0.3";
    (document.querySelector("#cabinsOptions") as HTMLElement).hidden = true;
    (document.querySelector("#packages") as HTMLElement).style.opacity = "0.3";
    //(document.querySelector('#summaryFinal') as HTMLElement).hidden = true;
    (document.querySelector("#passengers") as HTMLElement).style.opacity =
      "0.3";
    (document.querySelector("#passengersOptions") as HTMLElement).hidden = true;
    this.activeEndSlide = "active";
    this.classNextPassenger = "next inactive";
    this.isValidCabin = false;
  }

  fillInformation() {
    this.activatedRoute.params.subscribe((activatedRoute) => {
      this.paramsInRoute = activatedRoute;
      this.urlParamsService.setParams(this.paramsInRoute);
      this.requestFindAvailabilityByCode = {
        code: activatedRoute.codeAvailability,
        key: activatedRoute.key,
        // agency: 'MjE3' // it should be changing
      };
      this.paramsOfUrl = this.getParmasOfUrl();

      this.galavailServiceEcommer
        .findCompany(this.generateKeyCompany())
        .subscribe((company) => {
          this.company = company;
          this.companyParamsService.setCompany(this.company, this.paramsOfUrl);

          this.galavailServiceEcommer
            .findAvailabilityByCode(this.requestFindAvailabilityByCode)
            .subscribe((response) => {
              this.cruises = response;
              this.cruise = this.cruises[0];
              this.cruise.iterIndex = 0;
              const boatName = this.cruise.name;
              let requestSeoBody: any;

              setTimeout(() => {
                requestSeoBody = {
                  company: this.companyParamsService.getName(),
                  boat: isNullOrUndefined(boatName) ? "" : boatName,
                };
                this.seoServiceService.generateTags(
                  requestSeoBody,
                  "requestG_findSeo"
                );
              }, 1500);
              this.hasPromo = this.cruiseService.doesItineraryHasPromo(
                this.cruise,
                0
              );
              this.itineraries = this.cruiseService.createIterDay(this.cruise);
              this.isOptionAIncludesFreeTransfers =
                this.isIncludesFreeTransfers(true);

              this.isOptionBIncludesFreeTransfers =
                this.isIncludesFreeTransfers(false);
              this.purchaseRequestService.purchaseRequestStatus =
                PurchaseRequestStatus.INITIAL;
              this.purchaseRequestService.addCruise(this.cruise);
              this.showOptions();
              this.requestPurchaseRemainigTime();
              const requestCabin = {
                adults: activatedRoute.pax,
                children: 0,
                itinerary: activatedRoute.codeAvailability,
                code: this.companyParamsService.getCode(),
              };
              this.requestAdults = activatedRoute.pax;
              this.requestCabins = Math.ceil(this.requestAdults / 2);
              this.fetchCabins(requestCabin);
              this.itineraryIndex = 0;

              this.priceWithAirFare =
                this.cruiseService.getCruisePriceinNewFormat(
                  this.cruise,
                  this.itineraryIndex,
                  true
                );

              if (isNullOrUndefined(this.priceWithAirFare)) {
                this.router.navigate(["404_NOT_FOUND/" + activatedRoute.key]);
              }

              this.priceWithoutAirFare =
                this.cruiseService.getCruisePriceinNewFormat(
                  this.cruise,
                  this.itineraryIndex,
                  false
                );
              this.wasPriceWithAirFare = this.getWasPrice(true);
              this.wasPriceWithoutAirFare = this.getWasPrice(false);
              this.totalPrice = this.purchaseRequestService.purchase.totalPrice;
              this.cruiseStartDate =
                this.cruise.availability[this.cruise.iterIndex].date;
              this.startEndDates =
                this.cruise.availability[this.cruise.iterIndex].startEndDates;
              this.startEndDatesType =
                this.cruise.availability[this.cruise.iterIndex].name;
              this.promotion = this.promoService.getPromoText(
                this.cruise.availability[this.cruise.iterIndex].promotionType,
                this.cruise.availability[this.cruise.iterIndex].promotionValue
              );
              this.initializeItinerariesImagesResource();
              this.initializeBoatImageResource();

              this.lowestPriceCruise = this.getLowestPriceCruise();
              this.cruiseItineraryDuration = this.purchaseRequestService
                .getCruiseWrapper()
                .getCruiseItineraryDuration();
              this.isSearching = false;

              this.ngWebflowService.addWebFlowAction(
                "ENTERED ECOMMERCEGALAVAIL",
                this.cruise
              );
              this.setScripts();
            });
        });
    });
  }

  getParmasOfUrl() {
    return this.activatedRoute.snapshot.params;
  }
  public generateKeyCompany() {
    const encodeDate = window.btoa(this.getFormatedDateToday());
    const encodeCompany = window.btoa(
      encodeDate + "," + this.paramsOfUrl.companyName
    );
    this.requestCompany = {
      key: encodeCompany,
    };
    return this.requestCompany;
  }
  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }
  private requestPurchaseRemainigTime(): void {
    try {
      this.purchaseRequestService.isRemainingTimeForPurchaseRequest().subscribe(
        (isRemainingTimeForPurchase: boolean) => {
          this.isRemainingTimeForPurchase = isRemainingTimeForPurchase;
        },
        (error: any) => {
          this.isRemainingTimeForPurchase = false;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  onselectBed(indexCabin) {
    if (this.bedTypesSelected[indexCabin] !== "-1") {
      this.bedTypesSelected.push(indexCabin);
      this.passengerFlag++;
      if (this.passengerFlag >= this.cabinsSelected.length) {
        this.classNextPassenger = "next";
      }
    } else if (this.bedTypesSelected[indexCabin] === "-1") {
      this.classNextPassenger = "next inactive";
    }
  }

  private getWasPrice(includeAirFare: boolean): number {
    const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
      new CabinaItinerarioMenorWrapper(
        this.cruise.availability[this.itineraryIndex]
      );

    return cabinaItinerarioMenorWrapper.getWasPrice(
      this.cruise.category,
      includeAirFare,
      false,
      PassengerType.ADULT,
      this.cabinService
    );
  }

  public getPurchaseCabinPassengers(): PurchaseCabinPassenger[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinPassengers();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  cruiseDatesSelection() {
    this.isActive = true;
    (document.querySelector("#packages") as HTMLElement).style.opacity = "1";
    $("html, body").animate(
      {
        scrollTop: $("#packages").offset().top,
      },
      1000
    );
  }

  onClickIncr(valueToIncrese, isAdult: boolean, index: number) {
    if ((valueToIncrese === undefined || valueToIncrese === 1) && isAdult) {
      valueToIncrese = 1;
      if (
        angularMath.sum(this.passengers[index], this.children[index]) <
        this.cabinsSelected[index].getCabinaItinerarioMenor().capacity
      ) {
        valueToIncrese++;
        this.passengers[index] = valueToIncrese;
        this.totalPassengers = 0;
        this.totalAdults = 0;
        //this.isValidSummarFinal = false;
      }
    } else if (
      (valueToIncrese === undefined || valueToIncrese === 0) &&
      !isAdult
    ) {
      if (
        angularMath.sum(this.passengers[index], this.children[index]) <
        this.cabinsSelected[index].getCabinaItinerarioMenor().capacity
      ) {
        valueToIncrese = 1;
        this.children[index] = valueToIncrese;
        this.totalPassengers = 0;
        this.totalChildren = 0;
        this.isValidSummarFinal = false;
      }
    }
  }

  onClickDecr(valueDecrese, isAdult: boolean, index: number) {
    valueDecrese--;
    isAdult
      ? (this.passengers[index] = valueDecrese > 1 ? valueDecrese : 1)
      : (this.children[index] = valueDecrese > 0 ? valueDecrese : 0);
    this.totalPassengers = 0;
    this.totalAdults = 0;
    this.totalChildren = 0;
    this.isValidSummarFinal = false;
  }

  cabinSelection() {
    if (this.requestCabins === this.cabinsSelected.length) {
      (document.querySelector("#passengers") as HTMLElement).style.opacity =
        "1";
      (document.querySelector("#passengersOptions") as HTMLElement).hidden =
        false;
      $("html, body").animate(
        {
          scrollTop: $("#passengers").offset().top,
        },
        1000
      );
      this.ngWebflowService.addWebFlowAction(
        "SELECTED_CABINS",
        this.cabinsSelected
      );
    } else {
      // alert(`Please choose  ${this.requestCabins} cabins`)
      swal(`Please choose  ${this.requestCabins} cabins`);
    }
  }

  onSelectCabin(cabinSelected) {
    cabinSelected.cabinaItinerarioMenor.isSelected =
      !cabinSelected.cabinaItinerarioMenor.isSelected;
    this.totalPassengers = 0;
    this.totalAdults = 0;
    this.totalChildren = 0;
    if (this.cabinsSelected.length === 0) {
      this.cabinsSelected.push(cabinSelected);
      this.bedTypesSelected[this.cabinsSelected.length - 1] = 0;
      this.passengers[this.cabinsSelected.length - 1] =
        this.cabinsSelected.length === this.requestCabins
          ? this.requestAdults % 2 === 0
            ? 2
            : 1
          : 2;
      this.classNextPassenger = "next";
      if (this.requestCabins === this.cabinsSelected.length) {
        this.isValidCabin = true;
      }
    } else if (this.cabinsSelected.length !== 0) {
      const index = Array.from(this.cabinsSelected).findIndex(
        (cabin) =>
          cabin.getCodigo() === cabinSelected.cabinaItinerarioMenor.code
      );
      if (index < 0 && this.requestCabins > this.cabinsSelected.length) {
        this.cabinsSelected.push(cabinSelected);
        this.bedTypesSelected[this.cabinsSelected.length - 1] = 0;
        if (this.requestCabins === this.cabinsSelected.length) {
          this.isValidCabin = true;
        }
        this.passengers[this.cabinsSelected.length - 1] =
          this.cabinsSelected.length === this.requestCabins
            ? this.requestAdults % 2 === 0
              ? 2
              : 1
            : 2;
        this.classNextPassenger = "next";
      } else if (index >= 0) {
        this.cabinsSelected.splice(index, 1);
        this.passengers.splice(index, 1);
        this.children.splice(index, 1);
        this.bedTypesSelected.splice(index, 1);
        this.isValidCabin = false;
        (document.querySelector("#passengers") as HTMLElement).style.opacity =
          "0.3";
        (document.querySelector("#passengersOptions") as HTMLElement).hidden =
          true;
        this.isValidSummarFinal = false;
      } else if (this.requestCabins === this.cabinsSelected.length) {
        // alert(`Please choose only ${this.requestCabins} cabins`)
        swal(`Please choose only ${this.requestCabins} cabin(s)`);
        cabinSelected.cabinaItinerarioMenor.isSelected =
          !cabinSelected.cabinaItinerarioMenor.isSelected;
      }
    }
  }

  passengersSelection() {
    this.totalPassengers = 0;
    this.passengers.forEach((res) => {
      this.totalPassengers = this.totalPassengers + res;
      this.totalAdults = this.totalAdults + res;
    });
    this.children.forEach((res1) => {
      this.totalPassengers = this.totalPassengers + res1;
      this.totalChildren = this.totalChildren + res1;
    });
    this.isActive = true;

    if (this.totalPassengers.toString() === this.requestAdults.toString()) {
      if (!this.validateSelectedAllbedType()) {
        // return alert('please select all bed type of cabin ');
        return swal("please select all bed type of cabin(s)");
      }
      this.setPassenger();
      this.totalPurchase = this.getTotalPrice();
      this.isValidSummarFinal = true;
      $("html, body").animate(
        {
          scrollTop: $("#summaryFinal").offset().top,
        },
        1000
      );
    } else if (this.totalPassengers !== this.requestAdults) {
      this.isValidSummarFinal = false;
      this.totalPassengers = 0;
      // alert(' Please keep in mind that the number of adults must equal ' + `${this.requestAdults}`)
      swal(
        " Please keep in mind that the number of adults must equal " +
          `${this.requestAdults}`
      );
    }
  }
  validateSelectedAllbedType(): boolean {
    let response = true;
    this.cabinsSelected.forEach((c, index) => {
      if (this.bedTypesSelected[index] === -1) {
        response = false;
      }
    });
    return response;
  }
  validSummary() {
    return (
      (this.isShowOptionA || this.isShowOptionB) &&
      this.cabinsSelected.length > 0 &&
      this.totalPassengers > 0
    );
  }

  fetchCabins(requestCabin: RequestFindCabinsG) {
    this.galavailServiceEcommer
      .findCabins(requestCabin)
      .subscribe((cabinasItinerarioMenor: CabinaItinerarioMenor[]) => {
        cabinasItinerarioMenor.forEach((cabinaItinerarioMenor) => {
          const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
            new CabinaItinerarioMenorWrapper(cabinaItinerarioMenor);
          this.cabinasItinerarioMenorWrapper.push(cabinaItinerarioMenorWrapper);
        });
      });
  }

  public createCabinFormPassengers(): PurchaseCabinPassenger[][] {
    const purchaseCabinPassengersSummary: PurchaseCabinPassenger[][] = [];
    this.cabinsSelected.forEach((cabinaItinerarioMenorValue, index) => {
      const purchaseCabinPassengers: PurchaseCabinPassenger[] = [];
      const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
        cabinaItinerarioMenorValue;
      const numberChildren = this.children[index] ? this.children[index] : 0;
      let cabinAdultPrice = 0;

      cabinAdultPrice = cabinaItinerarioMenorWrapper.getAppPrice(
        this.cruise.category,
        this.includeAirFair,
        this.passengers[index] + numberChildren < 2 ? true : false,
        PassengerType.ADULT,
        this.cabinService
      );

      const cabinChildPrice = cabinaItinerarioMenorWrapper.getChildPrice(
        this.cruise.category,
        this.includeAirFair,
        this.cabinService
      );
      const cabinAdultWasPrice = cabinaItinerarioMenorWrapper.getWasPrice(
        this.cruise.category,
        this.includeAirFair,
        this.passengers[index] + numberChildren < 2 ? true : false,
        PassengerType.ADULT,
        this.cabinService
      );
      const cabinChildWasPrice = cabinaItinerarioMenorWrapper.getWasPrice(
        this.cruise.category,
        this.includeAirFair,
        false,
        PassengerType.CHILD,
        this.cabinService
      );
      for (let i = 0; i < this.passengers[index]; i++) {
        purchaseCabinPassengers.push({
          type: PassengerType.ADULT,
          price: cabinAdultPrice,
          wasPrice: cabinAdultWasPrice,
        });
      }
      for (let i = 0; i < this.children[index]; i++) {
        purchaseCabinPassengers.push({
          type: PassengerType.CHILD,
          price: cabinChildPrice,
          wasPrice: cabinChildWasPrice,
        });
      }

      purchaseCabinPassengersSummary.push(purchaseCabinPassengers);
    });
    return purchaseCabinPassengersSummary;
  }

  private createPurchaseCabinObject(
    purchaseCabinForm: {
      adults: number;
      children: number;
      cabinaItinerarioMenor: number;
      camaCabinaList: number;
    },
    // tslint:disable-next-line:align
    purchaseCabinPassengers: PurchaseCabinPassenger[]
  ): PurchaseCabin {
    try {
      const selectedCabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
        this.cabinsSelected[purchaseCabinForm.cabinaItinerarioMenor];
      if (isNullOrUndefined(selectedCabinaItinerarioMenorWrapper)) {
        return null;
      }
      const listOfCamaCabinaList: CamaCabinaList[] =
        selectedCabinaItinerarioMenorWrapper.getCamaCabinaList();
      if (isNullOrUndefined(listOfCamaCabinaList)) {
        return null;
      }
      const camaCabinaList: CamaCabinaList =
        listOfCamaCabinaList[purchaseCabinForm.camaCabinaList];
      if (isNullOrUndefined(camaCabinaList)) {
        return null;
      }
      // tslint:disable-next-line:whitespace
      const purchaseCabin: PurchaseCabin = {
        adults: Number(purchaseCabinForm.adults),
        children: Number(purchaseCabinForm.children),
        cabinaItinerarioMenor:
          selectedCabinaItinerarioMenorWrapper.getCabinaItinerarioMenor(),
        // tslint:disable-next-line:object-literal-shorthand
        camaCabinaList: camaCabinaList,
        purchaseCabinPasssengers: purchaseCabinPassengers,
      } as PurchaseCabin;
      return purchaseCabin;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo;
  }
  public getTotalPrice(): number {
    try {
      const purchaseCabinPassengers: PurchaseCabinPassenger[] = [];
      this.purchaseCabinPassengers.forEach(
        (purchaseCabinPassengerArray: PurchaseCabinPassenger[]) => {
          purchaseCabinPassengerArray.forEach(
            (purchaseCabinPassenger: PurchaseCabinPassenger) => {
              purchaseCabinPassengers.push(purchaseCabinPassenger);
            }
          );
        }
      );
      let purchaseAirTicketDetailsAsArray: PurchaseAirTicketDetail[] = [];
      if (!isNullOrUndefined(this.purchaseAirTicketDetail)) {
        purchaseAirTicketDetailsAsArray =
          PurchaseRequestService.getPurchaseAirTicketDetailsAsArray(
            this.purchaseAirTicketDetail
          );
      }
      this.totalPurchase = this.purchaseRequestService.estimateTotalPrice(
        this.isShowOptionB,
        purchaseCabinPassengers,
        this.includeGalapagosAirTickets,
        purchaseAirTicketDetailsAsArray
      );
      return this.totalPurchase;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  setPassenger() {
    const purchaseCabins: PurchaseCabin[] = [];
    this.purchaseCabinPassengers = this.createCabinFormPassengers();
    this.purchaseAirTicketDetail = this.createPurchaseAirTicketDetails();
    this.cabinsSelected.forEach((purchasecabin, index) => {
      const cabinformVar = {
        adults:
          this.passengers[index] === undefined ? 0 : this.passengers[index],
        // tslint:disable-next-line: use-isnan
        children: this.children[index] === undefined ? 0 : this.children[index],
        cabinaItinerarioMenor: index,
        camaCabinaList: this.bedTypesSelected[index],
      };
      const purchaseCabinObject: PurchaseCabin = this.createPurchaseCabinObject(
        cabinformVar,
        this.purchaseCabinPassengers[index]
      );
      purchaseCabins.push(purchaseCabinObject);
    });
    this.purchaseRequestService.addCabins(purchaseCabins);
    this.purchaseRequestService.setNumberRequestAdults(this.totalAdults);
    this.purchaseRequestService.setNumberRequestChildren(this.totalChildren);
    this.purchaseRequestService.setNumberRequestPassengers(
      this.totalPassengers
    );
  }

  public toggleIncludeGalapagosAirTickets(): void {
    try {
      this.toggle();
      this.includeGalapagosAirTickets = !this.includeGalapagosAirTickets;
      this.includeGalapagosAirTickets
        ? this.purchaseRequestService.setAditionalServices(
            this.cruise.availability[this.cruise.iterIndex].aditionalServices
          )
        : this.purchaseRequestService.setAditionalServices([]);
      this.getTotalPrice();
      this.purchaseRequestService.setIncludeGalapagosAirTickets(
        this.includeGalapagosAirTickets
      );
      // this.reloadPurchaseAirTicketDetails();
    } catch (error) {
      console.error(error);
    }
  }

  nextSecondPart() {
    // set purhase and emmit;
    this.purchaseRequestService.setIncludeAirFare(this.includeAirFair);
    this.purchaseRequestService.setTotalCabinPrice();
    this.purchaseRequestService.setPurchaseTotalPrice();
    this.purchaseRequestService.purchaseRequestStatus =
      PurchaseRequestStatus.CABINS_COMPLETED;
    this.purchaseRequestService.setIncludeGalapagosAirTickets(
      this.includeGalapagosAirTickets
    );
    this.purchaseRequestService.setAdults(this.totalAdults);
    this.purchaseRequestService.setChildren(this.totalChildren);
    console.log(
      "valueTransfer",
      this.isIncludesFreeTransfers(this.isShowOptionA)
    );
    this.purchaseRequestService.setIncludesFreeTransfers(
      this.isIncludesFreeTransfers(this.isShowOptionA)
    );

    if (this.includeGalapagosAirTickets === true) {
      this.purchaseRequestService.setPurchaseAirTicket();
    }
    this.purchaseRequestService
      .getObservablePurchase()
      .subscribe((respPurchase) => {
        this.ngWebflowService.addWebFlowAction("PURCHASE", respPurchase);
      });
    this.router.navigate([this.urlParamsService.createurlForNextPage("2")]);
  }

  selected() {
    this.isValidSummarFinal = false;
    (document.querySelector("#cabins") as HTMLElement).style.opacity = "0.3";
    (document.querySelector("#cabinsOptions") as HTMLElement).hidden = true;
    (document.querySelector("#passengers") as HTMLElement).style.opacity =
      "0.3";
    (document.querySelector("#passengersOptions") as HTMLElement).hidden = true;
    $("html, body").animate(
      {
        scrollTop: $("#packages").offset().top,
      },
      1000
    );
  }

  select() {
    (document.querySelector("#passengers") as HTMLElement).style.opacity =
      "0.3";
    (document.querySelector("#passengersOptions") as HTMLElement).hidden = true;
    this.isValidSummarFinal = false;
    (document.querySelector("#cabins") as HTMLElement).style.opacity = "1";
    (document.querySelector("#cabinsOptions") as HTMLElement).hidden = false;
    $("html, body").animate(
      {
        scrollTop: $("#cabins").offset().top,
      },
      1000
    );
  }

  public toggleCabins(cabin: string): void {
    this.passengers = [];
    this.children = [];
    this.bedTypesSelected = [];
    this.deselectedCabin();
    this.ngWebflowService.addWebFlowAction(
      "SELECTED_CABINS",
      this.cabinsSelected
    );
    this.isValidCabin = false;
    switch (cabin) {
      case "A":
        this.includeAirFair = true;
        this.includeGalapagosAirTickets = false;
        this.isShowOptionA = !this.isShowOptionA;
        this.isShowOptionB = false;
        this.purchaseRequestService.setAditionalServices(
          this.cruise.availability[this.cruise.iterIndex].aditionalServices
        );
        break;
      case "B":
        this.includeAirFair = false;
        this.includeGalapagosAirTickets = false;
        this.isShowOptionB = !this.isShowOptionB;
        this.isShowOptionA = false;
        this.purchaseRequestService.setAditionalServices([]);
        break;
      case "R":
        this.includeAirFair = false;
        this.includeGalapagosAirTickets = false;
        this.isShowOptionB = false;
        this.isShowOptionA = false;
        this.purchaseRequestService.setAditionalServices([]);
        break;
    }
    this.ngWebflowService.addWebFlowAction("PACKAGE_SELECTED", {
      package: cabin,
    });
  }

  deselectedCabin() {
    this.cabinasItinerarioMenorWrapper.forEach((item) => {
      item.setIsSelected(false);
    });
    this.cabinsSelected = [];
  }

  getPictureCabin(nameImage): string {
    return this.urlImage + nameImage;
  }

  private initializeItinerariesImagesResource(): void {
    for (let i = 0; i < this.cruise.itineraries.length; i++) {
      if (
        this.cruise.itineraries[i].code ===
        this.cruise.availability[0].itinerary
      ) {
        this.itinerariesImagesResource =
          environment.app_resources.itinerario +
          this.cruise.itineraries[i].images[0];
        break;
      }
    }
  }

  private initializeBoatImageResource(): void {
    this.boatImagesResource =
      environment.app_resources.logoCruise + this.cruise.image;
  }

  reset() {
    this.isValidSummarFinal = false;
    this.toggleCabins("R");
    this.isValidSummarFinal = false;
    (document.querySelector("#packages") as HTMLElement).style.opacity = "0.3";
    (document.querySelector("#cabins") as HTMLElement).style.opacity = "0.3";
    (document.querySelector("#cabinsOptions") as HTMLElement).hidden = true;
    (document.querySelector("#passengers") as HTMLElement).style.opacity =
      "0.3";
    (document.querySelector("#passengersOptions") as HTMLElement).hidden = true;
    this.isDefaultOptions = false;
    this.isShowOptionA = false;
    this.isActive = false;
    this.bedTypesSelected = [];
    $("html, body").animate(
      {
        scrollTop: $("#calendar").offset().top,
      },
      1000
    );
  }
  getIndexPassenger() {
    if (this.index > Number(this.urlParamsService.getPax)) {
      this.index = 0;
    }
    this.index++;
    return this.index;
  }
  private isIncludesFreeTransfers(isIncludesAirFare: boolean): boolean {
    try {
      return this.cruiseService.includesFreeTransfers(
        this.cruise,
        this.cruise.iterIndex,
        isIncludesAirFare
      );
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  getWebLink() {
    const nameLenght = this.cruise.webLink.split("/").length;
    return "";
  }
  public hasTextFree(service: string) {
    const textArray = service.split(" ");
    let auxString = "<P>";
    textArray.forEach((text) => {
      if (text === "FREE") {
        auxString = auxString + " " + `<strong>${text}</strong>`;
      } else {
        auxString = auxString + " " + text;
      }
    });
    auxString = auxString + "</p>";
    return auxString;
  }

  hasWordInSA(word: string) {
    let myArrayOFAdServ = [];
    let index = -1; // no existe
    try {
      myArrayOFAdServ =
        this.cruise.availability[this.itineraryIndex].aditionalServices;
      for (let i = 0; i < myArrayOFAdServ.length; i++) {
        index = myArrayOFAdServ[i].indexOf(word);

        if (index >= 0) {
          break; // finish
        }
      }
      return index < 0 ? false : true;
    } catch (error) {
      return false;
    }
  }

  isRoyal(): boolean {
    return CompanyCodeEnum.ROYAL === this.company.code;
  }
}
