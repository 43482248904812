import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';
import { PriceRateService } from '../price-rate/price-rate.service';
import { PassengerType } from 'src/app/enums/passenger-type.enum';
import { PriceRate } from 'src/app/models/price-rate';
import { PriceRateDescriptor } from 'src/app/enums/price-rate-descriptor.enum';


@Injectable({
  providedIn: 'root'
})
export class AirTicketService {


  constructor( private priceRateService: PriceRateService ) { }

  public getAirTicketPrice( passengerType: PassengerType ): number {
    try {
      if ( passengerType === PassengerType.CHILD ) {
        return this.getChildrenAirTicketPrice();
      } else {
        return this.getAdultsAirTicketPrice();
      }
    } catch ( error ) {
      console.log('AirTicketService.getChildrenAirTicketPrice()');
      console.error( error );
      throw error;
    }
  }

  private getAdultsAirTicketPrice(): number {
    try {
      const priceRate: PriceRate = this.getAdultsAirTicketPriceRate();
      if ( isNullOrUndefined( priceRate ) ) { throw new Error; }
      return priceRate.valor;
    } catch ( error ) {
      console.log('AirTicketService.getAdultsAirTicketPrice()');
      console.error( error );
      throw error;
    }
  }

  private getChildrenAirTicketPrice(): number {
    try {
      const priceRate: PriceRate = this.getChildrenAirTicketPriceRate();
      if ( isNullOrUndefined( priceRate ) ) { throw new Error; }
      return priceRate.valor;
    } catch ( error ) {
      console.log('AirTicketService.getChildrenAirTicketPrice()');
      console.error( error );
      throw error;
    }
  }

  private getChildrenAirTicketPriceRate(): PriceRate {
    try {
      return this.priceRateService.getPriceRateByName( PriceRateDescriptor.ChildrenAirFare );
    } catch ( error ) {
      console.log('AirTicketService.getChildrenAirTicketPriceRate()');
      console.error( error );
      return null;
    }
  }

  private getAdultsAirTicketPriceRate(): PriceRate {
    try {
      return this.priceRateService.getPriceRateByName( PriceRateDescriptor.AirFare );
    } catch ( error ) {
      console.log('AirTicketService.getAdultsAirTicketPriceRate()');
      console.error( error );
      return null;
    }
  }
}
