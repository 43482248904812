import {isDevMode} from '@angular/core';

import {PayflowTransactionRequestClass} from './payflow-transaction-request.class';
import {PayflowPartnerEnum} from '../enums/payflow-partner.enum';
import {PayflowPaymentMethodEnum} from '../enums/payflow-payment-method.enum';
import {PayFlowTransactionTypeEnum} from '../enums/payflow-transaction-type.enum';
import {PayflowCreditCardPaymentRequestEnum} from '../enums/payflow-credit-card-payment-request.enum';
import {CreditCardClass} from './credit-card.class';
import {PayflowRequestVerbosityEnum} from '../enums/payflow-request-verbosity.enum';
import {UtilClass} from '../classes/Util.class';

export class CreditCardPayflowTransactionRequestClass extends PayflowTransactionRequestClass {

  private paymentMethod: PayflowPaymentMethodEnum;
  private transactionType: PayFlowTransactionTypeEnum;
  private amount: number;
  private creditCard: CreditCardClass;
  private verbosity: PayflowRequestVerbosityEnum;
  private originalTransactionId: string;
  private comment1: string;
  private comment2: string;
  private requestToServer: string;

  public constructor() {
    super();
  }

  public setPaymentMethod(paymentMethod: PayflowPaymentMethodEnum): void {
    this.paymentMethod = paymentMethod;
  }

  public getPaymentMethod(): PayflowPaymentMethodEnum {
    return this.paymentMethod;
  }

  public setTransactionType(transactionType: PayFlowTransactionTypeEnum): void {
    this.transactionType = transactionType;
  }

  public getTransactionType(): PayFlowTransactionTypeEnum {
    return this.transactionType;
  }

  public setAmount(amount: number): void {
    this.amount = amount;
  }

  public getAmount(): number {
    return this.amount;
  }

  public getPreparedAmount(): string {
    const amount: number = this.getAmount();
    return UtilClass.roundNumberToTwoDecimals(amount);
  }

  public setCreditCard(creditCard: CreditCardClass): void {
    this.creditCard = creditCard;
  }

  public getCreditCard(): CreditCardClass {
    return this.creditCard;
  }

  public setVerbosity(verbosity: PayflowRequestVerbosityEnum): void {
    this.verbosity = verbosity;
  }

  public getVerbosity(): PayflowRequestVerbosityEnum {
    return this.verbosity;
  }

  public getRequestToServer(): string {
    const requestToServer = this.generateEncodedNameValuePairRequestJson();
    this.requestToServer = requestToServer;
    return requestToServer;
  }

  public getOriginalTransactionId(): string {
    return this.originalTransactionId;
  }

  public setOriginalTransactionId(originalTransactionId: string) {
    this.originalTransactionId = originalTransactionId;
  }

  public getComment1(): string {
    return this.comment1;
  }

  public setComment1(comment: string) {
    this.comment1 = comment;
  }

  public getComment2(): string {
    return this.comment2;
  }

  public setComment2(comment: string) {
    this.comment2 = comment;
  }

  private generateEncodedNameValuePairRequestString(): string {
    let payflowRequestString = '';
    try {
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.creditCardNumber,
        this.getCreditCard().getProductNumber() + ''
      );
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.expirationDate,
        this.getCreditCard().getExpirationDate() + ''
      );
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.securityNumber,
        this.getCreditCard().getSecurityNumber() + ''
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToFirstName,
        this.getCreditCard().getBillToFirstName()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToLastName,
        this.getCreditCard().getBillToLastName()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToStreet,
        this.getCreditCard().getBillToStreet()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToStreet2,
        this.getCreditCard().getBillToStreet2()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToCity,
        this.getCreditCard().getBillToCity()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToState,
        this.getCreditCard().getBillToStateFirstTwoLetters()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToCountry,
        this.getCreditCard().getBillToCountry() + ''
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToPhoneNumber,
        this.getCreditCard().getBillToPhoneNumber()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToZip,
        this.getCreditCard().getBillToZip()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.billToEmail,
        this.getCreditCard().getBillToEmail()
      );

      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.paymentMethod,
        this.getPaymentMethod()
      );
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.transactionType,
        this.getTransactionType()
      );
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.amount,
        this.getPreparedAmount()
      );
      payflowRequestString += this.getElementNameValuePairString(
        PayflowCreditCardPaymentRequestEnum.verbosity,
        this.getVerbosity()
      );

      if (this.getOriginalTransactionId() !== null
        && this.getOriginalTransactionId() !== undefined) {
        payflowRequestString += this.getElementNameValuePairString(
          PayflowCreditCardPaymentRequestEnum.payflowTransactionId,
          this.getOriginalTransactionId()
        );
      }
    } catch ( error ) {
      console.error( error );
    }
    if (isDevMode()) {
      console.log('DevMode: ' + payflowRequestString);
    }
    return payflowRequestString;
  }

  private getElementNameValuePairString(elementKey: string, elementValue: string): string {
    if (this.isNullEmptyOrUndefined(elementKey) || this.isNullEmptyOrUndefined(elementValue)) {
      return '';
    }
    const ELEMENT_SEPARATOR = '&';
    const PAIR_VALUE_SEPARATOR = '=';
    const tagLength: string = this.getTagLengthForAValue(elementValue);
    return elementKey + tagLength + PAIR_VALUE_SEPARATOR + elementValue + ELEMENT_SEPARATOR;
  }

   private getTagLengthForAValue(elementValue: string): string {
    const elementLength: number = elementValue.length;
    return '[' + elementLength + ']';
  }

  private isNullEmptyOrUndefined(value: string): boolean {
    return value === null || value === undefined || value.trim() === '';
  }
  private generateEncodedNameValuePairRequestJson(): any {
    try {
        const payflowRequestString = {
        acct: btoa(this.getCreditCard().getProductNumber().toString()),
        expdate: btoa( this.getCreditCard().getExpirationDate()),
        cvv2: btoa( this.getCreditCard().getSecurityNumber().toString()),
        billtofirstname: btoa(  this.getCreditCard().getBillToFirstName()),
        billtolastname: btoa(this.getCreditCard().getBillToLastName()),
        billtostreet: btoa(this.getCreditCard().getBillToStreet()),
        billtostreet2: btoa( this.getCreditCard().getBillToStreet2()),
        billtocity: btoa(this.getCreditCard().getBillToCity()),
        billtostate: btoa(this.getCreditCard().getBillToStateFirstTwoLetters()),
        billtocountry: btoa(this.getCreditCard().getBillToCountry().toString()),
        billtophonenum: btoa(this.getCreditCard().getBillToPhoneNumber()),
        billtozip: btoa(this.getCreditCard().getBillToZip()),
        billtoemail: btoa(this.getCreditCard().getBillToEmail()),
        tender: btoa(this.getPaymentMethod()),
        trxtype: btoa(this.getTransactionType()),
        amt: btoa(this.getPreparedAmount()),
        verbosity: btoa(this.getVerbosity())
       };
        return payflowRequestString;
    } catch (error) {
      console.error(error);
    }
  }
}
