import { PriceRateDescriptor } from './../../../enums/price-rate-descriptor.enum';
import { Injectable } from '@angular/core';
import { PriceRate } from 'src/app/models/price-rate';

@Injectable({
  providedIn: 'root'
})
export class PriceRateService {

  private _priceRates: PriceRate[] = [];

  constructor() {
    this.fillPriceRates();
  }

  get priceRates(): PriceRate[] {
    return this._priceRates;
  }

  set priceRates(value: PriceRate[]) {
    this._priceRates = value;
  }

  addPriceRate(priceRate: PriceRate): void {
    this._priceRates.push(priceRate);
  }

  private fillPriceRates(): void {
   

    this.addPriceRate({
      codigo: 3,
      nombre: PriceRateDescriptor.AirFare_C5_RACK_XOFF,
      valor: 0.84
    });

    this.addPriceRate({
      codigo: 3.1,
      nombre: PriceRateDescriptor.AirFare_C4_RACK_XOFF,
      valor: 0.85
    });

    this.addPriceRate({
      codigo: 4,
      nombre: PriceRateDescriptor.AirFare_C5_2X1,
      valor: 0.86
    });
    this.addPriceRate({
      codigo: 4.1,
      nombre: PriceRateDescriptor.AirFare_C4_2X1,
      valor: 0.86
    });

    this.addPriceRate({
      codigo: 5, // todo
      nombre: PriceRateDescriptor.AirFare_C5_NET,
      valor: 0.86
    });

    this.addPriceRate({
      codigo: 5.1, // todo
      nombre: PriceRateDescriptor.AirFare_C4_NET,
      valor: 0.86
    });



    this.addPriceRate({
      codigo: 6,
      nombre: PriceRateDescriptor.C5_RACK_XOFF,
      valor: 0.80
    });
    this.addPriceRate({
      codigo: 6.1,
      nombre: PriceRateDescriptor.C4_RACK_XOFF,
      valor: 0.84
    });


    this.addPriceRate({
      codigo: 7,
      nombre: PriceRateDescriptor.C5_2X1,
      valor: 0.85
    });
    this.addPriceRate({
      codigo: 7.1,
      nombre: PriceRateDescriptor.C4_2X1,
      valor: 0.85
    });

    this.addPriceRate({
      codigo: 8,
      nombre: PriceRateDescriptor.C5_NET,
      valor: 0.86
    });
    this.addPriceRate({
      codigo: 8,
      nombre: PriceRateDescriptor.C4_NET,
      valor: 0.86
    });
    this.addPriceRate({
      codigo: 9,
      nombre: PriceRateDescriptor.AirFare_C3C2_RACK_XOFF,
      valor: 0.87
    });

    this.addPriceRate({
      codigo: 10,
      nombre: PriceRateDescriptor.AirFare_C3C2_2X1,
      valor: 0.87
    });

    this.addPriceRate({
      codigo: 11,
      nombre: PriceRateDescriptor.AirFare_C3C2_NET,
      valor: 0.87
    });

    this.addPriceRate({
      codigo: 12,
      nombre: PriceRateDescriptor.C3C2_RACK_XOFF,
      valor: 0.85
    });

    this.addPriceRate({
      codigo: 13,
      nombre: PriceRateDescriptor.C3C2_2X1,
      valor: 0.86
    });

    this.addPriceRate({
      codigo: 14,
      nombre: PriceRateDescriptor.C3C2_NET,
      valor: 0.87
    });

  }

  public getPriceRateByCode(rateCode: number): PriceRate {
    for (let i = 0; i < this.priceRates.length; i++) {
      if (this.priceRates[i].codigo === rateCode) {
        return this.priceRates[i];
      }
    }
    return null;
  }

  public getPriceRateValueByCode(rateCode: number): number {
    const priceRate = this.getPriceRateByCode(rateCode);
    return priceRate ? priceRate.valor : null;
  }

  public getPriceRateByName(priceRateDescriptor: PriceRateDescriptor): PriceRate {
    for (let i = 0; i < this.priceRates.length; i++) {
      if (this.priceRates[i].nombre === priceRateDescriptor) {
        return this.priceRates[i];
      }
    }
    return null;
  }

}
