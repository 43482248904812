import { NgGtcPayflowModule } from './ngGtcPayflow/ng-payflow.module';
import { ReserveModule } from './reserve/reserve.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgWebflowModule, NgWebflowService, NgWebflowPersistenceService } from 'ngweflow';
import { LocalStorageModule } from 'angular-2-local-storage';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FirebaseService } from './services/firebase/firebase.service';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { PurchaseRequestService } from './reserve/services/purchase-request/purchase-request.service';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    ReserveModule,
    NgGtcPayflowModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    RouterModule.forRoot([]),
    ScrollToModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    NgWebflowModule, LocalStorageModule.forRoot({
      prefix: 'gtc-e-commerce-galavail',
      storageType: 'localStorage'
    }),
    BrowserAnimationsModule
  ],
  providers: [
    NgWebflowService,
    NgWebflowPersistenceService,
    FirebaseService,
    PurchaseRequestService,
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    HttpModule,
    NgWebflowModule,
    HttpClientModule,
    ],
})
export class AppModule { }
