import { PromoCode } from './../../../enums/promo-code.enum';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { GalavailRestService } from 'clientwsGalavail';
import { Injectable, Inject } from '@angular/core';
import { PriceRateService } from '../price-rate/price-rate.service';
import { PassengerType } from 'src/app/enums/passenger-type.enum';
import { PriceRateDescriptor } from 'src/app/enums/price-rate-descriptor.enum';
import { PriceRate } from 'src/app/models/price-rate';

@Injectable({
  providedIn: 'root'
})
export class CabinService {
  public static getPrice(): number {
    return 0;
  }

  constructor(
    private galavailRestService: GalavailRestService,
    @Inject(PriceRateService) private priceRateService: PriceRateService) { }

  public getCabins(searchRequestObject: any) {

    try {
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.galavailRestService
        .request<any[]>('', 'request_search_cabins', {
          headers: httpHeaders,
        }, searchRequestObject)
        .catch(error => {

          return this.handleError(error);
        });

    } catch (error) {

      console.error(error);

      return this.handleError(error);
    }

  }
  private handleError(error: any): Observable<any> {
    console.error('Error in Cabin Service');
    console.error(error);
    return Observable.throw(error);
  }

  public getAirFarePriceRate(passengerType: PassengerType): number {
    let airFarePriceRate: PriceRateDescriptor;
    if (passengerType === PassengerType.CHILD) {
      airFarePriceRate = PriceRateDescriptor.ChildrenAirFare;
    } else {
      airFarePriceRate = PriceRateDescriptor.AirFare;
    }

    const priceRateAirFare: PriceRate = this.priceRateService.getPriceRateByName(airFarePriceRate);
    return isNullOrUndefined(priceRateAirFare) ? 0 : priceRateAirFare.valor;
  }
  public getAppPrice(
    price: number,
    cruiseCategory: number,
    promoCode: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    agencyPercent?: number
    ): number {
    try {
      if (!isNullOrUndefined(agencyPercent)) {
        return this.getPriceToPublic(price, includeAirFare, passengerType, (agencyPercent / 100));
      }
      let priceRate: PriceRate;
      let airFare = 0;
      const cruisePricePromo: number = price;
      if (includeAirFare) {
        let airFarePriceRate: PriceRateDescriptor;
        if (passengerType === PassengerType.CHILD) {
          airFarePriceRate = PriceRateDescriptor.ChildrenAirFare;
        } else {
          airFarePriceRate = PriceRateDescriptor.AirFare;
        }

        const priceRateAirFare: PriceRate = this.priceRateService.getPriceRateByName(airFarePriceRate);
        airFare = isNullOrUndefined(priceRateAirFare) ? 0 : priceRateAirFare.valor;
      }


      if (cruiseCategory === 5 || cruiseCategory === 4) {
        if (cruiseCategory === 5) {
          if (includeAirFare) {
            if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C5_RACK_XOFF);
            } else if (promoCode === PromoCode.PROMO_2_X_1) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C5_2X1);
            } else if (promoCode === PromoCode.PROMO_NET) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C5_NET);
            }
          } else {
            if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C5_RACK_XOFF);
            } else if (promoCode === PromoCode.PROMO_2_X_1) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C5_2X1);
            } else if (promoCode === PromoCode.PROMO_NET) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C5_NET);
            }
          }
        } else {
          if (includeAirFare) {
            if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C4_RACK_XOFF);
            } else if (promoCode === PromoCode.PROMO_2_X_1) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C4_2X1);
            } else if (promoCode === PromoCode.PROMO_NET) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C4_NET);
            }
          } else {
            if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C4_RACK_XOFF);
            } else if (promoCode === PromoCode.PROMO_2_X_1) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C4_2X1);
            } else if (promoCode === PromoCode.PROMO_NET) {
              priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C4_NET);
            }
          }

        }
      } else if (cruiseCategory === 3 || cruiseCategory === 2) {

        if (includeAirFare) {
          if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C3C2_RACK_XOFF);
          } else if (promoCode === PromoCode.PROMO_2_X_1) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C3C2_2X1);
          } else if (promoCode === PromoCode.PROMO_NET) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.AirFare_C3C2_NET);
          }
        } else {
          if (promoCode === PromoCode.PROMO_RAC || promoCode === PromoCode.PROMO_OFF ) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C3C2_RACK_XOFF);
          } else if (promoCode === PromoCode.PROMO_2_X_1) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C3C2_2X1);
          } else if (promoCode === PromoCode.PROMO_NET) {
            priceRate = this.priceRateService.getPriceRateByName(PriceRateDescriptor.C3C2_NET);
          }
        }
      } else {
        priceRate = null;
      }
      const priceRateValue = priceRate ? priceRate.valor : 1;

      return Math.round((cruisePricePromo / 1) + airFare);
    } catch (error) {
      console.error('CabinaService.getAppPrice() > An error appeared while trying to calculate the price: ' + error);
      return null;
    }
  }
  getPriceToPublic(price: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    agencyPercent?: number
  ) {
    let airFare = 0;
    const cruisePricePromo: number = price;

    if (includeAirFare) {
      let airFarePriceRate: PriceRateDescriptor;
      if (passengerType === PassengerType.CHILD) {
        airFarePriceRate = PriceRateDescriptor.ChildrenAirFare;
      } else {
        airFarePriceRate = PriceRateDescriptor.AirFare;
      }
      const priceRateAirFare: PriceRate = this.priceRateService.getPriceRateByName(airFarePriceRate);
      airFare = isNullOrUndefined(priceRateAirFare) ? 0 : priceRateAirFare.valor;
    }
    return Math.floor((cruisePricePromo - cruisePricePromo * agencyPercent) + airFare);

  }
}
