import { Injectable } from '@angular/core';
import { PaymentsType } from '../../models/payments-type';

@Injectable({
  providedIn: 'root'
})
export class PaymentsTypeService {

  private paymentsType: PaymentsType[] = [];

  constructor() {
    this.fillPromos();
  }

  private fillPromos(): void {
    this.addPromo({
      code: 6,
      value: "Visa",
      type: 4,
      img: "visa.svg",
      heightValue: 25
    });

    this.addPromo({
      code: 7,
      value: "Mastercard",
      type: 4,
      img: "mastercard.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 8,
      value: "American Express",
      type: 4,
      img: "amex.svg",
      heightValue: 35
    });
    this.addPromo({
      code: 10,
      value: "Discover",
      type: 4,
      img: "discover.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 11,
      value: "JCB",
      type: 4,
      img: "jcb.svg",
      heightValue: 35
    });
    

    this.addPromo({
      code: 12,
      value: "Diners Club",
      type: 4,
      img: "diners-club.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 13,
      value: "UnionPay",
      type: 4,
      img: "union-pay.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 5,
      value: "Paypal",
      type: 5,
      img: "paypal.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 4,
      value: "Wells",
      type: 6,
      img: "wells.svg",
      heightValue: 35
    });


    this.addPromo({
      code: 3,
      value: "td",
      type: 6,
      img: "td.svg",
      heightValue: 35
    });


    this.addPromo({
      code: 2,
      value: "Zelle",
      type: 6,
      img: "zelle.svg",
      heightValue: 25
    });

    this.addPromo({
      code: 9,
      value: "Transfer in Ec",
      type: 7,
      img: "ecuador-account.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 1,
      value: "bank international",
      type: 6,
      img: "international-bank.svg",
      heightValue: 35
    });

    this.addPromo({
      code: 13,
      value: "Direct transfer",
      type: 8,
      img: "direct-transfer.svg",
      heightValue: 35
    });

  }

  private addPromo(promo: PaymentsType) {
    this.paymentsType.push(promo);
  }

  public getPaymentsType(): PaymentsType[] {
    return this.paymentsType;
  }

  public getPaymentsByType(type: number): PaymentsType[] {
    let response = [];
    for (const paymentType of this.getPaymentsType()) {
      if (paymentType.type === type) {
        response.push(paymentType);
      }
    }
    return response;
  }
  
  public getAllPaymentsBytypes(types: number[]){
    let response = [];
    for (const paymentType of this.getPaymentsType()) {
      let index = types.find(item => item === paymentType.type)
      if (index >= 0) {
        response.push(paymentType);
      }
    }
    return response;
  }
}
