import { ModalComponent } from './shared/components/modal/modal.component';
import { CompanyParamsService } from './../services/companyParams/company-params.service';
import { PassengerFormTabIndexDirective } from './directives/passenger-form-tab-index/passenger-form-tab-index.directive';
import { ShowCabinPassengerNamePipe } from './pipes/show-cabin-passenger-name.pipe';
import { CabinOrdinalNamePipe } from './pipes/cabin-ordinal-name.pipe';
import { NgGtcPayflowModule } from './../ngGtcPayflow/ng-payflow.module';
import { TourSummaryComponent } from './components/tour-summary/tour-summary.component';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ItineraryDetailsComponent } from './shared/components/itinerary-details/itinerary-details.component';
import { WebservicesModule, SearchService, GalavailRestService } from 'clientwsGalavail';
import { CheckoutSecondComponent } from './components/checkout-second/checkout-second.component';
import { EndSlideComponent } from './components/end-slide/end-slide.component';
import { CheckoutFourthComponent } from './components/checkout-fourth/checkout-fourth.component';
import { CheckoutThirdComponent } from './components/checkout-third/checkout-third.component';
import { UpSlideComponent } from './components/up-slide/up-slide.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryService } from '../core/services/country/country.service';
import { MonthService } from '../core/services/month/month.service';
import { ShowPassengerIndexInPassengerFormPipe } from './pipes/show-passenger-index-in-passenger-form.pipe';
import { TourLengthService } from './services/tour-length/tour-length.service';
import { ServerDateService } from 'ngweflow';
import { ContactFormService } from './services/contact-form/contact-form.service';
import { ContentComponent } from './components/content/content.component';
import { RESERVE_ROUTES } from './reserve.routes';
import { Transform2dArrayIndexPipe } from './pipes/transform-2d-array-index.pipe';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { ModalComponentShare } from './components/share-modal/modal/modal.component';
import { CollapseModule } from 'ngx-bootstrap';
import { TablePaymentSummaryComponent } from './components/table-payment-summary/table-payment-summary.component';
@NgModule({
  declarations: [
    CheckoutComponent,
    TourSummaryComponent,
    ItineraryDetailsComponent,
    ModalComponent,
    CheckoutSecondComponent,
    EndSlideComponent,
    CheckoutFourthComponent,
    CheckoutThirdComponent,
    UpSlideComponent,
    CabinOrdinalNamePipe,
    ShowCabinPassengerNamePipe,
    ShowPassengerIndexInPassengerFormPipe,
    PassengerFormTabIndexDirective,
    ContentComponent,
    Transform2dArrayIndexPipe,
    ShareModalComponent,
    ModalComponentShare,
    TablePaymentSummaryComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    WebservicesModule,
    FormsModule,
    ReactiveFormsModule,
    NgGtcPayflowModule,
    RESERVE_ROUTES,
    CollapseModule
  ],
  exports: [
    CheckoutComponent,
    TourSummaryComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    SearchService,
    CountryService,
    MonthService,
    TourLengthService,
    GalavailRestService,
    ServerDateService,
    ContactFormService,
    CompanyParamsService


    // SlimLoadingBarService
  ]
})
export class ReserveModule { }
