import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  public counter = 10;
  constructor() { }

  ngOnInit() {
    const intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      console.log(this.counter)
      if (this.counter < 1) {
       // window.location.href = 'https://galavail.com/galavail-web/';
      }
    }, 1000)

  }
  reloadPage() {

  }

  backPage() {

  }

}
