import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public todayDate: number = new Date().getFullYear();
  constructor(
    public companyParamsService: CompanyParamsService
  ) { }

  ngOnInit() {
  }
  configPrivacyUrl() {
    return 'mailto:' + this.companyParamsService.getInfoEmail();
  }
  
  isTooDark(hexcolor: any) {
    if ( hexcolor != null ) {
      const r = parseInt(hexcolor.substr(1,2),16);
      const g = parseInt(hexcolor.substr(3,2),16);
      const b = parseInt(hexcolor.substr(4,2),16);
      const yiq = ((r * 299) + ( g * 587 ) + ( b * 114 )) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return (yiq < 150);
    } else {
      return false;
    }

  }

}
