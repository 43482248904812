import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transform2dArrayIndex'
})
export class Transform2dArrayIndexPipe implements PipeTransform {
  transform(innerIndex: number, outerIndex: number, arrayOfArrays: any[][]): number {
    try {
      let resultIndex = 0;
      for ( let i = 0; i < arrayOfArrays.length && i <= outerIndex; i++ ) {
        for ( let o = 0; o < arrayOfArrays[ i ].length; o++ ) {
          if ( i < outerIndex || ( i === outerIndex && o <= innerIndex ) ) { resultIndex += 1; }
        }
      }
      return resultIndex;
    } catch ( error ) {
      console.log( 'Transform2dArrayIndexPipe()' );
      console.error( error );
      return 0;
    }
  }
}
