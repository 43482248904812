import { CabinaItinerarioMenorWrapper } from './../../../classes/cabina-itinerario-menor-wrapper';
import { CabinService } from './../cabin/cabin.service';
import { PassengerType } from './../../../enums/passenger-type.enum';
import { PromoCode } from './../../../enums/promo-code.enum';
import { Cruise } from 'src/app/models/cruise';
import { DayPeriod } from './../../../models/day-period-model';
import { Injectable } from '@angular/core';
import { GalavailRestService } from 'clientwsGalavail';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { getJSDocThisTag } from 'typescript';
import { TipoPaqueteEcommerceEnum } from 'src/app/enums/type-package-ecommerce.enum';

@Injectable({
  providedIn: 'root'
})
export class CruiseService {
  public readonly CODIGO_GALAVEN = 203;

  constructor(
    private galavailRestService: GalavailRestService,
    private cabinaService: CabinService,
  ) { }

  public search(searchRequestObject: any) {

    try {
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const body = this.jsonSearch(searchRequestObject);
      return this.galavailRestService
        .request<any[]>('', 'request_search_ecom', {
          headers: httpHeaders,
        }, body)
        .catch(error => {

          return this.handleError(error);
        });

    } catch (error) {

      console.error(error);

      return this.handleError(error);
    }

  }
  private jsonSearch(search): any {
    return {
      month: search.month,
      year: search.year,
      adults: search.adults,
      children: search.children,
      category:
        isNullOrUndefined(search.cruiseCategory) ||
          isNullOrUndefined(search.cruiseCategory.value)
          ? 0
          : search.cruiseCategory.value,
      type:
        isNullOrUndefined(search.cruiseType) ||
          isNullOrUndefined(search.cruiseType.name)
          ? 0
          : search.cruiseType.name,
      length: isNullOrUndefined(search.tourLength) ? 0 : search.tourLength.value,
      boat: isNullOrUndefined(search.cruise) ? 0 : search.cruise,
      order: search.order.value,
      page: search.page
    };
  }
  public createIterDay(cruise: any): {}[] {
    const AllItinerary: any[] = [];
    const mystringkey = cruise.availability[cruise.iterIndex].itinerary;
    cruise.itineraries.forEach(itemitenerary => {
      const myotherkey = itemitenerary.code;
      if (mystringkey === myotherkey) {
        itemitenerary.days.map(v => {
          const dataBundle: any = {};
          dataBundle.diaSemana = v.name;
          dataBundle.morningActivities = [];
          dataBundle.afternoonActivities = [];
          v.schedules.map(e => {
            e.activities.forEach(activity => {
              if (e.type === DayPeriod.AM) {
                dataBundle.morningActivities.push({
                  isla: activity.name,
                  period: e.type,
                  nombre: activity.description,
                  optional: activity.optional
                });
              } else {
                dataBundle.afternoonActivities.push({
                  isla: activity.name,
                  period: e.type,
                  nombre: activity.description,
                  optional: activity.optional
                });
              }
            });
          });
          AllItinerary.push(dataBundle);
        });
      }
    });
    return AllItinerary;
  }

  private handleError(error: any): Observable<any> {
    console.error('BarcoService.getCruises()-> HandleError');
    console.error(error);
    return Observable.throw(error);
  }

  public includesFreeTransfers(
    cruise: Cruise,
    selectedItinerary: number,
    isIncludeAirFare: boolean
  ): boolean {
    try {
      return cruise.availability[selectedItinerary].aditionalServices.length > 0
        && (this.hasWordInSA("TRANSFER", cruise) || this.hasWordInSA("TRANSFERS", cruise))
        && isIncludeAirFare;
      /*
      const promoCode: number =
        cruise.availability[selectedItinerary].aditionalServices.length>0 ;
      return (
        (cruise.category === 4 || cruise.category === 5) &&
        isIncludeAirFare === true &&
        (promoCode === PromoCode.PROMO_RAC ||
          promoCode === PromoCode.PROMO_OFF ||
          promoCode === PromoCode.PROMO_2_X_1) &&
        cruise.code !== this.CODIGO_GALAVEN
      );*/
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  hasWordInSA(word: string, cruise: Cruise): boolean {
    let myArrayOFAdServ = [];
    let resp = false;
    let index = -1; // no existe
    for (let j = 0; j < cruise.availability.length; j++) {
      const element = cruise.availability[j];
      myArrayOFAdServ = element.aditionalServices


      for (let i = 0; i < myArrayOFAdServ.length; i++) {
        index = (myArrayOFAdServ[i]).toUpperCase().indexOf(word)
        if (index >= 0) {
          resp = true;
          break; // finish
        }
      }
      if (index >= 0) {
        resp = true;
        break; // finish
      }

    }
    return resp;
  }
  public doesItineraryHasPromo(
    cruise: Cruise,
    itineraryIndex: number
  ): boolean {
    try {
      return (
        !isNullOrUndefined(cruise.availability[itineraryIndex].promotionType) &&
        cruise.availability[itineraryIndex].promotionType !== PromoCode.PROMO_RAC
      );
    } catch (error) {
      return false;
    }
  }

  public getCruisePriceinNewFormat(
    cruise: Cruise,
    selectedItinerary: number,
    includeAirFare: boolean
  ): number {
    try {
      // const mycode = cruise.mostAffordableDeparture
      const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper = new CabinaItinerarioMenorWrapper(
        cruise.availability[selectedItinerary]
      );

      return cabinaItinerarioMenorWrapper.getAppPrice(
        cruise.category,
        includeAirFare,
        false,
        PassengerType.ADULT,
        this.cabinaService
      );
    } catch (error) {
      console.error(error);
    }
  }
  public isCalipso(cruise: Cruise): boolean {
    try {
      if (cruise.name === 'Calipso Diving' || cruise.name === 'Calipso') {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      return false
    }
  }
}
