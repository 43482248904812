import {Injectable} from '@angular/core';
import {forEach} from '@angular/router/src/utils/collection';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import {environment} from '../../../../environments/environment';
import 'rxjs/add/operator/concatMap';

@Injectable()
export class CountryInfoService {

    private countries: any[] = [];

    public constructor(private httpClient: HttpClient) {}

    public getListOfCountries(): Observable<any[]> {
        if (this.countries.length < 1) {
            return this.requestCountriesToServer();
        } else {
            return Observable.of(this.countries);
        }
    }

    private requestCountriesToServer(): Observable<any[]> {
        const httpHeaders: HttpHeaders = this.getRequestHeaders();
        const restCountriesServer: string = environment.restCountriesServer;
        return this.httpClient.get<any[]>(restCountriesServer, { headers: httpHeaders })
        .map((response: any[]) => {
            this.countries = response;
            return this.countries;
        }).catch((error: any) => {
            console.error('There was an error while trying to get the list of countries');
            return Observable.of([]);
        });
    }

    private getRequestHeaders(): HttpHeaders {
        return new HttpHeaders();
    }
}
