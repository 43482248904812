import { Injectable } from '@angular/core';
import { TourLength } from 'src/app/models/tour-length';

@Injectable()
export class TourLengthService {

  private tourLengths: TourLength[];

  constructor() {
    this.fillTourLengths();
  }

  public fillTourLengths(): void {
    const tourLengths: TourLength[] = [
      { value: 8, name: '8 Days', shortName: '8-Day' },
      { value: 6, name: '6 Days', shortName: '6-Day' },
      { value: 5, name: '5 Days', shortName: '5-Day' },
      { value: 4, name: '4 Days', shortName: '4-Day' },
    ];
    this.setTourLengths( tourLengths );
  }

  public getTourLengths(): TourLength[] {
    return this.tourLengths;
  }

  public setTourLengths(tourLengths: TourLength[] ): void {
    this.tourLengths = tourLengths;
  }

  public getTourLength(tourLengthValue: number): TourLength {
    // tslint:disable-next-line:prefer-for-of
    for ( let i = 0; i < this.getTourLengths().length; i++ ) {
      if ( this.getTourLengths()[i].value === tourLengthValue ) {
        return this.getTourLengths()[i];
      }
    }
    return null;
  }

  public getTourLengthName(tourLengthValue: number): string {
    return this.getTourLength(tourLengthValue) ? this.getTourLength(tourLengthValue).name : null;
  }

  public getTourLengthShortName(tourLengthValue: number): string {
    return this.getTourLength(tourLengthValue) ? this.getTourLength(tourLengthValue).shortName : null;
  }
}
