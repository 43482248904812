import { PromoCode } from './../../../enums/promo-code.enum';
import { PromoService } from './../../services/promo/promo.service';
import { Purchase } from 'src/app/models/purchase';
import { Component, OnInit, Input, Output } from '@angular/core';
import { CruiseWrapper } from 'src/app/classes/cruise-wrapper';
import { PurchaseCabin } from 'src/app/models/purchase-cabin';
import { Availability } from '../../../models/availability';
import { PurchaseCabinPassenger } from 'src/app/models/purchase-cabin-passenger';
import { PurchaseRequestService } from '../../services/purchase-request/purchase-request.service';
import { CruiseService } from '../../services/cruise/cruise.service';
import { TourLengthService } from '../../services/tour-length/tour-length.service';
import { isNullOrUndefined } from 'util';
import { PurchaseRequestStatus } from 'src/app/enums/purchase-request-status.enum';
import { PurchaseAirTicket } from 'src/app/models/purchase/purchase-air-ticket';
import { FormArray, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanyParamsService } from 'src/app/services/companyParams/company-params.service';
import { Cruise } from 'src/app/models/cruise';
import { TextInfoTransferEnum } from '../../../enums/text-info-transfer.enum';

@Component({
  selector: 'app-tour-summary',
  templateUrl: './tour-summary.component.html',
  styleUrls: ['./tour-summary.component.css']
})
export class TourSummaryComponent implements OnInit {
  @Input() public asideBoolean: boolean;
  @Input() public unActiveTourIncludes: boolean;
  @Output() public purchaseContext: boolean;
  @Input() public boatImageResource: string;
  @Input() public cruiseInput: Cruise;
  
  public cruiseWrapper: CruiseWrapper;
  public cruise: any;
  public itineraries: any;
  public tourLengthShortName: string;
  public adults: number;
  public children: number;
  public tourLength: number;
  public purchaseCabinPassengers: PurchaseCabinPassenger[];
  // @Output() public purchaseCabinInformation: string[] = new EventEmitter();
  public purchaseCabinInformation: string[];
  public purchaseCabinInformationBed: string[];
  public purchasePassengerForm: FormGroup;
  public cabinsNumber: PurchaseCabin[];
  public cruiseStartDate: string;
  public startEndDates: string;
  public startEndDatesType: string;
  public totalCabinPrice: number;
  public totalPrice: number;
  public includesFreeTransfers: boolean;
  public includesGalapagosAirTickets: boolean;
  public includeAirFare: boolean;
  public isCalipso: boolean;
  public aditionalServices: string[];
  public purchaseRequestStatusType: typeof PurchaseRequestStatus = PurchaseRequestStatus;
  PROMO_RAC = PromoCode.PROMO_RAC;
  mainColor: string;
  constructor(
    public purchaseRequestService: PurchaseRequestService,
    private cruiseService: CruiseService,
    private tourLengthService: TourLengthService,
    public promoService: PromoService,
    public companyParamsService: CompanyParamsService
  ) { }

  ngOnInit() {
    this.mainColor= this.companyParamsService.getMainColor();
    if (this.asideBoolean) {
      this.cruiseWrapper = new CruiseWrapper(this.cruise);
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.aditionalServices = this.purchaseRequestService.getAditionalServices();
      this.isCalipso = this.cruiseService.isCalipso(this.cruise);
      this.itineraries = this.cruiseService.createIterDay(this.cruise);
      this.tourLengthShortName = this.cruise.availability[this.cruise.iterIndex].length.substring(0, 1);
      this.adults = this.purchaseRequestService.getNumberRequestAdults();
      this.children = this.purchaseRequestService.getNumberRequestChildren();
      this.tourLength = this.cruise.availability[this.cruise.iterIndex].length;
      this.purchaseCabinPassengers = this.purchaseRequestService.getPurchaseCabinPassengers();
      this.purchaseCabinInformation = this.getPurchaseCabinInformation();
      this.purchaseCabinInformationBed = this.getPurchaseCabinInformationBeds();
      this.cabinsNumber = this.purchaseRequestService.purchase.cabins;
      this.cruiseStartDate = this.cruise.availability[this.cruise.iterIndex].date;
      this.startEndDates = this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType = this.cruise.availability[this.cruise.iterIndex].name;
      this.totalCabinPrice = this.purchaseRequestService.purchase.totalCabinPrice;
      this.totalPrice = this.purchaseRequestService.purchase.totalPrice;
      this.includesFreeTransfers = this.purchaseRequestService.purchase.includesFreeTransfers;
      this.includesGalapagosAirTickets = this.purchaseRequestService.purchase.includesGalapagosAirTickets;
      this.includeAirFare = this.purchaseRequestService.purchase.includeAirFare;
    }
    if (this.adults) {
      this.asideBoolean = true;
    }
  }

  private getPurchaseCabinInformation(): string[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinObjectSummary().map(
        (purchaseCabinObjectSummary: { cabinaItinerarioNombre: string }) => {
          return purchaseCabinObjectSummary.cabinaItinerarioNombre;
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  hasIncludes(): boolean {
    return  (this.includesGalapagosAirTickets || this.includeAirFare)
  }
  
  getUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo
  }
  public isWithThirtyPercent(): boolean {
    try {
      return this.purchaseRequestService.getIsSelectedThirtyPerent();
    } catch (error) {
      console.log('TourSummaryComponent.isPurchaseIncludeAirTickets()');
      console.error(error)
    }
  }
  private getPurchaseCabinInformationBeds(): string[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinObjectSummary().map(
        (purchaseCabinObjectSummary: { camaCabinaNombre: string }) => {
          return purchaseCabinObjectSummary.camaCabinaNombre;
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getPurchasePassengerFormControls(): FormArray[] {
    try {
      return (this.purchasePassengerForm.controls.purchasePassengers as FormArray).controls as FormArray[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getTourLengthName(): string {
    try {
      return this.tourLengthService.getTourLengthShortName(this.cruise.itinerarios[this.cruise.iterIndex].dias);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getPurchaseRequestStatus(): PurchaseRequestStatus {
    try {
      return this.purchaseRequestService.purchaseRequestStatus;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getNumberOfPassengers(): number {
    try {
      return this.purchaseRequestService.getNumberOfPassengers();
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public getPurchaseCabinTypeSummary(): string {
    try {
      return this.purchaseRequestService.getPurchaseCabinTypeSummary();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getPurchaseTotalPrice(): number {
    try {
      return this.purchaseRequestService.getTotalPrice();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public isPurchaseIncludeAirTickets(): boolean {
    try {
      return this.purchaseRequestService.isIncludeGalapagosAirTickets();
    } catch (error) {
      console.error(error);
    }
  }
  public getPurchaseWithOutDiscount(): number {
    try {
      return this.purchaseRequestService.getTotalPriceWithoutDiscount();
    } catch (error) {
      console.log('TourSummary.getPurchaseTotalPrice()');
      console.error(error);
      throw error;
    }
  }
  public getGalapagosAirTicketsTotal(): number {
    try {
      if (isNullOrUndefined(this.isPurchaseIncludeAirTickets()) || this.isPurchaseIncludeAirTickets() === false) {
        return 0;
      }
      const purchaseAirTicket: PurchaseAirTicket = this.purchaseRequestService.getPurchaseAirTicket();
      if (isNullOrUndefined(purchaseAirTicket)) { return 0; }
      return purchaseAirTicket.totalPurchaseAirTicketPrice;
    } catch (error) {
      console.error(error);
    }
  }

  public getBookingTrackCode(): string {
    try {
      return this.purchaseRequestService.getBookingTrackCode();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public isIncludesFreeTransfers(): boolean {
    try {
      return this.purchaseRequestService.isIncludesFreeTransfers();
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public isIncludeAirFare(): boolean {
    try {
      return this.purchaseRequestService.isIncludeAirFare();
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public getCruiseItineraryDuration(): string {
    try {
      return this.purchaseRequestService.getCruiseWrapper().getCruiseItineraryDuration();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getPurchaseCabinPassengers(): PurchaseCabinPassenger[] {
    try {

      return this.purchaseRequestService.getPurchaseCabinPassengers();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getPurchaseCabinObjectSummary(): { cabinaItinerarioNombre: string, camaCabinaNombre: string }[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinObjectSummary();
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  public hasTextFree(service: string) {
    const textArray = service.split(' ');
    let auxString = '<P>';
    textArray.forEach(text => {
      if (text === 'FREE') {
        auxString = auxString + ' ' + `<strong>${text}</strong>`
      } else {
        auxString = auxString + ' ' + text;
      }
    })
    auxString = auxString + '</p>'
    return auxString;
  }

  getTextToCharterOrGroupsOnly(): string{
    if (this.cruiseInput.availability[0].charterOnly) {
      return "CHARTER ONLY";
    }else{
      if (this.cruiseInput.availability[0].groupsOnly) {
        return "GROUPS ONLY";
      }
    }
  }
  ischarterOrGroupsOnly(){
    try {
      
      return this.cruiseInput.availability[0].charterOnly || this.cruiseInput.availability[0].groupsOnly;
    } catch (error) {
      return false;
    }
  }

  getThanYouText() {
    let textValue = this.purchaseRequestService.getPaymentMethod().transfer
      ? TextInfoTransferEnum.TRANSFER_TEXT
      : TextInfoTransferEnum.WITHOUT_TRANSFER_TEXT;

    return textValue;
  }
  getInvoiceText() {
    let textValue = this.purchaseRequestService.getPaymentMethod().transfer
      ? TextInfoTransferEnum.TOTAL_INVOICED
      : TextInfoTransferEnum.TOTAL_BILLED;

    return textValue;
  }
  
  public getTextTotalPaid(): string {
    try {
      let textValue = "";
      if (this.purchaseRequestService.getPaymentMethod().transfer) {
        if (this.purchaseRequestService.getIsSelectedThirtyPerent()) {
          textValue = `Initial deposit (${this.companyParamsService.getPercentPartialPayment()}%) to be paid*`;
        } else {
          textValue = TextInfoTransferEnum.TOTAL_TO_BE_PAID;
        }
      } else {
        if (this.purchaseRequestService.getIsSelectedThirtyPerent()) {
          textValue = `Initial deposit (${this.companyParamsService.getPercentPartialPayment()}%) paid*`;
        } else {
          textValue = TextInfoTransferEnum.TOTAL_PAID;
        }
      }
      return textValue;
    } catch (error) {
      console.log("TourSummaryComponent.isPurchaseIncludeAirTickets()");
      console.error(error);
    }
  }
}
