import { PayflowEnvironmentParameterModel } from '../models/payflow-environment-parameter.model';
import { CreditCardPayflowTransactionRequestClass } from './credit-card-payflow-transaction-request.class';
import { PayflowPaymentMethodEnum } from '../enums/payflow-payment-method.enum';
import { TransactionRequestInterface } from './transaction-request.interface';
import { CreditCardClass } from './credit-card.class';
import { PayFlowTransactionTypeEnum } from '../enums/payflow-transaction-type.enum';
import { TransactionResponseInterface } from './transaction-response.interface';

export class TransactionRequestFactoryClass {

  // tslint:disable-next-line: new-parens
  private static readonly transactionRequestFactory: TransactionRequestFactoryClass = new TransactionRequestFactoryClass;

  public static getInstance(): TransactionRequestFactoryClass {
    return TransactionRequestFactoryClass.transactionRequestFactory;
  }

  private constructor() { }

  public createPayflowSaleTransaction(payflowEnvironmentParameter: PayflowEnvironmentParameterModel,
    // tslint:disable-next-line:align
    creditCardObject: CreditCardClass,
                                      amount: number, comment1: string, comment2: string): TransactionRequestInterface {

    const creditCardPaymentRequest: CreditCardPayflowTransactionRequestClass
      = new CreditCardPayflowTransactionRequestClass();

    creditCardPaymentRequest.setVerbosity(payflowEnvironmentParameter.defaultVerbosity);
    creditCardPaymentRequest.setTransactionType(payflowEnvironmentParameter.defaultTransactionType);
    creditCardPaymentRequest.setPaymentMethod(PayflowPaymentMethodEnum.creditCard);
    creditCardPaymentRequest.setCreditCard(creditCardObject);
    creditCardPaymentRequest.setAmount(amount);
    creditCardPaymentRequest.setComment1(comment1);
    creditCardPaymentRequest.setComment2(comment2);
    return creditCardPaymentRequest;
  }

  public createPayflowVerificationTransaction(payflowEnvironmentParameter: PayflowEnvironmentParameterModel,
                                              creditCardObject: CreditCardClass): TransactionRequestInterface {
    const creditCardPaymentRequest: CreditCardPayflowTransactionRequestClass
      = new CreditCardPayflowTransactionRequestClass();

    creditCardPaymentRequest.setVerbosity(payflowEnvironmentParameter.defaultVerbosity);
    creditCardPaymentRequest.setTransactionType(PayFlowTransactionTypeEnum.authorization);
    creditCardPaymentRequest.setPaymentMethod(PayflowPaymentMethodEnum.creditCard);
    creditCardPaymentRequest.setCreditCard(creditCardObject);
    creditCardPaymentRequest.setComment1('Verification transaction');
    creditCardPaymentRequest.setAmount(0);
    return creditCardPaymentRequest;
  }

  public createPayflowAutorizationTransaction(
    payflowEnvironmentParameter: PayflowEnvironmentParameterModel,
    creditCardObject: CreditCardClass,
    amount: number, comment1: string, comment2: string): TransactionRequestInterface {
    const creditCardPaymentRequest: CreditCardPayflowTransactionRequestClass
      = new CreditCardPayflowTransactionRequestClass();

    creditCardPaymentRequest.setVerbosity(payflowEnvironmentParameter.defaultVerbosity);
    creditCardPaymentRequest.setTransactionType(PayFlowTransactionTypeEnum.authorization);
    creditCardPaymentRequest.setPaymentMethod(PayflowPaymentMethodEnum.creditCard);
    creditCardPaymentRequest.setCreditCard(creditCardObject);
    creditCardPaymentRequest.setAmount(amount);
    creditCardPaymentRequest.setComment1(comment1);
    creditCardPaymentRequest.setComment2(comment2);
    return creditCardPaymentRequest;
  }

  public createPayflowDelayedTransaction(payflowEnvironmentParameter: PayflowEnvironmentParameterModel,
                                         creditCardObject: CreditCardClass,
                                         authorizationTransactionResponse: TransactionResponseInterface,
                                         amount: number, comment1: string, comment2: string): TransactionRequestInterface {

    const authorizationTransactionId: string = authorizationTransactionResponse.getPayflowTransactionId();

    if (authorizationTransactionResponse.isError()
      || authorizationTransactionResponse.getTransactionRequest().getAmount() < amount
      || this.isEmptyNullOrUndefined(authorizationTransactionId)
    ) {
      throw new Error('Amount cannot be greater than authorized amount.');
    }

    const creditCardPaymentRequest: CreditCardPayflowTransactionRequestClass
      = new CreditCardPayflowTransactionRequestClass();

    creditCardPaymentRequest.setVerbosity(payflowEnvironmentParameter.defaultVerbosity);
    creditCardPaymentRequest.setTransactionType(PayFlowTransactionTypeEnum.delayedCapture);
    creditCardPaymentRequest.setPaymentMethod(PayflowPaymentMethodEnum.creditCard);
    creditCardPaymentRequest.setCreditCard(creditCardObject);
    creditCardPaymentRequest.setOriginalTransactionId(authorizationTransactionId);
    creditCardPaymentRequest.setAmount(amount);
    creditCardPaymentRequest.setComment1(comment1);
    creditCardPaymentRequest.setComment2(comment2);
    return creditCardPaymentRequest;
  }

  private isEmptyNullOrUndefined(elementToValidate: string): boolean {
    return elementToValidate === '' || elementToValidate === null || elementToValidate === undefined;
  }

}
