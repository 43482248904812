import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { Component, OnInit } from '@angular/core';
export interface MenuHeader {
  name: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  styleheader;
  menus: MenuHeader[];
  constructor(public companyParamsService: CompanyParamsService,
    ) { }

  ngOnInit() {
    this.menus= this.companyParamsService.getListMenu();

  }
  redirectToCompany(){
    window.location.href = 'https://' + this.companyParamsService.getWebSite();
  }

}
