import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { NgWebflowService } from 'ngweflow';
import { PurchaseRequestStatus } from './../../../enums/purchase-request-status.enum';
import { CountryService } from 'src/app/core/services/country/country.service';
import { MonthDay } from 'src/app/models/month-day';
import { Gender } from 'src/app/enums/gender.enums';
import { Month } from 'src/app/models/month';
import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { Country } from 'src/app/models/country';
import { PurchaseRequestService } from '../../services/purchase-request/purchase-request.service';
import { MonthService } from 'src/app/core/services/month/month.service';
import { PurchaseCabin } from 'src/app/models/purchase-cabin';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { isNullOrUndefined } from 'util';
import { PassengerType } from 'src/app/enums/passenger-type.enum';
import { PurchaseCabinPassenger } from 'src/app/models/purchase-cabin-passenger';
import { PurchaseContactPerson } from 'src/app/ngGtcPayflow/models/purchase-contact-person';
import { PurchasePassengerInformation } from 'src/app/ngGtcPayflow/models/purchase-passenger-information';
import { CruiseService } from '../../services/cruise/cruise.service';
import * as $ from 'jquery';
import { UrlParamsService } from 'src/app/services/url-params.service';
import { environment } from 'src/environments/environment';
import { BookingConfirmationResponse } from 'src/app/models/purchase/booking-confirmation-response';
import { RoyalRequestService } from 'src/app/services/royal-request/royal-request.service';
import { PromoService } from '../../services/promo/promo.service';
import { Availability } from 'src/app/models/availability';
import { CodeProcesscrmEnum } from '../../../enums/code-process-crm.enum';

interface PurchaseContactPassengerListElement { cabinIndex: number; passengerIndex: number; }

@Component({
  selector: 'app-checkout-second',
  templateUrl: './checkout-second.component.html',
  styleUrls: ['./checkout-second.component.css']
})

export class CheckoutSecondComponent implements OnInit {
  public activeEndSlide: string;
  public isOtherContactPerson = false;
  public isOtherContactPersonValue = -1;
  public namesMinLength = 2;
  public months: Month[] = [];
  public genders: typeof Gender = Gender;
  public countries: Country[] = [];
  public years: number[] = [];
  public days: MonthDay[] = [];
  private numberOfPassengers: number;
  public purchasePassengerForm: FormGroup;
  public purchaseCabinInformation: string[];
  public purchaseContactPassengersListElements: PurchaseContactPassengerListElement[];
  public minAge: number;
  public maxChildAge: number;
  public isShowErrorsSummary = false;
  public isAutoPaymentAccepted: boolean;
  public showPassportNumberRequiredModal: boolean;
  // private static GENDER_DEFAULT_VALUE: string = 'undefined';
  testFormArrays: FormArray[] = [];
  cabinsNumber: PurchaseCabin[];
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  public cruise: any;
  public startEndDates: string;
  public startEndDatesType: string;
  public promotion: string;
  public priceWithAirFare: number;
  public itineraryIndex: number;
  public cruiseStartDate: string;
  public boatImagesResource: string;
  public position: boolean;
  public cruiseItineraryDuration: string;
  public availavilitySelected: Availability;
  geoIp = "";
  urlElementsArray: string[] = [];
  
  constructor(
    private fb: FormBuilder,
    private monthService: MonthService,
    private countryService: CountryService,
    private router: Router,
    public purchaseRequestService: PurchaseRequestService,
    private cruiseService: CruiseService,
    private ngWebflowService: NgWebflowService,
    public companyParamsService: CompanyParamsService,
    private urlParamsService: UrlParamsService,
    private royalRequestService: RoyalRequestService,
    private promoService: PromoService

  ) {
    $(document).ready(function () {
      $('html, body').animate({
        scrollTop: $('#startCheckoutSecond').offset().top
      }, 1000);
    });
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.purchaseRequestService.purchase.cruise)) {
      this.activeEndSlide = 'active';
      this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
        this.geoIp = ip;
      })
      this.numberOfPassengers = this.purchaseRequestService.getNumberOfPassengers();
      this.months = this.monthService.getMonths();
      this.years = this.generateYears();
      this.countries = this.countryService.getCountries();
      this.purchasePassengerForm = this.createPassengerForms();
      this.purchaseCabinInformation = this.getPurchaseCabinInformation();
      this.purchaseContactPassengersListElements = this.getPurchaseContactPassengersListElements();
      this.minAge = this.purchaseRequestService.getCruiseWrapper().getEdadMinima();
      this.maxChildAge = this.purchaseRequestService.getCruiseWrapper().getEdadNino();
      this.isAutoPaymentAccepted = this.purchaseRequestService.isAutoPaymentAccepted();
      this.showPassportNumberRequiredModal = false;

      const selecteIndex = 0;
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.cruiseStartDate = this.cruise.availability[this.cruise.iterIndex].date;
      this.availavilitySelected = this.cruise.availability[this.cruise.iterIndex];
      this.startEndDates = this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType = this.cruise.availability[this.cruise.iterIndex].name;
      this.promotion = this.cruise.availability[this.cruise.iterIndex].promotion;

      this.itineraryIndex = selecteIndex;
      this.priceWithAirFare = this.cruiseService.getCruisePriceinNewFormat(this.cruise, this.itineraryIndex, true);
      this.cabinsNumber = this.purchaseRequestService.purchase.cabins;
      this.initializeBoatImageResource();
      this.position = true;
      this.cruiseItineraryDuration = this.purchaseRequestService.getCruiseWrapper().getCruiseItineraryDuration();


    } else {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }

  }


  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo
  }
  public getMonthDays(cabinIndex: number, passengerIndex: number): MonthDay[] {
    try {
      const birthMonth: number = this.purchasePassengerForm.get('purchasePassengers')
        .get(cabinIndex.toString()).get(passengerIndex.toString()).get('birthMonth').value as number;
      const birthYear: number = this.purchasePassengerForm.get('purchasePassengers')
        .get(cabinIndex.toString()).get(passengerIndex.toString()).get('birthYear').value as number;
      if (isNullOrUndefined(birthMonth) || isNullOrUndefined(birthYear)) { return []; }
      return UtilsService.generateMonthDays(birthMonth, birthYear);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private getPurchaseContactPassengersListElements(): PurchaseContactPassengerListElement[] {
    try {
      const purchaseContactPassengerListElements: PurchaseContactPassengerListElement[] = [];
      this.purchaseRequestService.purchase.cabins.forEach((purchaseCabin: PurchaseCabin, cabinIndex: number) => {

        purchaseCabin.purchaseCabinPasssengers.forEach((purchaseCabinPassenger: PurchaseCabinPassenger, passengerIndex: number) => {
          if (purchaseCabinPassenger.type === PassengerType.ADULT) {
            purchaseContactPassengerListElements.push({ cabinIndex: Number(cabinIndex), passengerIndex: Number(passengerIndex) });
          }
        });

      });
      return purchaseContactPassengerListElements;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private getPurchaseCabinInformation(): string[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinObjectSummary().map(
        (purchaseCabinObjectSummary: { cabinaItinerarioNombre: string, camaCabinaNombre: string }) => {
          return purchaseCabinObjectSummary.cabinaItinerarioNombre + ' / ' + purchaseCabinObjectSummary.camaCabinaNombre;
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private createPassengerForms(): FormGroup {
    try {
      const formArrays: FormArray[] = [];
      this.purchaseRequestService.purchase.cabins.forEach((purchaseCabin: PurchaseCabin) => {
        const formArrayForCabin: FormArray = this.createFormArrayForCabin(purchaseCabin);
        formArrays.push(formArrayForCabin);
      });
      this.purchasePassengerForm = this.fb.group({
        purchasePassengers: new FormArray(formArrays),
        purchaseContactPersonForm: this.createContactForm()
      });
      return this.purchasePassengerForm;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  get agencyValue() {
    return this.purchasePassengerForm.get('purchaseContactPersonForm').get("agency").value;
  }

  get commentsValue() {
    return this.purchasePassengerForm.get('purchaseContactPersonForm').get("comments").value;
  }
  get emailValue() {
    return this.purchasePassengerForm.get('purchaseContactPersonForm').get("email").value;
  }

  get phoneValue() {
    return this.purchasePassengerForm.get('purchaseContactPersonForm').get("phone").value;
  }
  get firstNameValue() {
    return this.purchasePassengerForm.get('purchaseContactPersonForm').get("purchasePassenger").value;
  }



  private createFormArrayForCabin(purchaseCabin: PurchaseCabin): FormArray {
    try {
      const cabinFormGroup: FormGroup[] = [];
      const numberOfAdultsArray: number[] = UtilsService.getArrayOfNumbersByRange(0, purchaseCabin.adults - 1);
      const numberOfChildrenArray: number[] = UtilsService.getArrayOfNumbersByRange(0, purchaseCabin.children - 1);

      numberOfAdultsArray.forEach(() => {
        cabinFormGroup.push(this.createPassagerForm(PassengerType.ADULT));
      });

      numberOfChildrenArray.forEach(() => {
        cabinFormGroup.push(this.createPassagerForm(PassengerType.CHILD));
      });
      return new FormArray(cabinFormGroup);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private createPassagerForm(passengerType: PassengerType): FormGroup {
    try {
      return this.fb.group({
        firstName: [null, [Validators.required, Validators.minLength(this.namesMinLength)]],
        lastName: [null, [Validators.required, Validators.minLength(this.namesMinLength)]],
        gender: [null, Validators.required],
        country: [null, Validators.required],
        passport: [null, Validators.required],
        birthday: [null, Validators.required],
        birthMonth: [null, Validators.required],
        birthYear: [null, Validators.required],
        passengerType: [passengerType, Validators.required]
      }, { validator: this.birthDateValidator.bind(this) });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private createContactForm(): FormGroup {
    try {
      let contactFormGroup: FormGroup;
      const purchaseContactPerson = {
        firstName: null as string,
        lastName: null as string,
        email: [null as string, [Validators.required, Validators.email]],
        phone: [null as string, Validators.required],
        agency: '',
        comments: '',
        purchasePassenger: [0 as number, Validators.required]
      };
      contactFormGroup = this.fb.group(purchaseContactPerson);
      return contactFormGroup;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private birthDateValidator(passengerForm: FormGroup): { notAllowedBirtdate?: boolean, notChild?: boolean, birthdateError?: boolean } {
    try {
      if (isNullOrUndefined(passengerForm.get('birthYear').value)
        || isNullOrUndefined(passengerForm.get('birthMonth').value)
        || isNullOrUndefined(passengerForm.get('birthday').value)) { return { birthdateError: true }; }

      const selectedBirthDate: Date = UtilsService.getDate(
        passengerForm.get('birthYear').value,
        passengerForm.get('birthMonth').value,
        passengerForm.get('birthday').value);
      const childMinDate = this.purchaseRequestService.getCruiseWrapper().getMinBirthDateForChild();
      const childMaxDate = this.purchaseRequestService.getCruiseWrapper().getMaxBirthDateForChild();

      if (isNullOrUndefined(selectedBirthDate)
        || isNullOrUndefined(childMinDate)
        || isNullOrUndefined(childMaxDate)) { return { birthdateError: true }; }
      if (selectedBirthDate > childMinDate) {
        return { notAllowedBirtdate: true };
      } else if (passengerForm.get('passengerType').value === PassengerType.CHILD
        && (selectedBirthDate > childMinDate || selectedBirthDate < childMaxDate)) {
        return { notChild: true };
      }
      return null;
    } catch (error) {
      console.error(error);
      return { birthdateError: true };
    }
  }

  public goBack(): void {
    try {
      this.purchaseRequestService.purchaseRequestStatus = PurchaseRequestStatus.INITIAL;
    } catch (error) {
      console.error(error);
    }

  }

  public confirm(
    purchasePassengersInformation,
    purchaseContactPerson:
      { firstName: string, lastName: string, email: string, phone: string, agency: string, comments: string, purchasePassenger: number }
  ): void {
    try {


      if (this.purchasePassengerForm.invalid === true) {

        this.validateAllFormFields(this.purchasePassengerForm);
        this.testFormArrays = this.getPurchasePassengerFormControls();
        /**
         * implementacion de validacion de forms para los pasajeros
         */
        this.testFormArrays.forEach(element => {
          const elementFormArray = element as FormArray;
          elementFormArray.controls.forEach(formgroupPassengers => {
            const formgroupPasen: FormGroup = formgroupPassengers as FormGroup;
            this.validateAllFormFields(formgroupPasen);
          });
        });

        this.isShowErrorsSummary = true;
        return;
      }
      if (isNullOrUndefined(purchaseContactPerson.firstName)) {
        const row = this.purchaseContactPassengersListElements[purchaseContactPerson.purchasePassenger].cabinIndex;
        const column = this.purchaseContactPassengersListElements[purchaseContactPerson.purchasePassenger].passengerIndex;
        purchaseContactPerson.firstName = purchasePassengersInformation[Number(row)][Number(column)].firstName
        purchaseContactPerson.lastName = purchasePassengersInformation[Number(row)][Number(column)].lastName
      }
      this.purchaseRequestService.addPurchasePassengersInformation(purchasePassengersInformation);
      this.purchaseRequestService.addPurchaseContectPerson(
        this.validateContactPerson(purchaseContactPerson, purchasePassengersInformation)
      );
      this.purchaseRequestService.purchaseRequestStatus = PurchaseRequestStatus.PASSENGER_COMPLETED;
      const dataPassenger = {
        passengers: purchasePassengersInformation,
        contactPerson: purchaseContactPerson
      }
      this.ngWebflowService.addWebFlowAction('FILL_PASSENGER_FORM', dataPassenger);
      if (this.companyParamsService.getVisiblepago()) {

        this.router.navigate([this.urlParamsService.createurlForNextPage('3')]);
      } else {
        this.requestBookingConfirmation();
      }

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private requestBookingConfirmation(): void {
    try {
      this.purchaseRequestService.requestInquireConfirm().subscribe(
        (bookingConfirmationResponse: BookingConfirmationResponse) => {
          if (this.purchaseRequestService.isRequestBookingConfirmationSuccess(bookingConfirmationResponse)) {
            this.purchaseRequestService.purchaseRequestStatus = PurchaseRequestStatus.BOOCKING_CONFIRMATION_SENT;
            this.router.navigate([this.urlParamsService.createurlForNextPage('4')]);
          }
        },
        (error: any) => {

          this.ngWebflowService.addWebFlowAction('SEND_INQUIRE', { result: "erorr", response: error, body: { ...this.purchaseRequestService.preparePurchaseObjectToRequestBookingConfirmation() } });
          console.error(error);

        },
        () => {
          this.requestRoyalForm();
          this.ngWebflowService.addWebFlowAction('SEND_INQUIRE', { result: "ok" });
        }
      );
    } catch (error) {
      console.log('BookTourComponent.requestbookingConfirm()');
      console.error(error);
    }
  }

  async requestRoyalForm() {
    if (this.companyParamsService.getName() === "Royal Galapagos" ) {

      let url = this.getUrlRequest();
      this.royalRequestService.requestGetForRoyal(url).subscribe(
        err => {
          this.ngWebflowService.addWebFlowAction('SEND_CRM_REQUEST', {
            result: err,
            url });
          this.router.navigate([this.urlParamsService.createurlForNextPage('4')]);
        }
        ,
        () => {
          this.ngWebflowService.addWebFlowAction('Ok_SEND_CRM_REQUEST', {
            result: "OK-fromPassenger",
            url });
          this.router.navigate([this.urlParamsService.createurlForNextPage('4')]);
        }
      )
    }else{
      this.router.navigate([this.urlParamsService.createurlForNextPage('4')]);
    }
  }

  getUrlRequest(): string {

    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText = this.getPromoName(cruiseIndex) === '0' ? 'Regular Rate' : this.getPromoName(cruiseIndex);
    const newLetter = "0";

    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;
    
    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate);
    this.getInterestDataRoyal(); 
    this.getContactInfoRoyal(); 
    this.getStringUtm() ;
    this.urlElementsArray.push(newLetter);
    this.urlElementsArray.push(this.geoIp);
    this.setLastFourItmes();
    const urlEscapedElementsArray = this.urlElementsArray.map((urlElement: string) => {
      return encodeURIComponent(urlElement);
    });

    urlFinal += urlEscapedElementsArray.join('/');
    return urlFinal;
  }
  setLastFourItmes() {
    this.urlElementsArray.push(this.isMobile());
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push(CodeProcesscrmEnum.RG_ECOM_BOOK);
  }

  isMobile () {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  getStringUtm(): string {
    const utms = this.royalRequestService.getUtm();
    this.urlElementsArray.push(utms.utm_source);
    this.urlElementsArray.push(utms.utm_medium);
    this.urlElementsArray.push(utms.utm_campaign);
    this.urlElementsArray.push(utms.utm_term);

    return `${utms.utm_source}/${utms.utm_medium}/${utms.utm_campaign}/${utms.utm_term}/`;
  }
  getInterestDataRoyal(): string {
    let interestString = "";
    interestString = "0/0/0/0/"
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    return interestString;
  }
  getContactInfoRoyal(): string {
    let fName = this.purchasePassengerForm.value.purchaseContactPersonForm.firstName;
    let lName = this.purchasePassengerForm.value.purchaseContactPersonForm.lastName;
    let email = this.emailValue;
    let phone = isNullOrUndefined(this.phoneValue) || this.phoneValue === '' ? 'Not specific' : this.phoneValue;
    let country = "none";
    let agency = this.agencyValue === '' ? 'Not specific' : this.agencyValue;
    let reference = 'Not specific';
    this.urlElementsArray.push(fName);
    this.urlElementsArray.push(lName);
    this.urlElementsArray.push(email);
    this.urlElementsArray.push(phone);
    this.urlElementsArray.push(country);
    this.urlElementsArray.push(agency);
    this.urlElementsArray.push(reference);
    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }
  getStringTravelDataRoyal(startDate: Date, endDate: Date): string {
    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.availavilitySelected.length.substring(0, 1);
    let optionCruise = this.availavilitySelected.name.substring(this.availavilitySelected.name.length, 1);
    let numberAdults = this.purchaseRequestService.getNumberOfAdults();
    let numberChild = this.purchaseRequestService.getNumberOfChildren();
    let comments = this.commentsValue === "" ? "None specific" : this.commentsValue;
    const dayString = startDate.getDate() < 10 ? '0' +
      String(startDate.getDate()) : String(startDate.getDate());
    const endDayString = endDate.getDate() < 10 ? '0' +
      String(endDate.getDate()) : String(endDate.getDate());

    arrivalDate = `${this.monthNames[startDate.getMonth()]} ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${this.monthNames[endDate.getMonth()]} ${endDayString}, ${endDate.getFullYear()}`;
    this.urlElementsArray.push(arrivalDate);
    this.urlElementsArray.push(departureDate);
    this.urlElementsArray.push(daysCruise);
    this.urlElementsArray.push(optionCruise);
    this.urlElementsArray.push(String(numberAdults));
    this.urlElementsArray.push(String(numberChild));
    this.urlElementsArray.push(`${comments} - AUTOMATIC SENDING FROM ECOMMERCE THE AVAILABILITY DEPARTURE IS LESS OF 72H`);
    this.urlElementsArray.push("en");
    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return this.promoService.getPromoText(this.cruise.availability[itineraryIndex].promotionType,
        this.cruise.availability[itineraryIndex].promotionValue) || '';
    } catch (error) {
      console.log('ResultsComponent.getPromoName()');
      console.error(error);
      return null;
    }
  }

  getStringFormDatailRoyal(promoText: string): string {

    let product = this.royalRequestService.getNameBoatToRoyal(this.cruise.name);
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.urlElementsArray.push(product);
    this.urlElementsArray.push(promoText);
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }




  // controla si el control es valido caso contrario lo pinta este metodo sirve para cualquier form

  public validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public getCountries(): Country[] {
    try {
      return this.countryService.getCountries();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  nextThirdPart() {
    this.router.navigate([this.urlParamsService.createurlForNextPage('3')]);
  }

  public isFormValid(): boolean {
    try {
      this.purchasePassengerForm.get('purchaseContactPersonForm').get('purchasePassenger').updateValueAndValidity();
      return this.purchasePassengerForm.valid;
    } catch (error) {
      console.error(error);
      return false;
    }
  }


  private validateContactPerson(purchaseContactPersonForm:
    { firstName: string, lastName: string, email: string, phone: string, agency: string, comments: string, purchasePassenger: number },
    purchasePassengersInformation: PurchasePassengerInformation[][]): PurchaseContactPerson {
    try {


      const purchaseContactPerson: PurchaseContactPerson = this.createPurchaseContactPerson(purchaseContactPersonForm);
      if (purchaseContactPersonForm.purchasePassenger > -1) {
        const purchaseContactPassengerListElement: PurchaseContactPassengerListElement =
          this.purchaseContactPassengersListElements[purchaseContactPersonForm.purchasePassenger];
        purchaseContactPerson.purchasePassengerInformation =
          purchasePassengersInformation[purchaseContactPassengerListElement.cabinIndex]
          [purchaseContactPassengerListElement.passengerIndex];
        purchaseContactPerson.firstName = purchaseContactPerson.purchasePassengerInformation.firstName;
        purchaseContactPerson.lastName = purchaseContactPerson.purchasePassengerInformation.lastName;
      }
      return purchaseContactPerson;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  createPurchaseContactPerson(purchaseContactPersonForm:
    {
      firstName: string, lastName: string, email: string, phone: string, agency: string, comments: string, purchasePassenger: number
    }): PurchaseContactPerson {
    let purchaseContactPerson: PurchaseContactPerson;
    if (purchaseContactPersonForm.agency !== ' ') {
      if (purchaseContactPersonForm.comments !== ' ') {
        purchaseContactPerson = {
          firstName: purchaseContactPersonForm.firstName,
          lastName: purchaseContactPersonForm.lastName,
          phone: purchaseContactPersonForm.phone,
          agency: purchaseContactPersonForm.agency.trim(),
          remarks: purchaseContactPersonForm.comments.trim(),
          email: purchaseContactPersonForm.email,
          purchasePassengerInformation: null
        };
      } else {
        purchaseContactPerson = {
          firstName: purchaseContactPersonForm.firstName,
          lastName: purchaseContactPersonForm.lastName,
          phone: purchaseContactPersonForm.phone,
          agency: purchaseContactPersonForm.agency.trim(),
          email: purchaseContactPersonForm.email,
          purchasePassengerInformation: null
        };
      }
    } else {
      if (purchaseContactPersonForm.comments === ' ') {
        purchaseContactPerson = {
          firstName: purchaseContactPersonForm.firstName,
          lastName: purchaseContactPersonForm.lastName,
          phone: purchaseContactPersonForm.phone,
          email: purchaseContactPersonForm.email,
          purchasePassengerInformation: null
        };
      } else {
        purchaseContactPerson = {
          firstName: purchaseContactPersonForm.firstName,
          lastName: purchaseContactPersonForm.lastName,
          phone: purchaseContactPersonForm.phone,
          remarks: purchaseContactPersonForm.comments.trim(),
          email: purchaseContactPersonForm.email,
          purchasePassengerInformation: null
        };
      }
    }
    return purchaseContactPerson;
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public setIsOtherContactPerson(selectedValue: any): void {
    try {
      this.isOtherContactPerson = (Number(selectedValue) === this.isOtherContactPersonValue);
      if (Number(selectedValue) === this.isOtherContactPersonValue) {
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('firstName').setValidators(
          [Validators.required, Validators.minLength(this.namesMinLength)]);
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('lastName').setValidators(
          [Validators.required, Validators.minLength(this.namesMinLength)]);

        this.purchasePassengerForm.get('purchaseContactPersonForm').get('firstName').updateValueAndValidity();
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('lastName').updateValueAndValidity();

      } else {
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('firstName').setValidators(null);
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('lastName').setValidators(null);

        this.purchasePassengerForm.get('purchaseContactPersonForm').get('firstName').updateValueAndValidity();
        this.purchasePassengerForm.get('purchaseContactPersonForm').get('lastName').updateValueAndValidity();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public generateYears(): number[] {
    try {
      const years: number[] = [];
      const date = new Date();
      const startAt = date.getFullYear() - 100;

      const endAt = date.getFullYear() - this.purchaseRequestService.purchase.cruise.minimumAgeChild;
      // const endAt = date.getFullYear() - 10;

      for (let i = startAt; i <= endAt; i++) {
        years.push(i);
      }
      return years.sort((a, b) => b - a);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getPurchasePassengerFormControls(): FormArray[] {
    try {
      return (this.purchasePassengerForm.controls.purchasePassengers as FormArray).controls as FormArray[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public toggleShowPassportNumberRequiredModal(): void {
    try {
      this.showPassportNumberRequiredModal = !this.showPassportNumberRequiredModal;
    } catch (error) {
      console.error(error);
    }
  }

  private initializeBoatImageResource(): void {
    this.boatImagesResource = environment.app_resources.logoCruise + this.cruise.image;
  }
  reset() {
    this.createPassengerForms()
    $(document).ready(function () {
      $('html, body').animate({
        scrollTop: $('#startCheckoutSecond').offset().top
      }, 1000);
    });
  }
}
