import { ErrorComponent } from './../core/components/error/error.component';
import { ContentComponent } from './components/content/content.component';
import { CheckoutFourthComponent } from './components/checkout-fourth/checkout-fourth.component';
import { CheckoutThirdComponent } from './components/checkout-third/checkout-third.component';
import { CheckoutSecondComponent } from './components/checkout-second/checkout-second.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { RouterModule, Routes } from '@angular/router';



const pagesRoutes: Routes = [
  {
    path: ':companyName/:codeAvailability/:pax/:key',
    component: ContentComponent,
    children: [
      { path: '', component: CheckoutComponent },
      { path: '2', component: CheckoutSecondComponent },
      { path: '3', component: CheckoutThirdComponent },
      { path: '4', component: CheckoutFourthComponent },
    ],
  },
  { path: '**', component: ErrorComponent },


];


export const RESERVE_ROUTES = RouterModule.forChild(pagesRoutes);
