import {PaymentProductInterface} from './payment-product.interface';
import {CreditCardModel} from '../models/credit-card.model';

export class CreditCardClass implements PaymentProductInterface {

  public static readonly creditCardRegex = '^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$';
  public static readonly securityNumberRegex = '^[0-9]{3,4}$';
  public static readonly expirationMonthRegex = '^(0?[1-9]|1[012])$';
  public static readonly expirationYearRegex = '^[0-9]{3,4}$';
  public static readonly maxYearsExpirationDate = 20;

  private creditCardModel: CreditCardModel;
  private creditCardNumber: any;
  private securityNumber: any;
  private expirationMonth: any;
  private expirationYear: any;
  private billToFirstName: string;
  private billToLastName: string;
  private billToStreet: string;
  private billToStreet2: string;
  private billToCity: string;
  private billToState: string;
  private billToCountry: any;
  private billToPhoneNumber: string;
  private billToZip: string;
  private billToEmail: string;

  public constructor(creditCardModel: CreditCardModel) {
    this.initializeInstanceAttributes(creditCardModel);

    this.validate();
  }

  private initializeInstanceAttributes(creditCardModel: CreditCardModel): void {
    this.setModel(creditCardModel);
    this.setProductNumber(creditCardModel.creditCardNumber);
    this.setSecurityNumber(creditCardModel.securityNumber);
    this.setExpirationMonth(creditCardModel.expirationDate.expirationMonth);
    this.setExpirationYear(creditCardModel.expirationDate.expirationYear);
    this.setBillToFirstName(creditCardModel.billToFirstName);
    this.setBillToLastName(creditCardModel.billToLastName);
    this.setBillToStreet(creditCardModel.billToStreet);
    if (creditCardModel.hasOwnProperty('billToStreet2')) {
      this.setBillToStreet2(creditCardModel.billToStreet2);
    }
    this.setBillToCity(creditCardModel.billToCity);
    this.setBillToState(creditCardModel.billToState);
    this.setBillToCountry(creditCardModel.billToCountry);
    this.setBillToPhoneNumber(creditCardModel.billToPhoneNumber);
    this.setBillToZip(creditCardModel.billToZip);
    this.setBillToEmail(creditCardModel.billToEmail);
  }

  public getProductNumber(): number {
    return this.creditCardNumber;
  }

  private setProductNumber(creditCardNumber: number): void {
   // this.creditCardNumber = btoa(creditCardNumber.toString());
   this.creditCardNumber = (creditCardNumber.toString());
  }

  public getSecurityNumber(): number {
    return this.securityNumber;
  }

  private setSecurityNumber(securityNumber: number): void {
    this.securityNumber =  (securityNumber.toString());
  }

  public getExpirationMonth(): number {
    return this.expirationMonth;
  }

  private setExpirationMonth(expirationMonth: number) {
    this.expirationMonth =  (expirationMonth.toString());
  }

  public getExpirationYear(): number {
    return this.expirationYear;
  }

  private setExpirationYear(expirationYear: number) {
    this.expirationYear =  (expirationYear.toString());
  }

  private setBillToFirstName(billToFirstName: string): void {
      this.billToFirstName =  (billToFirstName);
  }

  public getBillToFirstName(): string {
    return this.billToFirstName;
  }

  private setBillToLastName(billToLastName: string): void {
    this.billToLastName =  (billToLastName);
  }

  public getBillToLastName(): string {
    return this.billToLastName;
  }

  private setBillToStreet(billToStreet: string): void {
    this.billToStreet =  (billToStreet);
  }

  public getBillToStreet(): string {
    return this.billToStreet;
  }

  private setBillToStreet2(billToStreet: string): void {
    this.billToStreet2 =  (billToStreet);
  }

  public getBillToStreet2(): string {
    return this.billToStreet2;
  }

  private setBillToCity(billToCity: string): void {
    this.billToCity =  (billToCity);
  }

  public getBillToCity(): string {
    return this.billToCity;
  }

  private setBillToState(billToState: string): void {
    this.billToState =  (billToState);
  }

  public getBillToState(): string {
    return this.billToState;
  }

  public getBillToStateFirstTwoLetters(): string {
    return this.billToState.substring(0, 2);
  }

  private setBillToCountry(billToCountry: number): void {
    this.billToCountry =  (billToCountry.toString());
  }

  public getBillToCountry(): number {
    return this.billToCountry;
  }

  private setBillToPhoneNumber(billToPhoneNumber: string): void {
    this.billToPhoneNumber =  (billToPhoneNumber);
  }

  public getBillToPhoneNumber(): string {
    return this.billToPhoneNumber;
  }

  private setBillToZip(billToZip: string): void {
    this.billToZip =  (billToZip);
  }

  public getBillToZip(): string {
    return this.billToZip;
  }

  private setBillToEmail(billToEmail: string): void {
    this.billToEmail =  (billToEmail);
  }

  public getBillToEmail(): string {
    return this.billToEmail;
  }

  private setModel(creditCardModel: CreditCardModel): void {
    this.creditCardModel = creditCardModel;
  }

  public getExpirationDate(): string {
    try {
      const paddedMonth: string = this.padLeftExpirationMonth();
      const shortYear: string = this.getExpirationYearLastTwoDigits();
      return '' + paddedMonth + shortYear;
    } catch ( error ) {
      console.error(error);
    }
    return '0000';
  }

  private getExpirationYearLastTwoDigits(): string {
    try {
      const expirationYearAsAString: string = this.getExpirationYear() + '';
      return expirationYearAsAString.substr(2, 2);
    } catch (error) {
      return '00';
    }
  }

  private padLeftExpirationMonth(): string {
    const aNumber: number = this.getExpirationMonth();
    try {
      if (aNumber < 1) {
        return '01';
      } else if (aNumber < 10) {
        return '0' + aNumber;
      } else if (aNumber > 12) {
        return '12';
      }
      return aNumber + '';
    } catch (error) {
      console.error(error);
      return '01';
    }
  }

  public validate(): void {
    this.isCreditCardNumberValid();
    this.isSecurityNumberValid();
    this.areRequiredFieldsPresent();
  }

  private isCreditCardNumberValid(): void {
    const creditCardNumberPattern: RegExp = new RegExp(CreditCardClass.creditCardRegex);
    const creditCardNumberAsString: string = this.getProductNumber() + '';
    const result: boolean = creditCardNumberPattern.test(creditCardNumberAsString);
    if (!result) {
      throw new Error('Invalid credit card number');
    }
  }

  private isSecurityNumberValid(): void {
    const securityNumberPattern: RegExp = new RegExp(CreditCardClass.securityNumberRegex);
    const securityNumberAsString: string = this.getSecurityNumber() + '';
    const result: boolean = securityNumberPattern.test(securityNumberAsString);
    if (!result) {
      throw new Error('Invalid security number');
    }
  }

  private areRequiredFieldsPresent(): void {
    const result: boolean = !(
      this.isNullUndefinedOrEmpty(this.creditCardNumber + '') ||
      this.isNullUndefinedOrEmpty(this.securityNumber + '') ||
      this.isNullUndefinedOrEmpty(this.expirationMonth + '') ||
      this.isNullUndefinedOrEmpty(this.expirationYear + '') ||
      this.isNullUndefinedOrEmpty(this.billToFirstName) ||
      this.isNullUndefinedOrEmpty(this.billToLastName) ||
      this.isNullUndefinedOrEmpty(this.billToStreet) ||
      this.isNullUndefinedOrEmpty(this.billToCity) ||
      this.isNullUndefinedOrEmpty(this.billToState) ||
      this.isNullUndefinedOrEmpty(this.billToCountry + '') ||
      this.isNullUndefinedOrEmpty(this.billToPhoneNumber) ||
      this.isNullUndefinedOrEmpty(this.billToZip) ||
      this.isNullUndefinedOrEmpty(this.billToEmail)
    );
    if (!result) {
      throw new Error('Please check the required fields');
    }
  }

  private isNullUndefinedOrEmpty(value: string): boolean {
    return value === null || value === undefined || value.trim() === '';
  }

}
