import { Promo } from './../../../models/cabina/promo';
import { PromoCode } from './../../../enums/promo-code.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  private promos: Promo[] = [];

  constructor() {
    this.fillPromos();
  }

  private fillPromos(): void {

    this.addPromo({
      codigo: PromoCode.PROMO_OFF,
      valor: 'OFF',
      etiqueta: 'OFF',
      isPromo: true,
      imageName: 'amount-off-wt.png',
      prefix: '$',
      suffix: ' OFF'
    });

    this.addPromo({
      codigo: PromoCode.PROMO_PORCENT_OFF,
      valor: 'OFF',
      etiqueta: '% OFF',
      isPromo: true,
      imageName: 'percentage-off-wt.png',
      suffix: '% OFF'
    });
    this.addPromo({
      codigo: PromoCode.PROMO_2_X_1,
      valor: '2 X 1',
      etiqueta: '2 X 1',
      isPromo: true,
      imageName: 'two-by-one-wt.png'
    });

    this.addPromo({
      codigo: PromoCode.PROMO_RAC,
      valor: 'NO PROMO',
      etiqueta: 'Regular Rate',
      isPromo: false,
    });

    this.addPromo({
      codigo: PromoCode.PROMO_NET,
      valor: 'NETA',
      etiqueta: '1 Net',
      isPromo: true,
      alternateText: 'Super Promo',
      imageName: 'amount-off-wt.png'
    });
    this.addPromo({
      codigo: PromoCode.PROMO_SUPER_PROMO,
      valor: 'NETA',
      etiqueta: '1 Net',
      isPromo: true,
      alternateText: 'Super Promo',
      imageName: 'amount-off-wt.png'
    });
  }

  private addPromo(promo: Promo) {
    this.promos.push(promo);
  }

  public getPromos(): Promo[] {
    return this.promos;
  }

  public getPromoById(promoId: number): Promo {
    for (const promo of this.getPromos()) {
      if (promo.codigo === promoId) {
        return promo;
      }
    }
    console.error('promo.service.getPromoById() > The provided promo code was not found on the service');
    return null;
  }

  public isPromo(promo: Promo): boolean {
    const servicePromo = this.getPromoById(promo.codigo);
    if (servicePromo) {
      return servicePromo.isPromo;
    } else {
      console.error('promo.service.isPromo() > There was a problem with the provided Promo object');
      return false;
    }
  }

  public getPromoText(promoId: number, value?: any): string {
    let promoText = '';
    const promo = this.getPromoById(promoId);

    if (!promo) {
      console.error('PromoService.getPromoText() > The requested promo could not have been found ');
      return null;
    }

    promoText += promo.prefix || '';
    promoText += promo.codigo === PromoCode.PROMO_2_X_1 ? promo.alternateText || promo.valor : promo.alternateText ||
      (value < 1 ? value * 100 : value);
    promoText += promo.suffix || '';
    return promoText === '0' ? 'Regular Rate' : promoText;
  }

  public getPromoImg(promoId: number): string {
    const promo = this.getPromoById(promoId);

    if (!promo) {
      console.error('PromoService.getPromoImg() > Could not find the promo with the id ' + promoId);
      return '';
    }

    return promo.imageName || '';

  }

}
