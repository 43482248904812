import { ContentComponent } from './reserve/components/content/content.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './core/components/error/error.component';

const appRoutes: Routes = [
  { path: '404_NOT_FOUND/:key', component: ErrorComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { useHash: true }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
