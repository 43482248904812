import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'showCabinPassengerName'
})
export class ShowCabinPassengerNamePipe implements PipeTransform {

  transform(value: number, firstName: string, lastName: string, alwaysIncludePreffix = false ): string {
    try {
      let resultString = '';
      if ( ( isNullOrUndefined( firstName ) || firstName === '' ) && ( isNullOrUndefined( lastName )  || lastName === '' ) ) {
        resultString += 'Passenger ' + value;
      } else {
        if ( alwaysIncludePreffix === true ) {
          resultString += 'Passenger ' + value + ': ';
        }
        resultString += isNullOrUndefined( firstName ) ? '' : firstName;
        resultString += isNullOrUndefined( lastName ) ? '' : ' ' + lastName;
      }
      return resultString;
    } catch ( error ) {
      console.log( 'ShowCabinPassengerNamePipe.transform()' );
      console.error( error );
      return null;
    }
  }

}
