import { Injectable } from '@angular/core';
import {isNullOrUndefined} from 'util';
import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';

@Injectable()
export class FirebaseService {

  constructor(
    private angularFireDatabase: AngularFireDatabase,
  ) { }

  public list<T>( pathOrRef: string ): FirebaseListObservable<T[]> {
    try {
      return this.angularFireDatabase.list( pathOrRef ) as FirebaseListObservable<T[]>;
    } catch ( error ) {
      console.log( 'FirebaseService.list()' );
      console.error( error );
    }
  }

  public push<T>( pathOrRef: string,  object: T ): void {
    try {
      const list = this.angularFireDatabase.list( pathOrRef );
      if ( isNullOrUndefined( list ) ) { return; }
      list.push( object );
    } catch ( error ) {
      console.log( 'FirebaseService.push()' );
      console.error( error );
    }
  }

  public pushWithCustomKey<T>( pathOrRef: string,  object: T, objectId: string ): void {
    try {
      const newObjectRef: any =  this.angularFireDatabase.object( pathOrRef + '/' + objectId );
      if ( isNullOrUndefined( newObjectRef ) ) { return; }
      this.angularFireDatabase.object( pathOrRef + '/' + objectId ).set( object );
    } catch ( error ) {
      console.log( 'FirebaseService.pushWithCustomKey()' );
      console.error( error );
    }
  }
}
