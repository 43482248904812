import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { CruiseService } from '../../services/cruise/cruise.service';
import { Location } from '@angular/common';
import { CompanyParamsService } from 'src/app/services/companyParams/company-params.service';

@Component({
  selector: 'app-up-slide',
  templateUrl: './up-slide.component.html',
  styleUrls: ['./up-slide.component.css']
})
export class UpSlideComponent implements OnInit {

  @Input() cruiseStartDate: string;
  @Input() startEndDates: string;
  @Input() startEndDatesType: string;
  @Input() priceWithAirFare: number;
  @Input() wasPriceWithAirFare: number;
  @Input() hastPromo: boolean;
  @Input() cruise: any;
  @Input() promotion: string;
  @Input() position: boolean;
  @Input() cruiseItineraryDuration: string;
  @Input() numberPage: number;
  @Input() urlLogoCruise: string;
  @Input() isFirstPage: boolean;
  isCalipso: boolean;
  mainColor: string;
  constructor(
    private location: Location,
    private cruiseService: CruiseService,
    private router: Router,
    public companyParamsService: CompanyParamsService
    
    ) { }

  ngOnInit() {
    this.mainColor= this.companyParamsService.getMainColor();
    this.isCalipso = this.cruiseService.isCalipso(this.cruise);
  }

  goBack() {
    console.log('goBack')
    this.location.back();

    //this.router.navigate([this.urlParamsService.createurlForNextPage('1')]);
  }
  getNameOfBackLabel(numberPage) {
    switch (numberPage) {
      case 4:
        return 'Back to search results'
        break;
      case 3:
        return 'Back to Passengers Info'
        break;
      case 2:
        return 'Back to Tour Options'
        break;
      default:
        return '';
    }
  }
  getTextToCharterOrGroupsOnly(): string{
    if (this.cruise.availability[0].charterOnly) {
      return "CHARTER ONLY";
    }else{
      if (this.cruise.availability[0].groupsOnly) {
        return "GROUPS ONLY";
      }
    }
  }
  ischarterOrGroupsOnly(){
    return this.cruise.availability[0].charterOnly || this.cruise.availability[0].groupsOnly;
  }
}
