export enum PayflowCreditCardPaymentResponseEnum {
  result = 'RESULT',
  payflowTransactionId = 'PNREF',
  paypalProcessorTransactionId = 'PPREF',
  responseMessage = 'RESPMSG',
  authCode = 'AUTHCODE',
  addressVerificationServiceStreetAddress = 'AVSADDR',
  addressVerificationServiceZip = 'AVSZIP',
  cardSecurityCodeMatch = 'CVV2MATCH',
  correlationId = 'CORRELATIONID',
  rawAddressVerificationServiceResponse = 'PROCAVS',
  rawCVV2Response = 'PROCCVV2',
  internationalAddressVerificationService = 'IAVS',
  PREFPSMSG = 'PREFPSMSG',
  POSTFPSMSG = 'POSTFPSMSG',
  hostCode = 'HOSTCODE',
  hostCodeResponseText = 'RESPTEXT',
  rawBuyerAuthentication = 'PROCCARDSECURE',
  additionalErrorMessage = 'ADDLMSGS',
  paymentType = 'PAYMENTTYPE',
  amexId = 'AMEXID',
  amexPostData = 'AMEXPOSDATA',
  ccTransactionId = 'CCTRANSID',
  ccTransactionPostData = 'CCTRANS_POSDATA',
  amount = 'AMT',
  originalAmount = 'ORIGAMT',
  cardType = 'CARDTYPE',
  emailMatch = 'EMAILMATCH',
  phoneMatch = 'PHONEMATCH',
  extraProcessorMessage = 'EXTRSPMSG',
  transactionTime = 'TRANSTIME',
  duplicate = 'DUPLICATE',
  dateToSettle = 'DATE_TO_SETTLE',
  paymentAdviceCode = 'PAYMENTADVICECODE',
  transactionState = 'TRANSSTATE'
}
