import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[galPassengerFormTabIndex]'
})
export class PassengerFormTabIndexDirective implements OnInit {

  @Input() galFormNumber: number;
  @Input() galCabinIndex: number;
  @Input() galPassengerIndex: number;
  @Input() galTabIndex: number;

  constructor(private elementRef: ElementRef) { }

  public ngOnInit() {
    try {
      const tabIndexValue: number = ((this.galFormNumber) * 10000) +
        ((this.galCabinIndex + 1) * 1000) + ((this.galPassengerIndex + 1) * 100) + this.galTabIndex;
      this.elementRef.nativeElement.tabIndex = tabIndexValue;
    } catch (error) {
      console.error(error);
    }
  }

}
