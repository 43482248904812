import {Inject, Injectable, isDevMode} from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import { PromoService } from '../promo/promo.service';
import { Purchase } from 'src/app/models/purchase';
// import {Geoip2Service} from '../../shared/services/geoip2/geoip2.service';
import { Observable } from 'rxjs/Observable';
import { GiEcomContactFormResponse } from 'src/app/models/external-responses/gi-ecom-contact-form-response';
import { ContactFormMessage } from 'src/app/models/contact-form-message';
import { isNullOrUndefined } from 'util';
import { PurchaseRequestService } from '../purchase-request/purchase-request.service';
import { environment } from 'src/environments/environment';
import { Geoip2Service } from 'ngweflow';





@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  public readonly DEPARTURE_ALREADY_BLOCKED_WHILE_TRYING_TO_BLOCK =
  'An user were trying to block a cabin, but it was already blocked.';
private userIp: string;

constructor( @Inject( Http ) private http: Http,
             @Inject( SlimLoadingBarService ) private ngProgress: SlimLoadingBarService,
             @Inject( Geoip2Service ) private geoip2Service: Geoip2Service,
             @Inject( PromoService ) private promoService: PromoService  ) {
  this.userIp = '0.0.0.0';
  this.geoip2Service.getGeoIpDirection().subscribe((geoIp: string) => {
    this.userIp = geoIp;
  });
}

/***
 * This method is used to send a form (automatically) when the user tries
 * to block a departure but this has been already blocked
 * by another person
 * @param {Purchase} purchaseRequest
 * @returns {Observable<GiEcomContactFormResponse>}
 */
public sendFormWhenBLockDepartureFails( purchaseRequest: Purchase ): Observable<GiEcomContactFormResponse> {
  try {
    const contactFormMessage: ContactFormMessage = {
      name: purchaseRequest.purchaseContactPerson.firstName + ' ' + purchaseRequest.purchaseContactPerson.lastName,
      email: purchaseRequest.purchaseContactPerson.email,
      phone: purchaseRequest.purchaseContactPerson.phone,
      message: this.DEPARTURE_ALREADY_BLOCKED_WHILE_TRYING_TO_BLOCK,
      purchase: purchaseRequest
    };
    return this.sendForm( contactFormMessage );
  } catch ( error ) {
    console.log('ContactFormService.sendInquire()');
    console.error( error );
    return Observable.throw( error );
  }
}

public sendForm( contactFormMessage: ContactFormMessage ): Observable<GiEcomContactFormResponse> {
  let httpResponse: Observable<Response>;
  const headers: Headers = new Headers( { 'Content-Type': 'application/json' } );
  try {
    const url =  this.createFormUrl( contactFormMessage );
    if ( isNullOrUndefined( url ) ) {  return Observable.throw( new Error ); }
    this.ngProgress.start();
    httpResponse = this.http.get( url, { headers: headers } );
    return httpResponse.map( ( response: Response ) => {
      return response.json() as GiEcomContactFormResponse;
    }).do( () => {
      this.ngProgress.complete();
    }).catch(
      ( error: any ) => {
        this.ngProgress.stop();
        console.log( 'ContactFormService.handleError()' );
        console.error( error );
        return Observable.throw( error );
      }
    );
  } catch ( error ) {
    console.log('ContactFormService.sendForm()');
    console.error( error );
    this.ngProgress.complete();
    return Observable.throw( error );
  }
}

public isSendFormResponseSuccess( giEcomContactFormResponse): boolean {
  if ( isNullOrUndefined( giEcomContactFormResponse ) ) { return false; }
   if ( giEcomContactFormResponse.status === true ) {
    return true;
  } else {
    return false;
  }
}

private createFormUrl( contactFormMessage: ContactFormMessage ): string {
  const urlElementsArray: string[] = [];
  const emptyCharacter = '-';
  const cruiseItineraryIndex = contactFormMessage.purchase.cruise.iterIndex;
  let url =  environment.GI_ECOM_CONTACT_FORM;
  try {
    if ( isNullOrUndefined( contactFormMessage.purchase )
      || isNullOrUndefined( contactFormMessage.purchase.cruise ) ) {
      return null;
    }
    urlElementsArray.push( contactFormMessage.purchase.cruise.name || emptyCharacter );
    const days: string = contactFormMessage.purchase.cruise.availability[ cruiseItineraryIndex ].length
    urlElementsArray.push(days.substring(0, 1)
      || emptyCharacter );
    urlElementsArray.push( contactFormMessage.purchase.cruise.category.toString() || emptyCharacter );

    const promoText: string = this.promoService.getPromoText(
      contactFormMessage.purchase.cruise.availability[ cruiseItineraryIndex ].promotionType
    );
    urlElementsArray.push( promoText || '' );
    urlElementsArray.push( contactFormMessage.purchase.includeAirFare ? 'A' : 'B' );
    if ( isNullOrUndefined( contactFormMessage.purchase.cabins ) ) {
      urlElementsArray.push( emptyCharacter );
      urlElementsArray.push( emptyCharacter ); // TODO cama
    } else {
      urlElementsArray.push( PurchaseRequestService.getPurchaseCabinTypeSummary() );
      urlElementsArray.push( emptyCharacter ); // TODO cama
    }
    urlElementsArray.push( contactFormMessage.purchase.adults.toString() || emptyCharacter );
    urlElementsArray.push( contactFormMessage.purchase.children.toString() || emptyCharacter );
    urlElementsArray.push( contactFormMessage.purchase.totalPrice ?
      contactFormMessage.purchase.totalPrice.toString() : '0'  ); // TODO Precio de cabina seleccionada
    const departureDate: Date = new Date(contactFormMessage.purchase.cruise.availability[ cruiseItineraryIndex ].startDate);
    const arrivalDate: Date = new Date(contactFormMessage.purchase.cruise.availability[ cruiseItineraryIndex ].endDate);


    const  stringdepartureDate = departureDate.getFullYear() + '-' + (departureDate.getMonth() + 1) + '-' + departureDate.getDate()
    const  stringarrivalDate = arrivalDate.getFullYear() + '-' + (arrivalDate.getMonth() + 1) + '-' + arrivalDate.getDate()

    urlElementsArray.push( stringdepartureDate || emptyCharacter );
    urlElementsArray.push( stringarrivalDate || emptyCharacter );
    urlElementsArray.push( contactFormMessage.purchase.adults.toString() || emptyCharacter );
    urlElementsArray.push( contactFormMessage.purchase.children.toString() || emptyCharacter );
    urlElementsArray.push( emptyCharacter ); // TODO dirección
    urlElementsArray.push( contactFormMessage.name );
    urlElementsArray.push( contactFormMessage.email );
    urlElementsArray.push( contactFormMessage.phone );
    urlElementsArray.push( contactFormMessage.message );
    urlElementsArray.push( this.userIp );

    const urlEscapedElementsArray = urlElementsArray.map( ( urlElement: string ) => {
      return encodeURIComponent( urlElement );
    } );

    url += urlEscapedElementsArray.join( '/' );
    if ( isDevMode() ) { console.log( url ); }
    return url;
  } catch ( error ) {
    console.log('ContactFormService.createFormUrl()');
    console.error( error );
    return null;
  }
}
}
