import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {
  @Input() isOpenShareDialog;
  @Output() private eventChangeVisibility: EventEmitter<Boolean>;
  @Input() urlShare;
  constructor() {
    this.eventChangeVisibility = new EventEmitter<Boolean>();
  }

  ngOnInit() {
  }
  clipboardAction() {
    this.clipboardmyMessage((this.urlShare));
    alert('Copied to clipboard ')
  }
  clipboardmyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  changeVisible() {
    this.isOpenShareDialog = false;
    this.eventChangeVisibility.emit(this.isOpenShareDialog);
  }
  shareAction(sharein: string) {

  }

  getLink(sharein: string) {
    let response = '';
    switch (sharein) {
      case 'facebook': {
        response = 'https://www.facebook.com/sharer/sharer.php?u=' + this.urlShare;
        break;
      }
      case 'twitter': {
        response = ('https://twitter.com/intent/tweet/?text=' + this.urlShare);
        break;
      }
      case 'google': {
        response = ('https://plus.google.com/share?url=' + this.urlShare);
        break;
      }
      case 'whatsapp': {
        response = 'https://wa.me/?text=' + (this.urlShare);
        break;
      }
      case 'email': {
        response = 'mailto:?subject=Ecommerce%20GTC.&amp;body=' + (this.urlShare);
        break;
      }

    }
    return response;
  }
}
