import { Injectable } from '@angular/core';

import {Observable} from 'rxjs/Rx';

import {PayflowEnvironmentParameterModel} from '../../models/payflow-environment-parameter.model';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CreditCardClass} from '../../classes/credit-card.class';
import {TransactionResponseInterface} from '../../classes/transaction-response.interface';
import {PayflowTransactionResponseClass} from '../../classes/payflow-transaction-response.class';
import {TransactionRequestFactoryClass} from '../../classes/transaction-request-factory.class';
import {TransactionRequestInterface} from '../../classes/transaction-request.interface';
import {UtilClass} from '../../classes/Util.class';
import {NumberTypeEnum} from '../../enums/number-type.enum';

@Injectable()
export class NgGtcPayflowService {

  public constructor( private httpService: HttpClient) {}

  public requestCreditCardPayment(
    creditCard: CreditCardClass, amount: number, comment1: string, comment2: string, uniqueReference: string ):
  Observable<TransactionResponseInterface> {
    try {
      const payflowEnvironmentParameter: PayflowEnvironmentParameterModel = environment.ngGtcPayflow;
      const transactionRequestFactory: TransactionRequestFactoryClass = TransactionRequestFactoryClass.getInstance();

      const creditCardPaymentRequest: TransactionRequestInterface
        = transactionRequestFactory.createPayflowSaleTransaction(
          payflowEnvironmentParameter, creditCard, amount, comment1, comment2);

      return this.requestPayflowPaymentToPayflowGatewayApi( creditCardPaymentRequest.getRequestToServer(), uniqueReference )
        .map ( ( nameValuePairStringPayflowGatewayApiResponse: string ) => {
          const transactionResponse: TransactionResponseInterface =
            new PayflowTransactionResponseClass(
              nameValuePairStringPayflowGatewayApiResponse, creditCardPaymentRequest);

          return transactionResponse;
        } ).catch( (error: any) => {
          return Observable.throw(error);
        } );
    } catch ( error ) {
      console.error ( error );
    }
    return null;
  }

  public requestAccountVerification(creditCard: CreditCardClass, uniqueReference: string): Observable<boolean> {
    try {
      const payflowEnvironmentParameter: PayflowEnvironmentParameterModel = environment.ngGtcPayflow;
      const transactionRequestFactory: TransactionRequestFactoryClass = TransactionRequestFactoryClass.getInstance();

      const creditCardPaymentRequest: TransactionRequestInterface
        = transactionRequestFactory.createPayflowVerificationTransaction(payflowEnvironmentParameter, creditCard);

      return this.requestPayflowPaymentToPayflowGatewayApi( creditCardPaymentRequest.getRequestToServer(), uniqueReference )
        .map ( ( nameValuePairStringPayflowGatewayApiResponse: string ) => {
          const transactionResponse: TransactionResponseInterface =
            new PayflowTransactionResponseClass(
              nameValuePairStringPayflowGatewayApiResponse, creditCardPaymentRequest);

          return transactionResponse.isSuccess();
        } ).catch( (error: any) => {
          return Observable.throw(error);
        } );
    } catch ( error ) {
      console.error ( error );
    }
    return null;
  }

  public requestAuthorizationPayment(creditCard: CreditCardClass, comment1: string, comment2: string,
                                     amount: number, uniqueReference: string): Observable<TransactionResponseInterface> {
    try {
      const payflowEnvironmentParameter: PayflowEnvironmentParameterModel = environment.ngGtcPayflow;
      const transactionRequestFactory: TransactionRequestFactoryClass = TransactionRequestFactoryClass.getInstance();

      const creditCardPaymentRequest: TransactionRequestInterface
        = transactionRequestFactory.createPayflowAutorizationTransaction(
          payflowEnvironmentParameter, creditCard, amount, comment1, comment2);
      return this.requestPayflowPaymentToPayflowGatewayApi( creditCardPaymentRequest.getRequestToServer(), uniqueReference )
        .map ( ( nameValuePairStringPayflowGatewayApiResponse: string ) => {
           const transactionResponse: TransactionResponseInterface =
            new PayflowTransactionResponseClass(
              nameValuePairStringPayflowGatewayApiResponse, creditCardPaymentRequest);

           return transactionResponse;
        } ).catch( (error: any) => {
          return Observable.throw(error);
        } );
    } catch ( error ) {
      console.error ( error );
    }
    return null;
  }

  public requestDelayedPayment(amount: number, approvedTransactionResponse: TransactionResponseInterface,
                               comment1: string, comment2: string, uniqueReference: string):
                              Observable<TransactionResponseInterface> {
    try {
      const creditCard: CreditCardClass = approvedTransactionResponse.getTransactionRequest().getCreditCard();
      const payflowEnvironmentParameter: PayflowEnvironmentParameterModel = environment.ngGtcPayflow;
      const transactionRequestFactory: TransactionRequestFactoryClass = TransactionRequestFactoryClass.getInstance();

      const creditCardPaymentRequest: TransactionRequestInterface
        = transactionRequestFactory.createPayflowDelayedTransaction(
          payflowEnvironmentParameter, creditCard, approvedTransactionResponse, amount, comment1, comment2);

      return this.requestPayflowPaymentToPayflowGatewayApi( creditCardPaymentRequest.getRequestToServer(), uniqueReference )
        .map ( ( nameValuePairStringPayflowGatewayApiResponse: string ) => {
          const transactionResponse: TransactionResponseInterface =
            new PayflowTransactionResponseClass(
              nameValuePairStringPayflowGatewayApiResponse, creditCardPaymentRequest);

          return transactionResponse;
        } ).catch( (error: any) => {
          return Observable.throw(error);
        } );
    } catch ( error ) {
      console.error ( error );
    }
    return null;
  }

  private requestPayflowPaymentToPayflowGatewayApi( requestString: string, uniqueReference: string ): Observable<string> {
    const url: string = environment.ngGtcPayflowonHold.apiUrl + this.getUrlParameter() + '/';

    const httpHeaders: HttpHeaders = this.getRequestHeaders(uniqueReference);
    return this.httpService.post(
        url,
        requestString,
        { headers: httpHeaders, responseType: 'text' } )
      .catch( ( error ) => {
        return Observable.throw(error);
      } );
  }

  private getRequestHeaders(uniqueReference: string): HttpHeaders {
    return new HttpHeaders(
      {
      'Content-Type': 'text/namevalue'
      }
    );
  }

  private getUrlParameter(): string {
    try {
        const numberType: NumberTypeEnum = environment.ngGtcPayflow.numberType;
        return UtilClass.generateRandomNumberByType(numberType) + '';
    } catch (error) {
      console.error(error);
    }
  }


}
