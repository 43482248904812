import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-itinerary-details',
  templateUrl: './itinerary-details.component.html',
  styleUrls: ['./itinerary-details.component.css']
})
export class ItineraryDetailsComponent implements OnInit {
  @Input() public itineraries: any[];
  @Input() public className: string;
  @Input() public isIncludeTableHeader = false;
  @Input() public tourLengthShortName: string;
  @Input() public cruiseName: string;

  constructor() { }

  ngOnInit() {
  }
  isPair(index: number) {
    return index % 2 === 0 ? true : false;
  }

}
