import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { UrlParams } from '../models/urlParams';

@Injectable({
  providedIn: 'root'
})
export class UrlParamsService {
  public paramsInRoute: UrlParams;
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    // tslint:disable-next-line: no-shadowed-variable
    this.activatedRoute.params.subscribe(activatedRoute => {
      this.paramsInRoute = {
        companyName: activatedRoute.companyName,
        codeAvailability: activatedRoute.codeAvailability,
        key: activatedRoute.key,
        pax: activatedRoute.pax,
      };
    })
  }
  setParams(params: UrlParams) {
    this.paramsInRoute = params;
  }
  getUrlParams(): UrlParams {
    try {
      return this.paramsInRoute;
    } catch (error) {
      console.error(error);
    }
  }
  getCodeAvail(): string {
    try {
      return this.paramsInRoute.codeAvailability;
    } catch (error) {
      console.error(error);
    }
  }
  getCompanyName(): string {
    try {
      return this.paramsInRoute.companyName;
    } catch (error) {
      console.error(error);
    }
  }
  getPax(): string {
    try {
      return this.paramsInRoute.pax;
    } catch (error) {
      console.error(error);
    }
  }
  getKey(): string {
    try {
      return this.paramsInRoute.key;
    } catch (error) {
      console.error(error);
    }
  }
  createurlForNextPage(nextPage: string) {
    return '/' + this.paramsInRoute.companyName + '/' +
      this.paramsInRoute.codeAvailability + '/' +
      this.paramsInRoute.pax + '/' +
      this.paramsInRoute.key + '/' + nextPage;
  }
}
