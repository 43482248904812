import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GalavailServiceEcommer } from "clientwsGalavail";
import * as $ from "jquery";
import { NgWebflowService, ServerDateService } from "ngweflow";
import { Availability } from "src/app/models/availability";
import { PaymentMethod } from "src/app/models/payment-method";
import { PaypalPaymentResponse } from "src/app/models/paypal/paypal-payment-response";
import { Purchase } from "src/app/models/purchase";
import { BookingConfirmationResponse } from "src/app/models/purchase/booking-confirmation-response";
import { UnblockDeparture } from "src/app/models/purchase/unblock-departure";
import { TransactionResponseInterface } from "src/app/ngGtcPayflow/classes/transaction-response.interface";
import { RoyalRequestService } from "src/app/services/royal-request/royal-request.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { isNullOrUndefined } from "util";
import { environment } from "../../../../environments/environment";
import { CodeProcesscrmEnum } from "../../../enums/code-process-crm.enum";
import { PaymentMethodTransfer } from "../../../enums/payment-method-transfer.enum";
// import { ServerDateService } from '../../services/server-date/server-date.service';
import { PaymentsType } from "../../../models/payments-type";
import { PaymentsTypeService } from "../../../services/payments-type/payments-type.service";
import { ContactFormService } from "../../services/contact-form/contact-form.service";
import { CruiseService } from "../../services/cruise/cruise.service";
import { PromoService } from "../../services/promo/promo.service";
import { PurchaseRequestService } from "../../services/purchase-request/purchase-request.service";
import { PaymentType } from "./../../../enums/payment-type.enum";
import { PurchaseRequestStatus } from "./../../../enums/purchase-request-status.enum";
import { BlockDeparture } from "./../../../ngGtcPayflow/models/block-departure";
import { CompanyParamsService } from "./../../../services/companyParams/company-params.service";
import { UrlParamsService } from "./../../../services/url-params.service";
declare let paypal: any;

@Component({
  selector: "app-checkout-third",
  templateUrl: "./checkout-third.component.html",
  styleUrls: ["./checkout-third.component.css"],
})
export class CheckoutThirdComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public isBlockDepartureSuccess: boolean;
  public activeEndSlide: string;
  public purchaseTotalPrice;
  public comment1: string;
  private isConsoleLog: boolean;
  public paymentType = PaymentType;
  public isPaymentComplete: boolean;
  public isPaymentCancel: boolean;
  public isPaymentError: boolean;
  public isProcessing: boolean = false;
  public cruise: any;
  public startEndDates: string;
  public startEndDatesType: string;
  public promotion: string;
  public priceWithAirFare: number;
  public itineraryIndex: number;
  public cruiseStartDate: string;
  public boatImagesResource: string;
  public isRequestingBlockDeparture: boolean;
  public position: boolean;
  public cruiseItineraryDuration: string;
  public isAutoPaymentAccepted: boolean;
  public minDepartureTimeToEnableAutoPayment: number;
  public termsCondition: boolean;
  public hasThirtyPercent: boolean;
  public valueTerms = "none";
  isSelectedThirtyPercent = false;
  allValuePrice: number;
  onlyThirtyPrice: number;
  paymentMethod: any;
  termsConditionPaytransfer: boolean;
  flagClick = false;
  availavilitySelected: Availability;
  geoIp = "";
  urlElementsArray: string[] = [];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  paymentMethods: PaymentMethod[];
  constructor(
    private router: Router,
    private purchaseRequestService: PurchaseRequestService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private cruiseService: CruiseService,
    private ngWebflowService: NgWebflowService,
    private serverDateService: ServerDateService,
    private contactFormService: ContactFormService,
    private urlParamsService: UrlParamsService,
    public companyParamsService: CompanyParamsService,
    private promoService: PromoService,
    private royalRequestService: RoyalRequestService,
    private paymentsTypeService: PaymentsTypeService
  ) {
    this.isConsoleLog = environment.console_log;
    this.hasThirtyPercent = false;
    this.paymentMethod = {};
    this.paymentMethod.paypal = false;
    this.paymentMethod.creditCard = false;
    this.paymentMethod.transfer = true;
    this.allValuePrice = 0;
    this.onlyThirtyPrice = 0;
    $(document).ready(function () {
      $("html, body").animate(
        {
          scrollTop: $("#startCheckoutThird").offset().top,
        },
        1000
      );
    });
    this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
      this.geoIp = ip;
      this.isOnTimeToPay();
    });
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.purchaseRequestService.purchase.cruise)) {
      this.activeEndSlide = "active";
      this.isSelectedThirtyPercent = false;
      this.purchaseRequestService.setIsSelectedThirtyPerent(
        this.isSelectedThirtyPercent
      );
      const selecteIndex = 0;
      this.paymentMethods = this.companyParamsService.getPaymentMethods();
      this.paymentMethods.forEach((item, index) => {
        item.selected = false;
      });
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.availavilitySelected = this.cruise.availability[selecteIndex];
      this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();
      this.allValuePrice = this.purchaseRequestService.getTotalPrice();
      this.onlyThirtyPrice = Math.round(
        this.allValuePrice *
          (this.companyParamsService.getPercentPartialPayment() / 100)
      );
      this.cruiseStartDate =
        this.cruise.availability[this.cruise.iterIndex].date;
      this.startEndDates =
        this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType =
        this.cruise.availability[this.cruise.iterIndex].name;
      this.promotion =
        this.cruise.availability[this.cruise.iterIndex].promotion;
      this.itineraryIndex = selecteIndex;
      this.priceWithAirFare = this.cruiseService.getCruisePriceinNewFormat(
        this.cruise,
        this.itineraryIndex,
        true
      );
      this.purchaseRequestService.setPaymentMethod(this.paymentMethod);
      this.initializeBoatImageResource();
      this.position = true;
      this.cruiseItineraryDuration = this.purchaseRequestService
        .getCruiseWrapper()
        .getCruiseItineraryDuration();
      this.isAutoPaymentAccepted =
        this.purchaseRequestService.isAutoPaymentAccepted();

      this.minDepartureTimeToEnableAutoPayment =
        environment.PURCHASE.MIN_TIME_TO_ENABLE_AUTOPAYMENT;
    } else {
      window.location.href = window.location.href.substring(
        0,
        window.location.href.length - 1
      );
    }
  }
  ngAfterViewInit(): void {
    if (this.isAutoPaymentAccepted === true) {
      this.renderPaypalButton("paypal-button-payment", false, "paypal");
      // this.renderPaypalButton('paypal-button-funding-buttons', true, 'buynow');
    }
  }
  ngOnDestroy(): void {
    /*
    this.isSelectedThirtyPercent = false;
    this.purchaseRequestService.setIsSelectedThirtyPerent(
      this.isSelectedThirtyPercent
    );
    this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();*/
  }
  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo;
  }
  nextFourthPart() {
    this.router.navigate([this.urlParamsService.createurlForNextPage("4")]);
  }
  onErrorCreditCardPayment(event) {}
  alertTerms() {
    alert("Please accept terms and conditions");
  }

  /** Este metodo esta comentado debido a q sirve unicamente para probar el servicio de bloque*/
  public blockCabinNewjson(test?): void {
    this.isProcessing = true;

    this.purchaseRequestService
      .requestBlockCabinOnHold(this.purchaseRequestService.getPercentUsed())
      .subscribe(
        (blockDepartureResponse: BlockDeparture) => {
          const isblockDeparture: boolean =
            this.purchaseRequestService.setBlockDeparture(
              blockDepartureResponse
            );
          this.isBlockDepartureSuccess =
            isblockDeparture === true &&
            this.purchaseRequestService.isRequestBlockDepartureSuccess();
          if (this.isBlockDepartureSuccess === false) {
            this.isBlockDepartureFailed();
          } else {
            //this.requestPostSpaces();
            this.isPaymentComplete = true;
            this.isPaymentCancel = false;
            this.isPaymentError = false;
            this.purchaseRequestService.purchaseRequestStatus =
              PurchaseRequestStatus.PAYMENT_COMPLETED;
            this.ngWebflowService.addWebFlowAction("EXECUTING_PAYMENT", {
              result: "completed: " + test,
              termsConditions: false,
            });
            this.requestBookingConfirmation();
          }
          this.isRequestingBlockDeparture = false;
        },
        (error) => {
          this.isRequestingBlockDeparture = false;
          this.isBlockDepartureSuccess = false;
          this.ngWebflowService.addWebFlowAction("ONHOLD", {
            result: "error" + error,
          });
          console.log("PaymentOptionsComponent.blockDeparture()");
          console.error(error);
          this.isBlockDepartureFailed();
        },
        () => {
          this.isRequestingBlockDeparture = false;
        }
      );
  }

  async requestRoyalForm(isVoerTime: boolean) {
    if (this.companyParamsService.getName() === "Royal Galapagos") {
      this.isProcessing = true;

      let url = this.getUrlRequest(isVoerTime);
      this.royalRequestService.requestGetForRoyal(url).subscribe(
        (err) => {
          this.ngWebflowService.addWebFlowAction("SEND_CRM_REQUEST", {
            result: err,
            url,
          });
          this.nextFourthPart();
        },
        () => {
          this.ngWebflowService.addWebFlowAction("Ok_SEND_CRM_REQUEST", {
            result: "OK-fromPaymentOptions",
            url,
          });
          this.nextFourthPart();
        }
      );
    } else {
      this.nextFourthPart();
    }
  }

  async requestRoyalFormOverTime(isOVerTime: boolean) {
    if (this.companyParamsService.getName() === "Royal Galapagos") {
      let url = this.getUrlRequest(isOVerTime);
      this.royalRequestService.requestGetForRoyal(url).subscribe(
        (err) => {
          this.ngWebflowService.addWebFlowAction("SEND_CRM_REQUEST", {
            result: err,
            url,
          });
        },
        () => {
          this.ngWebflowService.addWebFlowAction("Ok_SEND_CRM_REQUEST", {
            result: "OK-fromPaymentOptions",
            url,
          });
        }
      );
    }
  }
  getUrlRequest(isOVerTime: boolean): string {
    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText =
      this.getPromoName(cruiseIndex) === "0"
        ? "Regular Rate"
        : this.getPromoName(cruiseIndex);
    const newLetter = "0";

    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;

    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate);
    this.getInterestDataRoyal();
    this.getContactInfoRoyal();
    this.getStringUtm();
    this.urlElementsArray.push(newLetter);
    this.urlElementsArray.push(this.geoIp);
    this.setLastFourItmes(isOVerTime);
    const urlEscapedElementsArray = this.urlElementsArray.map(
      (urlElement: string) => {
        return encodeURIComponent(urlElement);
      }
    );

    urlFinal += urlEscapedElementsArray.join("/");
    return urlFinal;
  }
  setLastFourItmes(isOverTime: boolean) {
    this.urlElementsArray.push(this.isMobile());
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push(
      isOverTime ? CodeProcesscrmEnum.RG_NP_AV : CodeProcesscrmEnum.RG_ECOM_BOOK
    );
  }

  isMobile() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  getStringTravelDataRoyal(startDate: Date, endDate: Date): string {
    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.availavilitySelected.length.substring(0, 1);
    let optionCruise = this.availavilitySelected.name.substring(
      this.availavilitySelected.name.length,
      1
    );
    let numberAdults = this.purchaseRequestService.getNumberOfAdults();
    let numberChild = this.purchaseRequestService.getNumberOfChildren();
    let comments =
      this.purchaseRequestService.getPurchaseContactPerson().remarks === ""
        ? "None specific"
        : this.purchaseRequestService.getPurchaseContactPerson().remarks;
    const dayString =
      startDate.getDate() < 10
        ? "0" + String(startDate.getDate())
        : String(startDate.getDate());
    const endDayString =
      endDate.getDate() < 10
        ? "0" + String(endDate.getDate())
        : String(endDate.getDate());

    arrivalDate = `${
      this.monthNames[startDate.getMonth()]
    } ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${
      this.monthNames[endDate.getMonth()]
    } ${endDayString}, ${endDate.getFullYear()}`;
    this.urlElementsArray.push(arrivalDate);
    this.urlElementsArray.push(departureDate);
    this.urlElementsArray.push(daysCruise);
    this.urlElementsArray.push(optionCruise);
    this.urlElementsArray.push(String(numberAdults));
    this.urlElementsArray.push(String(numberChild));
    this.urlElementsArray.push(`${comments}- THE PAYMENT HAS BEEN COMPLETING`);
    this.urlElementsArray.push("en");

    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }
  getStringUtm(): string {
    const utms = this.royalRequestService.getUtm();
    this.urlElementsArray.push(utms.utm_source);
    this.urlElementsArray.push(utms.utm_medium);
    this.urlElementsArray.push(utms.utm_campaign);
    this.urlElementsArray.push(utms.utm_term);
    return `${utms.utm_source}/${utms.utm_medium}/${utms.utm_campaign}/${utms.utm_term}/`;
  }
  getContactInfoRoyal(): string {
    let fName =
      this.purchaseRequestService.getPurchaseContactPerson().firstName;
    let lName = this.purchaseRequestService.getPurchaseContactPerson().lastName;
    let email = this.purchaseRequestService.getPurchaseContactPerson().email;
    let phone =
      isNullOrUndefined(
        this.purchaseRequestService.getPurchaseContactPerson().phone
      ) || this.purchaseRequestService.getPurchaseContactPerson().phone === ""
        ? "Not specific"
        : this.purchaseRequestService.getPurchaseContactPerson().phone;
    let country =
      this.purchaseRequestService.getPurchasePassengersInformation()[0].country;
    let agency =
      this.purchaseRequestService.getPurchaseContactPerson().agency === ""
        ? "Not specific"
        : this.purchaseRequestService.getPurchaseContactPerson().agency;
    let reference = this.purchaseRequestService.getPurchaseContactPerson()
      .lastName
      ? this.purchaseRequestService.getPurchaseContactPerson().lastName
      : "Not specific";
    this.urlElementsArray.push(fName);
    this.urlElementsArray.push(lName);
    this.urlElementsArray.push(email);
    this.urlElementsArray.push(phone);
    this.urlElementsArray.push(country);
    this.urlElementsArray.push(agency);
    this.urlElementsArray.push(reference);

    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }
  getInterestDataRoyal(): string {
    let interestString = "";
    interestString = "0/0/0/0/";
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");

    return interestString;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }
  getStringFormDatailRoyal(promoText: string): string {
    let product = this.royalRequestService.getNameBoatToRoyal(this.cruise.name);
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.urlElementsArray.push(product);
    this.urlElementsArray.push(promoText);
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }

  private requestBookingConfirmation(): void {
    try {
      this.isProcessing = true;
      this.purchaseRequestService.requestBookingConfirmation().subscribe(
        (bookingConfirmationResponse: BookingConfirmationResponse) => {
          if (
            this.purchaseRequestService.isRequestBookingConfirmationSuccess(
              bookingConfirmationResponse
            )
          ) {
            this.purchaseRequestService.purchaseRequestStatus =
              PurchaseRequestStatus.BOOCKING_CONFIRMATION_SENT;

            this.requestRoyalForm(false);
          }
        },
        (error: any) => {
          this.isProcessing = false;
          console.log("BookTourComponent.requestbookingConfirm()");
          console.error(error);
          this.ngWebflowService.addWebFlowAction("SENDCONFIRMATION_EMAIL", {
            result: "error: " + error,
            requestBody: {
              ...this.purchaseRequestService.preparePurchaseBookingConfirm(),
            },
          });
        },
        () => {
          this.isProcessing = false;
        }
      );
    } catch (error) {
      console.log("BookTourComponent.requestbookingConfirm()");
      console.error(error);
    }
  }
  selectThirtyPercent() {
    this.isSelectedThirtyPercent = !this.isSelectedThirtyPercent;
    this.purchaseRequestService.setIsSelectedThirtyPerent(
      this.isSelectedThirtyPercent
    );
    this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();
  }

  changeTermsPayTransfer(eventValue) {
    this.termsConditionPaytransfer = eventValue;
    this.valueTerms = eventValue ? "all" : "none";
    this.ngWebflowService.addWebFlowAction("CLICK_TERMS_CODITIONS_PAYPAL", {
      termsConditionPaytransfer: this.termsConditionPaytransfer,
    });
  }

  private isOnTimeToPay(): void {
    try {
      this.serverDateService.requestServerDateAsString().subscribe(
        (serverDate: string) => {
          // const departureDeta: Date = new Date(this.cruiseWrapper.getCurrentItinerary()
          const departureDeta: Date = new Date(
            this.purchaseRequestService
              .getCruiseWrapper()
              .getCurrentItinerary().startDate
          );
          const msDifference =
            Number(departureDeta.getTime()) - Number(serverDate);
          const hoursDifference =
            UtilsService.millisecondsToHours(msDifference);
          const daysDifference = UtilsService.millisecondsToDays(msDifference);
          this.hasThirtyPercent =
            daysDifference < this.minDepartureTimeToEnableAutoPayment
              ? false
              : true;

          if (hoursDifference < this.minDepartureTimeToEnableAutoPayment) {
            this.isAutoPaymentAccepted = false;
            this.sendEmail();
          } else {
            this.isAutoPaymentAccepted = true;
          }
        },
        (error: any) => {
          console.error(error);
          this.isAutoPaymentAccepted = false;
        },
        () => {}
      );
    } catch (error) {
      console.error(error);
      this.isAutoPaymentAccepted = false;
    }
  }

  private sendEmail() {
    const myPurchase = this.purchaseRequestService.purchase as Purchase;
    console.log("--->sending automatic");
    this.galavailServiceEcommer
      .sendInquireEmail(this.fillRequestDatatoInquire(myPurchase))
      .subscribe(
        (response) => {
          const result: boolean =
            this.contactFormService.isSendFormResponseSuccess(response);
          this.ngWebflowService.addWebFlowAction("SENDING_AUTOMATIC", {
            result: {
              actionStatus: result,
              purchaseStatus: this.purchaseRequestService.purchaseRequestStatus,
            },
          });
          this.requestRoyalFormOverTime(true);
        },
        (errorResponse) => {
          this.ngWebflowService.addWebFlowAction("SENDING_AUTOMATIC", {
            result: {
              actionStatus: true,
              purchaseStatus: this.purchaseRequestService.purchaseRequestStatus,
            },
          });
          console.error(errorResponse);
        }
      );
  }

  changePaymentMethod(i: number) {
    this.termsConditionPaytransfer = false;
    this.flagClick = false;
    this.paymentMethods.forEach((item, index) => {
      if (index === i) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    const selectedCodePayment: PaymentMethod = this.paymentMethods[i];
    if (
      selectedCodePayment.type === PaymentMethodTransfer.TYPE_TRANSFER_EC ||
      selectedCodePayment.type === PaymentMethodTransfer.TYPE_TRANSFER_US ||
      selectedCodePayment.type === PaymentMethodTransfer.DIRECT_TRANSFER
    ) {
      this.paymentMethod.transfer = true;
      this.paymentMethod.creditCard = false;
      this.paymentMethod.paypal = false;
    } else {
      this.paymentMethod.transfer = false;
      this.paymentMethod.creditCard = false;
      this.paymentMethod.paypal = false;
    }
    this.purchaseRequestService.setCodePaymentMethod(selectedCodePayment.code);
    this.purchaseRequestService.setPaymentMethod(this.paymentMethod);
  }

  fillRequestDatatoInquire(purchase: Purchase): any {
    const objectResquest = {
      emailReceptor: "edisson@galapagosislands.com",
      subject: "New Request test",
      inquireDTO: {
        company: this.urlParamsService.getCompanyName(),
        boat: purchase.cruise.name,
        promotion: purchase.cruise.availability[0].promotion,
        startDate: purchase.cruise.availability[0].startDate,
        endDate: purchase.cruise.availability[0].endDate,
        days: purchase.cruise.availability[0].lenght,
        opcDays: purchase.cruise.availability[0].name,
        adults: this.purchaseRequestService.getNumberOfAdults(),
        childs: this.purchaseRequestService.getNumberOfChildren(),
        commnets:
          "Given that your cruise will take place within the next 72 hours",
        interestFlights: true,
        interestAmazon: true,
        interestPeru: true,
        interestEcuador: true,
        lastName: purchase.purchaseContactPerson.lastName,
        firstName: purchase.purchaseContactPerson.firstName,
        email: purchase.purchaseContactPerson.email,
        phone: purchase.purchaseContactPerson.phone,
        country: "",
        interestPromos: false,
      },
      company: {
        logoCompany: this.companyParamsService.getLogo(),
        nameCompany: this.companyParamsService.getName(),
        mainColorCompany: this.companyParamsService.getMainColor(),
        secondaryColorCompany: this.companyParamsService.getSecundaryColor(),
        headerColorCompany: this.companyParamsService.getHeaderColor(),
        footerColorCompany: this.companyParamsService.getFooterColor(),
        infoMailCompany: this.companyParamsService.getInfoEmail(),
        phoneCompany: this.companyParamsService.getPhone(),
        webSiteCompany: this.companyParamsService.getWebSite(),
      },
    };
    return objectResquest;
  }
  public paymentComplete(paymentResponse: any, paymentType: PaymentType): void {
    try {
      this.isProcessing = true;
      // this.requestPostSpaces();
      this.purchaseRequestService.addPaymentResponse(
        paymentResponse,
        paymentType
      );
      this.isPaymentComplete = true;
      this.isPaymentCancel = false;
      this.isPaymentError = false;

      if (paymentType === PaymentType.PAYPAL) {
        this.setBookingTrackCode(paymentResponse.id);
      } else if (paymentType === PaymentType.payflowCreditCard) {
        const PayflowTransactionResponse: TransactionResponseInterface =
          paymentResponse as TransactionResponseInterface;
        this.setBookingTrackCode(PayflowTransactionResponse.getId());
      }

      this.purchaseRequestService.purchaseRequestStatus =
        PurchaseRequestStatus.PAYMENT_COMPLETED;
      const paymentData = {
        paymentResponse: paymentResponse,
        paymentType: paymentType,
      };
      this.ngWebflowService.addWebFlowAction("EXECUTING_PAYMENT", {
        paymentData,
      });
      this.requestBookingConfirmation();
    } catch (error) {
      this.isProcessing = false;
      console.error(error);
    }
  }
  private setBookingTrackCode(bookingTrackCode: string): void {
    try {
      this.purchaseRequestService.setTrackingCode(bookingTrackCode);
    } catch (error) {
      console.error();
    }
  }
  private initPayment(): void {}

  renderPaypalButton(
    htmlElementId: string,
    includeFundingButtons: boolean,
    label: string
  ): void {
    try {
      this.isProcessing = false;
      const paypalRequestObject: any = {
        style: this.getPayPalButtonStyleObject(includeFundingButtons, label),
        env: this.companyParamsService.getPayPalInfo().apiMode,
        client: {
          sandbox: this.companyParamsService.getPayPalInfo().clientSandboxToken,
          production:
            this.companyParamsService.getPayPalInfo().clientProductionToken,
        },
        funding: this.getPaypalFundingMethodsObject(),
        commit: true,
        payment: (data, actions) => {
          this.initPayment();
          return actions.payment.create(this.getPaypalPaymentObject());
        },
        onAuthorize: (data: any, actions: any) => {
          return actions.payment
            .get()
            .then((approvedPaymentResponse: PaypalPaymentResponse) => {
              this.isRequestingBlockDeparture = true;

              this.isProcessing = true;
              this.purchaseRequestService
                .requestBlockCabinOnHold(
                  this.purchaseRequestService.getPercentUsed()
                )
                .subscribe(
                  (blockDepartureResponse: BlockDeparture) => {
                    const isblockDeparture: boolean =
                      this.purchaseRequestService.setBlockDeparture(
                        blockDepartureResponse
                      );
                    this.isBlockDepartureSuccess =
                      isblockDeparture === true &&
                      this.purchaseRequestService.isRequestBlockDepartureSuccess();
                    if (this.isBlockDepartureSuccess === false) {
                      this.isBlockDepartureFailed();
                    } else {
                      return actions.payment.execute().then(
                        (executedPaymentResponse: PaypalPaymentResponse) => {
                          this.paymentComplete(
                            executedPaymentResponse,
                            PaymentType.PAYPAL
                          );
                        },
                        (error) => {
                          this.isRequestingBlockDeparture = false;
                          this.isBlockDepartureSuccess = false;
                          console.error(error);
                          this.isBlockDepartureFailed();
                        }
                      );
                    }
                    this.isRequestingBlockDeparture = false;
                  },
                  (error) => {
                    this.isRequestingBlockDeparture = false;
                    this.isBlockDepartureSuccess = false;
                    this.isProcessing = false;
                    console.log("PaymentOptionsComponent.blockDeparture()");
                    console.error(error);
                    this.isBlockDepartureFailed();
                  },
                  () => {
                    this.isRequestingBlockDeparture = false;
                  }
                );
            });
        },
        onCancel: (data, actions) => {
          this.paymentCanceled(data, actions);
        },
        onError: (error) => {
          this.paymentError(error);
        },
      };

      if (this.isConsoleLog) {
        console.log("paymentOptions.renderPaypalButton(): Request Object");
        console.log(paypalRequestObject);
      }

      paypal.Button.render(paypalRequestObject, "#" + htmlElementId);
    } catch (error) {
      console.log("PaymentOptionsCompoent.renderPaypalButton()");
      console.error(error);
    }
  }
  isProcessingMethod(event: any) {
    this.isProcessing = true;
  }
  private paymentError(error: any) {
    try {
      this.isPaymentComplete = false;
      this.isPaymentCancel = false;
      this.isPaymentError = true;
      if (
        this.purchaseRequestService.isRequestBlockDepartureSuccess() === true
      ) {
        this.unblockDeparture();
      }
      this.ngWebflowService.addWebFlowAction("EXECUTING_PAYMENT", {
        result: "error",
      });
    } catch (error) {
      console.log("PaymentOptionsComponent.paymentError()");
      console.error(error);
    }
  }
  private paymentCanceled(data: any, actions: any): void {
    try {
      this.isPaymentComplete = false;
      this.isPaymentCancel = true;
      this.isPaymentError = false;
      if (
        this.purchaseRequestService.isRequestBlockDepartureSuccess() === true
      ) {
        this.unblockDeparture();
      }
      this.ngWebflowService.addWebFlowAction("EXECUTING_PAYMENT", {
        result: "canceled",
      });
    } catch (error) {
      console.log("PaymentOptionsComponent.paymentCanceled()");
      console.error(error);
    }
  }
  private unblockDeparture(): void {
    try {
      this.purchaseRequestService.requestUnblockDeparture().subscribe(
        (unblockDepartureResponse: UnblockDeparture) => {
          const isUnblockDeparture: boolean =
            this.purchaseRequestService.setUnblockDeparture(
              unblockDepartureResponse
            );
          const isUnblockDepartureSuccess: boolean =
            isUnblockDeparture === true &&
            this.purchaseRequestService.isRequestUnblockDepartureSuccess();
          if (isUnblockDepartureSuccess === false) {
            this.isUnblockDepartureFailed();
          } else {
            this.isBlockDepartureSuccess = undefined;
          }
        },
        (error) => {
          console.log("PaymentOptionsComponent.unblockDeparture()");
          console.error(error);
          this.isUnblockDepartureFailed();
        },
        () => {}
      );
    } catch (error) {
      console.log("PaymentOptionsComponent.unblockDeparture()");
      console.error(error);
      this.isBlockDepartureSuccess = false;
      this.isUnblockDepartureFailed();
      throw error;
    }
  }
  private isUnblockDepartureFailed(): void {
    // TODO do something
  }

  private getPaypalFundingMethodsObject(): any {
    return {
      allowed: [
        paypal.FUNDING.CREDIT,
        paypal.FUNDING.CARD,
        paypal.FUNDING.ELV,
        paypal.FUNDING.VENMO,
        paypal.FUNDING.IDEAL,
      ],
    };
  }
  private getPayPalButtonStyleObject(
    includeFundingButtons: boolean,
    label: string
  ): {
    fundingicons: boolean;
    size: string;
    shape: string;
    label: string;
    tagline: boolean;
    layout: string;
  } {
    return {
      fundingicons: includeFundingButtons,
      size: "small",
      shape: "rect",
      label: label,
      tagline: false,
      layout: "horizontal",
    };
  }
  private getPaypalPaymentObject(): any {
    return {
      payment: {
        intent: this.companyParamsService.getPayPalInfo().requestIntent,
        transactions: [
          {
            amount: {
              total: this.purchaseTotalPrice,
              currency: this.companyParamsService.getPayPalInfo().currency,
            },
            // description: this.purchaseRequestService.getPurchaseDescription(),
            // invoice_number: '487875896735',
            // soft_descriptor: this.purchaseRequestService.getPurchaseDescriptor(),
            // custom: 'AN ID OR SOMETHING46'
            item_list: this.isSelectedThirtyPercent
              ? {
                  items:
                    this.purchaseRequestService.getPaypalItemsWithThirtyPercent(
                      this.purchaseTotalPrice
                    ),
                }
              : {
                  items: this.purchaseRequestService.getPaypalItems(),
                },
          },
        ],
        note_to_payer:
          this.purchaseRequestService.getPurchaseShortDescription(),
      },
      experience: {
        input_fields: {
          no_shipping: 1,
        },
      },
    };
  }
  isBlockDepartureFailed() {}

  private initializeBoatImageResource(): void {
    this.boatImagesResource =
      environment.app_resources.logoCruise + this.cruise.image;
  }
  changeTerms(eventValue) {
    this.termsCondition = eventValue;
    this.valueTerms = eventValue ? "all" : "none";
  }

  getImagesByType(code: number): PaymentsType[] {
    return this.paymentsTypeService.getPaymentsByType(code);
  }
}
