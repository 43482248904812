import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseRequestService } from '../services/purchase-request/purchase-request.service';

@Pipe({
  name: 'showPassengerIndexInPassengerForm'
})
export class ShowPassengerIndexInPassengerFormPipe implements PipeTransform {

  constructor( private purchaseRequestService: PurchaseRequestService ) {}

  transform(passengerIndex: number, cabinIndex: number): number {
    try {
      let indexToDisplay = 0;
      for ( let i = 0; i < this.purchaseRequestService.purchase.cabins.length && i <= cabinIndex; i++ ) {
        for ( let o = 0; o < this.purchaseRequestService.purchase.cabins[ i ].purchaseCabinPasssengers.length; o++ ) {
          if ( i < cabinIndex || ( i === cabinIndex && o <= passengerIndex )  ) {
            indexToDisplay++;
          }
        }
      }
      return indexToDisplay;
    } catch ( error ) {
      console.error( error );
      return 0;
    }
  }

}
