import { TipoPaqueteEcommerceEnum } from './../enums/type-package-ecommerce.enum';
import { CubiertaCabina } from './../models/cabina/cubierta-cabina';
import { PromoCode } from './../enums/promo-code.enum';
import { CabinService } from './../reserve/services/cabin/cabin.service';
import { GalavailAppStatus } from './../enums/galavail-app-status.enum';
import { isNullOrUndefined } from 'util';
import { CamaCabinaList } from './../models/cabina/cama-cabina-list';
import { CabinaItinerarioMenor } from './../models/cabina/cabina-itinerario-menor';
import { TipoCabinaBarco } from '../models/cabina/tipo-cabina-barco';
import { TipoCabina } from '../models/cabina/tipo-cabina';
import { PassengerType } from '../enums/passenger-type.enum';
import { Cabina } from '../models/cabina/cabina';
import { SingleCabinFormCapacityType } from '../enums/single-cabin-form-capacity-type.enum';
import { environment } from 'src/environments/environment';
import { Cubierta } from '../models/cabina/cubierta';


export class CabinaItinerarioMenorWrapper {

  private cabinaItinerarioMenor: CabinaItinerarioMenor;

  public constructor(cabinaItinerarioMenor: CabinaItinerarioMenor) {
    cabinaItinerarioMenor.isSelected = false;
    this.setCabinaItinerarioMenor(cabinaItinerarioMenor);
  }
  // it is a method for get percent of array percents with package code
  public getAgencyPercent(): number {
    return null; // it is for use prices machine always, delete it if you want to use arrays percents
/*
    try {
      if (this.cabinaItinerarioMenor.percents.length !== 0) {
        // with my flag diferentA_B set type_B for get percent of array
        if (this.cabinaItinerarioMenor.percents.length === 1) {
          return Number(atob(this.cabinaItinerarioMenor.percents[0].value))
        }
        // find package code
        let valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
          === this.getPackageCode())
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_DEFAULT)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A)
        }

        if (isNullOrUndefined(valPercentObj)) {
          return null;
        }
        return Number(atob(valPercentObj.value));
      } else {
        return null;
      }
    } catch (error) {
      return null
    }*/
  }
  private getPackageCode() {
    return this.cabinaItinerarioMenor.packageCode;
  }
  public getCabinaItinerarioMenor(): CabinaItinerarioMenor {
    return this.cabinaItinerarioMenor;
  }

  private setCabinaItinerarioMenor(cabinaItinerarioMenor: CabinaItinerarioMenor): void {
    this.cabinaItinerarioMenor = cabinaItinerarioMenor;
  }

  public getCodigo(): number {
    return this.getCabinaItinerarioMenor().code;
  }

  public getCabina(): Cabina {
    return this.getCabinaItinerarioMenor().cabina;
  }

  public getTipoCabinaBarco(): TipoCabinaBarco {
    return this.getCabina().tipoCabinaBarco;
  }

  public getTipoCabina(): TipoCabina {
    try {
      return this.getTipoCabinaBarco().tipoCabina;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getCamaCabinaList(): CamaCabinaList[] {
    try {
      return this.getCabinaItinerarioMenor().accommodation;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public getIsSelected(): boolean {
    try {
      return this.getCabinaItinerarioMenor().isSelected;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public setIsSelected(value: boolean): boolean {
    try {
      this.getCabinaItinerarioMenor().isSelected = value;
    } catch (error) {
      console.error(error);
      return null;
    }
  }



  public getCamaCabina(camaCabinaListIndex: number): CamaCabinaList {
    try {
      if (isNullOrUndefined(this.getCamaCabinaList()) || isNullOrUndefined(this.getCamaCabinaList()[camaCabinaListIndex])
        || this.getCamaCabinaList()[camaCabinaListIndex].estado === GalavailAppStatus.INACTIVE) {
        return null;
      }
      return this.getCamaCabinaList()[camaCabinaListIndex];
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getValorTarifaPromocion(): number {

    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().promotionPublic)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaPromocion(): Null price');
      }

      return Number(this.getCabinaItinerarioMenor().promotionPublic);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getValorTarifaAgencias(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().promotionPublic)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaPromocion(): Null price');
      }
      return this.getCabinaItinerarioMenor().promotionPublic;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getValorSuplemento(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().supplement)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorSuplemento(): Null price')
      }
      const valueSuplement = this.getCabinaItinerarioMenor().supplement;

      return Math.floor(valueSuplement);
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getValorSuplemento()');
      console.error(error);
      throw error;
    }
  }

  public getValorTarifaNino(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().childrenPrice)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaNino(): Null price');
      }
      return this.getCabinaItinerarioMenor().childrenPrice;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getTarifa(): number {
    try {
      return this.getCabinaItinerarioMenor().rackPrice;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getPromo(): number {
    try {
      return this.getCabinaItinerarioMenor().promotionType;
    } catch (error) {
      console.error();
      return null;
    }
  }

  public getPromoCode(): number {
    try {
      const promo = this.getPromo();
      if (isNullOrUndefined(promo)) { return null; }
      return promo;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getPromoPrice(
    cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      let price = 0;
      price += this.getValorTarifaPromocion();
      if (isIncludeSupplement === true) {
        price += this.getValorSuplemento();
      }
      return this.getPrice(price, cruiseCategory, includeAirFare, passengerType, cabinaService);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getTravelAgencyPrice(
    cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      let price = 0;
      // preguntar el valor de agencia
      price += this.getValorTarifaAgencias();


      if (isIncludeSupplement === true) {
        price += this.getValorSuplemento();
      }

      return this.getPrice(price, cruiseCategory, includeAirFare, passengerType, cabinaService);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getChildPrice(cruiseCategory: number, includeAirFare: boolean, cabinaService: CabinService): number {
    try {
      const valorTarifaNino: number = this.getValorTarifaNino();
      return this.getPrice(valorTarifaNino, cruiseCategory, includeAirFare, PassengerType.CHILD, cabinaService);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getAppPrice(
    cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {

      const appPrice: number = this.getTravelAgencyPrice(
        cruiseCategory, includeAirFare, isIncludeSupplement, passengerType, cabinaService);
      return appPrice;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getAppPriceByPassengerType(
    cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      const appPrice: number = this.getTravelAgencyPrice(
        cruiseCategory, includeAirFare, isIncludeSupplement, passengerType, cabinaService);
      return appPrice;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getWasPrice(
    cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      let totalPrice = 0;

      totalPrice += this.getTarifa();

      if (includeAirFare === true) {
        totalPrice += cabinaService.getAirFarePriceRate(passengerType);
      }

      return Math.round((totalPrice));
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  private getPrice(
    price: number,
    cruiseCategory: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {

    try {

      const promoCode: number = this.getCabinaItinerarioMenor().promotionType;
      return cabinaService.getAppPrice(price, cruiseCategory, promoCode, includeAirFare, passengerType, this.getAgencyPercent());
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getCapacidad(): number {
    try {
      return this.getAppCapacity(this.getCabinaItinerarioMenor().capacityAdults);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  private isCapacityDynamic(): boolean {
    try {
      const type: SingleCabinFormCapacityType = environment.single_cabin_form_capacity.type;
      if (isNullOrUndefined(type)
        || type === SingleCabinFormCapacityType.FIXED
        || isNullOrUndefined(environment.single_cabin_form_capacity.capacity
          || isNaN(environment.single_cabin_form_capacity.capacity))) {
        return false;
      } else if (type === SingleCabinFormCapacityType.DYNAMIC) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private getSingleCabinFormFixedCapacity(): number {
    try {
      return environment.single_cabin_form_capacity.capacity || 0;
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  private getAppCapacity(realCapacity: number): number {
    try {
      if (this.isCapacityDynamic() === true) {
        return realCapacity;
      } else {
        return this.getSingleCabinFormFixedCapacity() <= realCapacity
          ? this.getSingleCabinFormFixedCapacity() : realCapacity;
      }
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public getCapacidadAdultos(): number {
    try {
      return this.getAppCapacity(this.getCabinaItinerarioMenor().capacityAdults);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public getCapacidadNinos(): number {
    try {
      return this.getAppCapacity(this.getCabinaItinerarioMenor().capacityChildren);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public getCubiertaCabina(): CubiertaCabina {
    try {
      const cabina: Cabina = this.getCabina();
      if (isNullOrUndefined(cabina)) { return null; }
      if (isNullOrUndefined(cabina.cubiertaCabina) || cabina.cubiertaCabina.estado === GalavailAppStatus.INACTIVE) {
        return null;
      }
      return cabina.cubiertaCabina;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getCubierta(): Cubierta {
    try {
      const cubiertaCabina: CubiertaCabina = this.getCubiertaCabina();
      const cubierta: Cubierta = cubiertaCabina.cubierta;
      if (isNullOrUndefined(cubierta) || cubierta.estado === GalavailAppStatus.INACTIVE) {
        return null;
      }
      return cubierta;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getCubiernaName(): string {
    try {
      const cubierta: Cubierta = this.getCubierta();
      if (isNullOrUndefined(cubierta)) {
        return null;
      }
      return cubierta.nombre;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getCabinaFullName(): string {
    try {
      return this.getCabinaItinerarioMenor().deck + ' - ' + this.getCabinaItinerarioMenor().type;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getFotoCabinaList(): string[] {
    try {
      return this.getCabinaItinerarioMenor().images;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public getimagebyIndex(i: number): string {
    try {
      return this.getCabinaItinerarioMenor().images[i];
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public getFotoCabinaListImageNames(): string[] {
    try {
      const fotoCabinaListImageNameArray = this.getFotoCabinaList();
      return fotoCabinaListImageNameArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getFotoCabinaListMainImage(): string {
    try {
      const defaultIndex = 0;
      const fotoCabinaListImageNames: string[] = this.getFotoCabinaListImageNames();
      return fotoCabinaListImageNames[defaultIndex];
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}
