import { UrlParamsService } from './../../../services/url-params.service';
import { PurchaseRequestStatus } from 'src/app/enums/purchase-request-status.enum';
import { environment } from 'src/environments/environment';
import { CreditCardClass } from './../../classes/credit-card.class';
import { UtilClass } from './../../classes/Util.class';
import { CountryInfoService } from './../../../core/services/country-info/country-info.service';
import { Component, EventEmitter, Input, OnInit, Output, isDevMode } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { BlockDeparture } from '../../../models/purchase/block-departure';
import { TransactionResponseInterface } from '../../classes/transaction-response.interface';
import { NgGtcPayflowService } from '../../services/ng-gtc-payflow/ng-gtc-payflow.service';
import { PurchaseRequestService } from 'src/app/reserve/services/purchase-request/purchase-request.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CountryService } from '../../../core/services/country/country.service';

@Component({
  templateUrl: 'credit-card-form.component.html',
  selector: 'app-gal-payflow-credit-card-form',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent implements OnInit {
  @Input() private amount: number;
  @Input() private reference: string;
  @Input() private comment1: string;
  @Input() private comment2: string;
  public isRequestingPayment = false;
  @Output() public isPaymentError: EventEmitter<any> = new EventEmitter();
  @Output() public isPaymentSucceed: EventEmitter<TransactionResponseInterface> = new EventEmitter();
  @Output() public isBlockDepartureFailed: EventEmitter<boolean> = new EventEmitter();
  @Output() public errorMessage: EventEmitter<string> = new EventEmitter();
  @Output() public isProcessing: EventEmitter<boolean> = new EventEmitter();
  public processing: boolean;
  public readonly billToFirstNameMaxLength: number = 30;
  public readonly billToLastNameMaxLength: number = 30;
  public readonly billToStreetMaxLength: number = 30;
  public readonly billToPhoneNumberMaxLength: number = 10;
  public readonly billToZipMaxLength: number = 9;
  public readonly billToEmailMaxLength: number = 60;
  public readonly securityNumberMaxLength: number = 4;
  public readonly billToCityMaxLength: number = 20;
  public isProduction = false;
  public creditCardFormGroup: FormGroup;
  public arrayOfExpirationMonths: number[] = [];
  public arrayOfExpirationYears: number[] = [];
  public arrayOfCountries: any[] = [];
  public isShowErrorMessageInForm = false;
  public errorMessageInForm = 'There were an error while trying to make your payment';
  public termsCondition: boolean;
  public valueTerms = 'none';

  public constructor(
    private payflowService: NgGtcPayflowService,
    private purchaseRequestService: PurchaseRequestService,
    private countryInfoService: CountryService,
    private urlParamsService: UrlParamsService,
    private router: Router) {
    this.isProduction = environment.production;

  }

  public ngOnInit() {
    this.amount = 100;
    this.reference = 'pagoTest';
    this.comment1 = 'comment1';
    this.comment2 = 'comment2';
    this.setUpForm();
    this.arrayOfExpirationMonths = UtilClass.createArrayOfExpirationMonths();
    this.arrayOfExpirationYears = UtilClass.createArrayOfExpirationYears(CreditCardClass.maxYearsExpirationDate);
    this.createArrayofCountries();
    this.truncateComments();
    this.setPaymentUniqueReference();

  }

  private setPaymentUniqueReference(): void {
    if (this.reference === null || this.reference === undefined || this.reference === '') {
      this.generatePaymentUniqueReference();
    }
  }

  private createArrayofCountries(): void {
    this.arrayOfCountries = this.countryInfoService.getCountries();
    /*
    this.countryInfoService.getCountries().subscribe((countries: any[]) => {
      this.arrayOfCountries = countries;
    }, (error) => {
      this.arrayOfCountries = [];
    });*/
  }

  private generatePaymentUniqueReference(): void {
    const limitReference = 32;
    const now: number = Date.now();
    const intPrice: number = Math.round(this.purchaseRequestService.getTotalPrice());
    const itineraryId: number = this.purchaseRequestService.getCruiseWrapper().getCurrentItinerary().codigo;
    const email: string = this.purchaseRequestService.getPurchaseContactPerson().email;
    const tempReference: string = '' + now + intPrice + itineraryId + email;
    this.reference = tempReference.substring(0, limitReference);
  }

  private truncateComments() {
    const commentMaxLength = 128;
    if (!isNullOrUndefined(this.comment1)) {
      this.comment1 = this.comment1.substr(0, commentMaxLength);
    }
    if (!isNullOrUndefined(this.comment2)) {
      this.comment2 = this.comment2.substr(0, commentMaxLength);
    }
  }

  private setUpForm(): void {
    try {

      this.creditCardFormGroup = new FormGroup({
        creditCardNumber: new FormControl(null, [Validators.required, this.creditCardNumberValidation.bind(this)]),
        expirationDate: new FormGroup({
          expirationMonth: new FormControl(null, [Validators.required, Validators.pattern(CreditCardClass.expirationMonthRegex)]),
          expirationYear: new FormControl(null, [Validators.required, Validators.pattern(CreditCardClass.expirationYearRegex)])
          // }, this.expirationDateValidation.bind(this)),
        }),
        securityNumber: new FormControl(null,
          [Validators.required, Validators.minLength(3),
          Validators.maxLength(this.securityNumberMaxLength),
          Validators.pattern(CreditCardClass.securityNumberRegex)]),
        billToFirstName: new FormControl(null, [Validators.required, Validators.maxLength(this.billToFirstNameMaxLength)]),
        billToLastName: new FormControl(null, [Validators.required, Validators.maxLength(this.billToLastNameMaxLength)]),
        billToStreet: new FormControl(null, [Validators.required, Validators.maxLength(this.billToStreetMaxLength)]),
        billToStreet2: new FormControl(null, [Validators.maxLength(this.billToStreetMaxLength)]),
        billToCity: new FormControl(null, [Validators.required, Validators.maxLength(this.billToCityMaxLength)]),
        billToState: new FormControl(null, [Validators.required]),
        billToCountry: new FormControl(null, [Validators.required]),
        billToPhoneNumber: new FormControl(null, [Validators.required, Validators.maxLength(this.billToPhoneNumberMaxLength)]),
        billToZip: new FormControl(null, [Validators.required, Validators.maxLength(this.billToZipMaxLength)]),
        billToEmail: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(this.billToEmailMaxLength)])
      });
    } catch (error) {
      console.log('CreditCardFormComponent.setUpForm()');
      console.error(error);
    }
  }

  private creditCardNumberValidation(creditCardNumberField: FormControl): { error?: boolean } {
    try {
      const unformattedCreditCardNumber = creditCardNumberField.value;

      if (isNullOrUndefined(unformattedCreditCardNumber)) {
        return {};
      }
      const formattedCreditCardNumber: string = UtilClass.formatCreditCardNumber(unformattedCreditCardNumber);
      if (formattedCreditCardNumber.match(new RegExp(CreditCardClass.creditCardRegex))) {
        return {};
      } else {
        return { error: true };
      }
    } catch (error) {
      console.log('CreditCardFormComponent.creditCardNumberValidation()');
      console.error(error);
      return { error: true };
    }
  }

  private expirationDateValidation(expirationDateForm: FormGroup): { validationError?: boolean } {
    const resultObject = { validationError: false };
    try {
      const FIRST_DAY_OF_MONTH = 1;

      const selectedYear: number = expirationDateForm.get('expirationYear').value;
      const selectedMonth: number = expirationDateForm.get('expirationMonth').value;
      const expirationDateObject: Date = UtilClass.getDate(selectedYear, selectedMonth, FIRST_DAY_OF_MONTH);
      const todayDate: Date = new Date();
      const firstDayOfCurrentMonth: Date = UtilClass.getDate(todayDate.getFullYear(), todayDate.getMonth(), FIRST_DAY_OF_MONTH);

      if (isNullOrUndefined(selectedMonth)
        || isNullOrUndefined(selectedYear)
        || isNullOrUndefined(expirationDateObject)
        || expirationDateObject < firstDayOfCurrentMonth) {
        resultObject.validationError = true;
      }

      return resultObject.validationError === true ? resultObject : {};
    } catch (error) {
      resultObject.validationError = true;
      console.error(error);
      return resultObject;
    }
  }

  public validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public confirm(): void {
    let paymentAuthorizationTransactionResponse: TransactionResponseInterface;
    if (!this.termsCondition) {
      this.valueTerms = 'none';
      return;
    }
    try {
      this.validateAllFormFields(this.creditCardFormGroup);
      if (this.creditCardFormGroup.valid && this.amount > 0) {
        this.isShowErrorMessageInForm = false;
        this.isRequestingPayment = true;
        this.processing = true;
        this.disableFormElements();
        this.creditCardFormGroup.get('creditCardNumber').setValue(this.creditCardFormGroup.get('creditCardNumber').value.trim());
        const creditCard: CreditCardClass = new CreditCardClass(this.creditCardFormGroup.value);

        this.requestCreditCardPayment(creditCard).flatMap((transactionResponse: TransactionResponseInterface) => {
          paymentAuthorizationTransactionResponse = transactionResponse;
          if (isDevMode) {
            console.log(paymentAuthorizationTransactionResponse);
          }

          if (transactionResponse.isError()) {
            const message = 'There were an error while executing the transaction with the ID '
              + transactionResponse.getId() + '. Error Number: ' + transactionResponse.getStatus()
              + '. Message: ' + transactionResponse.getResponseMessage();
            return Observable.throw(new Error(message));
          }
          // bloquea la cabina
          const blockDepartureResponse: Observable<BlockDeparture> = this.purchaseRequestService.requestBlockCabinOnHold(this.purchaseRequestService.getPercentUsed());
          return blockDepartureResponse;
        }).catch((error) => {
          console.error(error);
          this.isPaymentError.emit(true);
          this.isProcessing.emit(false);
          return Observable.throw(error);
        }).subscribe((blockDepartureResponse: BlockDeparture) => {

          const isblockDeparture: boolean = this.purchaseRequestService.setBlockDeparture(blockDepartureResponse);
          const isBlockDepartureSuccess = (isblockDeparture === true && this.purchaseRequestService.isRequestBlockDepartureSuccess());
          if (isBlockDepartureSuccess !== true) {
            this.isBlockDepartureFailed.emit(true);
            return Observable.throw(
              new Error('There was an error while trying to block the cabin ' + blockDepartureResponse.pedido));
          }
          this.isPaymentSucceed.emit(paymentAuthorizationTransactionResponse);
        }, (error) => {
          console.error(error);
          this.errorMessage.emit(error);
          this.errorMessageInForm = error;
          this.isShowErrorMessageInForm = true;
          this.isRequestingPayment = false;
          this.isPaymentError.emit(error);
          this.enableFormElements();
          this.isProcessing.emit(false);
          this.processing = false;
        }, () => {
          this.isRequestingPayment = false;
          this.enableFormElements();
          this.isProcessing.emit(false);
          this.processing = false;
        });
      } else {
        this.isShowErrorMessageInForm = true;
        this.processing = false;
      }

    } catch (error) {
      console.log('CreditCardFormComponent.confirm()');
      console.error(error);
      this.errorMessage.emit(error);
      this.errorMessageInForm = error;
      this.isShowErrorMessageInForm = true;
      this.isRequestingPayment = false;
      this.enableFormElements();
    }
  }
  // ** Este metodo esta comentado debido a q sirve unicamente para probar el servicio de bloque*/
  public blockCabin(): void {
    this.isRequestingPayment = true;
    this.processing = true;
    this.purchaseRequestService.requestBlockCabinOnHold(this.purchaseRequestService.getPercentUsed()).subscribe(blockDepartureResponse => {

      const isblockDeparture: boolean = this.purchaseRequestService.setBlockDeparture(blockDepartureResponse);
      const isBlockDepartureSuccess = (isblockDeparture === true && this.purchaseRequestService.isRequestBlockDepartureSuccess());
      if (isBlockDepartureSuccess !== true) {
        this.isBlockDepartureFailed.emit(true);
        return Observable.throw(
          new Error('There was an error while trying to block the cabin ' + blockDepartureResponse.pedido));
      }
    }, (error) => {
      console.error(error);
      this.errorMessage.emit(error);
      this.errorMessageInForm = error;
      this.isShowErrorMessageInForm = true;
      this.isRequestingPayment = false;
      this.isPaymentError.emit(error);
      this.enableFormElements();
      this.isProcessing.emit(false);
      this.processing = false;
    }, () => {
      this.isRequestingPayment = false;
      this.enableFormElements();
      this.isProcessing.emit(false);
      this.processing = false;
      this.router.navigate([this.urlParamsService.createurlForNextPage('4')]);
    });
  }


  private requestCreditCardPayment(creditCard: CreditCardClass): Observable<TransactionResponseInterface> {
    try {
      return this.payflowService.requestAuthorizationPayment(creditCard, this.comment1, this.comment2, this.amount, this.reference);
    } catch (error) {
      console.log('CreditCardFormComponent.requestCreditCardPayment()');
      console.error(error);
      return Observable.throw(error);
    }
  }

  public resetForm(): void {
    this.creditCardFormGroup.reset();
  }

  private disableFormElements(): void {
    this.creditCardFormGroup.disable({});
  }

  private enableFormElements(): void {
    this.creditCardFormGroup.enable({});
  }
  changeTerms(eventValue){
    this.termsCondition = eventValue;
    this.valueTerms = eventValue ? 'all' : 'none';
  }
}
