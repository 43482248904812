import { isNullOrUndefined } from 'util';
import { Cruise } from '../models/cruise';
import { Availability } from '../models/availability';
import { Itineraries } from '../models/itineraries';

export class CruiseWrapper {
    private cruise: Cruise;
    public constructor(
        cruise: Cruise,
    ) {
        this.cruise = cruise;
    }

    public getCruise(): Cruise {
        return this.cruise;
    }

    public getAvailability(): Availability {
        return this.cruise.availability;
    }



    public getBeam(): string {
        return this.cruise.beam;
    }

    public getCategory(): number {
        return this.cruise.category;
    }

    public getCategoryII(): string {
        return this.cruise.categoryII;
    }

    public getCode(): number {
        return this.cruise.code;
    }

    public getDescription(): string {
        return this.cruise.description;
    }

    public getDraft(): string {
        return this.cruise.draft;
    }

    public getImage(): string {
        return this.cruise.image;
    }

    public getImages(): [] {
        return this.cruise.images;
    }

    public getItineraries(): Itineraries {
        return this.cruise.itineraries;
    }

    public getItineraryTypes(): [] {
        return this.cruise.itineraryTypes;
    }

    public getKeyFeatures(): string[] {
        return this.cruise.keyFeatures;
    }

    public getLenght(): string {
        return this.cruise.lenght;
    }

    public getMaximumAgeChild(): number {
        return this.cruise.maximumAgeChild;
    }

    public getMinimumAgeChild(): number {
        return this.cruise.minimumAgeChild;
    }

    public getMostAffordableDeparture(): number {
        return this.cruise.mostAffordableDeparture;
    }

    public getName(): string {
        return this.cruise.name;
    }

    public getPassengers(): number {
        return this.cruise.passengers;
    }
    public getTipoPaqueteEcommerce(): number {
      try {
        const currentItinerary: any = this.getCurrentItinerary();
        if (!isNullOrUndefined(currentItinerary) && !isNullOrUndefined(currentItinerary.packageCode)) {
          return currentItinerary.packageCode;
        }
      } catch (error) {
        console.error(error);
      }
      return null;
    }
    public getSpeed(): string {
        return this.cruise.speed;
    }

    public getType(): string {
        return this.cruise.type;
    }

    public getYear(): number {
        return this.cruise.year;
    }

    public getIterIndex(): number {
        return this.cruise.iterIndex;
    }

    public getLongName(): string {
        return this.cruise.name + ' ' + this.cruise.type;
    }

    public getStartAndEndDataSummary(): string {
        return this.cruise.availability[this.cruise.iterIndex].dateLong;
    }
    public getItineraryName(): string {
        return this.cruise.availability[this.cruise.iterIndex].name;
    }


    public getCruiseItineraryDuration(): string {
        // return this.cruise.iterIndex;
        return this.cruise.itineraries[this.cruise.iterIndex].length + '-Day';
    }

    public getCurrentItineraryIndex(): number {
        try {
            return this.getCruise().iterIndex;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public getCurrentItinerary(): any {
      try {
        return this.getCruise().availability[this.getCurrentItineraryIndex()];
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    public getCurrentItinerarynew(): any {
        try {
          return this.getCruise().availability[this.getCurrentItineraryIndex()];
        } catch (error) {
          console.log('CruiseWrapper.getCurrentItinerarynew()');
          console.error(error);
          return null;
        }
    }

    public getTipoPaqueteEcommerceinNewFormat(): any {
        try {
          const currentItinerary: any = this.getCurrentItinerarynew();
          if (!isNullOrUndefined(currentItinerary) && !isNullOrUndefined(currentItinerary.packageCode)) {
            return currentItinerary.packageCode;
          }
        } catch (error) {
          console.log('CruiseWrapper.getTipoPaqueteEcommerce()');
          console.error(error);
        }
        return null;
    }

    // public getCruiseGalleryArray(): string[] {
    //     try {
    //         if (isNullOrUndefined(this.getCruise().images)) { return []; }
    //         const photos: string[] = [];
    //         this.getCruise().images.forEach((foto) => {
    //             if (foto.estado === 1) {
    //                 photos.push(foto.codigo + foto.tipo);
    //             }
    //         });
    //         return photos;
    //     } catch (error) {
    //         console.log('CruiseDetailsComponent.getCruiseGalleryArray');
    //         console.error(error);
    //         return [];
    //     }
    // }

    public getEdadMinima(): number {
        try {
            return this.getCruise().minimumAgeChild;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    public getEdadNino(): number {
        try {
            return this.getCruise().maximumAgeChild;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    public getMinBirthDateForChild(): Date {
        try {
            const today = new Date();
            const minAge = this.getEdadMinima();
            return new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public getMaxBirthDateForChild(): Date {
        try {
            const today = new Date();
            const childrenMaxAge = this.getEdadNino();
            if (childrenMaxAge === 0) { return null; }
            const minBirthDateForAdult = new Date(today.getFullYear() - childrenMaxAge, today.getMonth(), today.getDate());
            return minBirthDateForAdult;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public getItineraryDepartureTime(): Date {
        try {
            return new Date(this.getCruise().itineraries[this.getCurrentItineraryIndex()].fechaSalida);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

}
