import { Component, OnInit } from "@angular/core";
import { CruiseWrapper } from "src/app/classes/cruise-wrapper";
import { CompanyParamsService } from "src/app/services/companyParams/company-params.service";
import { PurchaseRequestService } from "../../services/purchase-request/purchase-request.service";
import { TextInfoTransferEnum } from "../../../enums/text-info-transfer.enum";

@Component({
  selector: "app-table-payment-summary",
  templateUrl: "./table-payment-summary.component.html",
  styleUrls: ["./table-payment-summary.component.css"],
})
export class TablePaymentSummaryComponent implements OnInit {
  public cruiseLongName: string;
  public bookingTrackCode: string;
  public cruiseWrapper: CruiseWrapper;
  public totalWithoutThirtyPercent: number;
  public totalPrice: number;
  public cruiseItineraryDuration: string;

  constructor(
    public purchaseRequestService: PurchaseRequestService,
    private companyParamsService: CompanyParamsService
  ) {}

  ngOnInit() {
    this.cruiseWrapper = this.purchaseRequestService.getCruiseWrapper();
    this.cruiseLongName = this.getCruiseLongName();
    this.bookingTrackCode = this.getBookingTrackCode();
    this.totalWithoutThirtyPercent = this.getTotalPriceWhitoutDiscoutn();
    this.totalPrice = this.getTotalPrice();
    this.cruiseItineraryDuration = this.cruiseWrapper.getItineraryName();
  }
  private getCruiseLongName(): string {
    try {
      return this.cruiseWrapper.getLongName();
    } catch (error) {
      console.log("BookTourComponent.getCruiseLongName()");
      console.error(error);
      return null;
    }
  }
  private getCruiseItineraryDuration(): string {
    try {
      return this.purchaseRequestService
        .getCruiseWrapper()
        .getCruiseItineraryDuration();
    } catch (error) {
      console.log("BookTourCompoenent.getCruiseItineraryDuration()");
      console.error(error);
      return null;
    }
  }
  private getBookingTrackCode(): string {
    try {
      return this.purchaseRequestService.getBookingTrackCode();
    } catch (error) {
      console.log("BookTourComponent.getBookingTrackCode()");
      console.error(error);
      return null;
    }
  }
  public getTextTotalPaid(): string {
    try {
      let textValue = "";
      if (this.purchaseRequestService.getPaymentMethod().transfer) {
        if (this.purchaseRequestService.getIsSelectedThirtyPerent()) {
          textValue = `Initial deposit (${this.companyParamsService.getPercentPartialPayment()}%) to be paid*`;
        } else {
          textValue = TextInfoTransferEnum.TOTAL_TO_BE_PAID;
        }
      } else {
        if (this.purchaseRequestService.getIsSelectedThirtyPerent()) {
          textValue = `Initial deposit (${this.companyParamsService.getPercentPartialPayment()}%) paid*`;
        } else {
          textValue = TextInfoTransferEnum.TOTAL_PAID;
        }
      }
      return textValue;
    } catch (error) {
      console.log("TourSummaryComponent.isPurchaseIncludeAirTickets()");
      console.error(error);
    }
  }
  private getTotalPriceWhitoutDiscoutn(): number {
    try {
      return this.purchaseRequestService.getTotalPriceWithoutDiscount();
    } catch (error) {
      console.log("BookTourComponent.getTotalPrice()");
      console.error(error);
      return 0;
    }
  }
  private getTotalPrice(): number {
    try {
      return this.purchaseRequestService.getTotalPrice();
    } catch (error) {
      console.log("BookTourComponent.getTotalPrice()");
      console.error(error);
      return 0;
    }
  }
  getThanYouText() {
    let textValue = this.purchaseRequestService.getPaymentMethod().transfer
      ? TextInfoTransferEnum.TRANSFER_TEXT
      : TextInfoTransferEnum.WITHOUT_TRANSFER_TEXT;

    return textValue;
  }
  getInvoiceText() {
    let textValue = this.purchaseRequestService.getPaymentMethod().transfer
      ? TextInfoTransferEnum.TOTAL_INVOICED
      : TextInfoTransferEnum.TOTAL_BILLED;

    return textValue;
  }
}
