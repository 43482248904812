export enum TextInfoTransferEnum {
  TOTAL_INVOICED = "Total Invoiced",
  TOTAL_TO_BE_PAID = "Total to be paid",
  TRANSFER_TEXT = "All the information for wire transfer was successfully sent to your registered email address.",

  TOTAL_BILLED = "Total Billed",
  TOTAL_PAID = "Total Paid",
  WITHOUT_TRANSFER_TEXT = "One of our Trip Advisors is going to contact you as soon as possible to confirm your payment and booking."

}
