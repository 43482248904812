import { environment } from './../../../../environments/environment';
import { Router } from '@angular/router';
import { UrlParamsService } from './../../../services/url-params.service';
import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { Component, OnInit, Input, isDevMode } from '@angular/core';

import { Location } from '@angular/common';
import { PurchaseRequestService } from '../../services/purchase-request/purchase-request.service';
@Component({
  selector: 'app-end-slide',
  templateUrl: './end-slide.component.html',
  styleUrls: ['./end-slide.component.css']
})
export class EndSlideComponent implements OnInit {

  @Input() public firstString: string;
  @Input() public secondString: string;
  @Input() public thirdString: string;
  @Input() public fourthString: string;
  @Input() numberPage: number;
  isProduction: boolean = environment.production;
  keyofCompany: string;
  constructor(
    public companyParamsService: CompanyParamsService,
    private urlParamsService: UrlParamsService,
    private purchaseRequestService: PurchaseRequestService,
    private router: Router,
    private location: Location
  ) {
    this.keyofCompany = 'it is a test';

   }

  ngOnInit() {
    if (this.secondString) {
      this.firstString = 'active';
    } else if (this.thirdString) {
      this.firstString = 'active';
      this.secondString = 'active';
    } else if (this.fourthString) {
      this.firstString = 'active';
      this.secondString = 'active';
      this.thirdString = 'active';
    }
  }
  onClick(){
    console.log('goBack')
    this.purchaseRequestService.setIsSelectedThirtyPerent(false);
    this.location.back();
  }
  getNameOfBackLabel(numberPage) {
    switch (numberPage) {
      case 4:
        return 'Back to search results'
        break;
      case 3:
        return 'Back to Passengers Info'
        break;
      case 2:
        return 'Back to Tour Options'
        break;
      default:
        return '';
    }

  }
  getNumberlastPage():string{
    return this.companyParamsService.getVisiblepago() ? "4" : "3";
  }
  getTitlelastPage():string{
    return this.companyParamsService.getVisiblepago() ? "Booking Summary" : "Reservation Summary";
  }
}
