import { PayFlowTransactionTypeEnum } from "../app/ngGtcPayflow/enums/payflow-transaction-type.enum";
import { PayflowRequestVerbosityEnum } from "../app/ngGtcPayflow/enums/payflow-request-verbosity.enum";
import { NumberTypeEnum } from "../app/ngGtcPayflow/enums/number-type.enum";
import { PaypalMode } from "../app/enums/paypal/paypal-mode.enum";
import { PaypalRequestIntent } from "../app/enums/paypal/paypal-request-intent.enum";
import { SingleCabinFormCapacityType } from "../app/enums/single-cabin-form-capacity-type.enum";
export const environment = {
  production: false,
  environmentName: "sales",
  console_log: true,
  GI_ECOM_CONTACT_FORM:
    "https://rest.galavail.com/gi-web/webresources/giforms/ecom/",
  geoIpServiceUrl: "https://geoip.maxmind.com/geoip/v2.1/city/me",
  pathUrlOrigin: "https://pruebas.ecommerce.galavail.com/",
  app_resources: {
    images: { path: "assets/images/" },
    icons: { path: "assets/images/icons/" },
    fleet_photo_directory: {
      server: "https://sales.galavail.com",
      path: "upload/fleet",
    },
    cruise_photo_directory: {
      server: "https://sales.galavail.com",
      path: "upload/cruises",
    },
    cabin_photo_directory: {
      server: "https://sales.galavail.com",
      path: "upload/cabinas",
    },
    itinerary_photo_directory: {
      server: "https://sales.galavail.com",
      path: "upload/itinerario",
    },
    fake_cruise_default_photo: { path: "assets/images/icons/loading_icon.gif" },
    logoCruise: "https://sales.galavail.com/upload/fleet/",
    itinerario: "https://sales.galavail.com/upload/itinerario/",
    cabinpath: "https://sales.galavail.com/upload/cabinas/",
    companyLogos: "https://sales.galavail.com/upload/company/logos/",
  },
  restCountriesServer: "https://restcountries.eu/rest/v2/all",
  single_cabin_form_capacity: {
    type: SingleCabinFormCapacityType.FIXED,
    capacity: 2,
  },
  firebase: {
    apiKey: "AIzaSyAGqNllLBVRBFGVUzB3H-7-xTHKq26XPls",
    authDomain: "e-commerce-desarrollo.firebaseapp.com",
    databaseURL: "https://e-commerce-desarrollo.firebaseio.com",
    projectId: "e-commerce-desarrollo",
    storageBucket: "e-commerce-desarrollo.appspot.com",
    messagingSenderId: "119233533832",
  },
  ngGtcPayflow: {
    apiUrl: "https://rest.galavail.com/webservice/royalWSAPI/payflow/",
    numberType: NumberTypeEnum.ODD,
    defaultVerbosity: PayflowRequestVerbosityEnum.high,
    defaultTransactionType: PayFlowTransactionTypeEnum.sale,
    clientTimeOutForRequestingTransactions: 45,
  },
  ngGtcPayflowonHold: {
    apiUrl: "https://sales.api.galavail.com/webservice/royalWSAPI/payflow/",
    numberType: NumberTypeEnum.ODD,
    defaultVerbosity: PayflowRequestVerbosityEnum.high,
    defaultTransactionType: PayFlowTransactionTypeEnum.sale,
    clientTimeOutForRequestingTransactions: 45,
  },
  firebase_table_names: {
    genders: "genders",
    countries: "countries",
    purchase_requests: "purchase_requests",
    itineraries_periods: "itineraries_periods",
    ip_white_list: "ipwhitelist",
  },
  BOOKING_CONFIRMATION_SERVICE:
    "https://sales.api.galavail.com/webservice/royalWSAPI/sendConfirmationMail",
  PAYPAL_EXPRESS_CHECKOUT_CONFIG: {
    API_MODE: PaypalMode.SANDBOX,
    REQUEST_INTENT: PaypalRequestIntent.AUTHORIZE,
    CLIENT_SANDBOX_TOKEN:
      "AS1BeLY_IiuTRh9naGBzGQ9NcRPICZ8a59Ax9xhQ64xGMEFE7XWEtMRihK20HQXrOzDf1kgrmTaCoAju",
    CLIENT_PRODUCTION_TOKEN:
      "AS1BeLY_IiuTRh9naGBzGQ9NcRPICZ8a59Ax9xhQ64xGMEFE7XWEtMRihK20HQXrOzDf1kgrmTaCoAju",
    CURRENCY: "USD",
  },
  PURCHASE: {
    MIN_TIME_TO_ENABLE_AUTOPAYMENT: 72,
    IS_AUTO_PAYMENT_ACCEPTED: true,
    search_expiration_time: 1000,
  },
  pathCRMroyal:
    "https://develop.galavail.com/gi-web/webresources/royalforms/requestav/",
};
