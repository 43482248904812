import { CountryInfoService } from './services/country-info/country-info.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorComponent } from './components/error/error.component';
import { LoadingComponent } from './components/loading/loading/loading.component';
import { LoadingHeaderComponent } from './components/loading-header/loading-header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    LoadingComponent,
    LoadingHeaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    LoadingComponent,
    LoadingHeaderComponent
  ],
  providers: [
    CountryInfoService
  ]
})
export class CoreModule { }
